import { Component, OnInit, Input, ViewChild, ElementRef, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { DatiComponenteParteDialog, UtilitaDialog, AbsModelloContenutoDialog, EsitoRichiestaChiusuraDialog, EventoComunicazioneDialog, TipiChiusuraDialog, DialogService, LoaderModaleService, FunzioneChiusuraDialog, Utilita, SelezioneValori, UtilitaDialogSelezioneValori, ModelloChiusuraDialog, SnackbarService, ModelloNotificaSnackbar, Modelli, UtilitaOperazioni, UtilitaDialogMessaggio } from '@pl-web-angular/libreria';
import { TraduzioniService } from 'src/app/servizi/traduzioni.service';
import { DatiService, DatiRichiestiPerInserimentoEModificaDispositivo, InformazioniOperatore, ModelloSerieChiave, ModelloGruppoDispositivi, ModelloLingua } from 'src/app/servizi/dati.service';
import { GestioneConnessioneHubService } from 'src/app/servizi/gestione-connessione-hub.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { UtilitaCondiviseDispositivi } from 'src/app/utilita/pagine/condivise/utilitaCondiviseDispositivi';
import { RecuperoDatiBackupService, RecuperoDatiBackup } from 'src/app/servizi/recupero-dati-backup.service';
import { PL } from 'src/lib/isell';
import { TipiOperazioniDiGestioneDispositivi } from 'src/app/pagine/dispositivi/utilita-pagina-dispositivi';

class GruppoFormOperazione {
  constructor(
    public Email: Modelli.FormControlWithType<string>,
    public Operatori: Modelli.FormControlWithType<Utilita.Set<string>>,
    public Descrizione: Modelli.FormControlWithType<string>,
    public IDGruppo: Modelli.FormControlWithType<string>,
    public IDSerieChiave: Modelli.FormControlWithType<string>,
    public IDLingua: Modelli.FormControlWithType<string>,
    public Note: Modelli.FormControlWithType<string>,
    public AbilitaAssociaBackup: Modelli.FormControlWithType<boolean>,
    public IDBackupSelezionato: Modelli.FormControlWithType<string>,
    public IDDispositivo: Modelli.FormControlWithType<string>,
    public IDHardware: Modelli.FormControlWithType<string>,
    public Preferito: Modelli.FormControlWithType<boolean>
  ) { }
}

enum TipiOperazione {
  Creazione = 0,
  Invito = 1
}

@Component({
  selector: 'app-dialog-inserimento-dispositivo',
  templateUrl: './dialog-inserimento-dispositivo.component.html',
  styleUrls: ['./dialog-inserimento-dispositivo.component.less']
})
export class DialogInserimentoDispositivoComponent extends AbsModelloContenutoDialog<void> implements OnInit, OnDestroy {

  @Input() datiInizializzazione: DatiRichiestiPerInserimentoEModificaDispositivo;
  @Input() IDDispositivoValido: string;
  @Input() tipoOperazione: TipiOperazione;

  private modelloPerSelettoreOperatori: SelezioneValori.ModelloStrutturaSelettore<string, InformazioniOperatore>;
  private descrizioneModificataManualmente: boolean = false;
  private descrizioneModificataDaSelezioneOperatore: boolean = false;
  datiGestioneBackup: RecuperoDatiBackup.DatiGestioneBackup;

  erroriForm: string[] = [];
  public formInterno: FormGroup;
  get FC(): GruppoFormOperazione { return (this.formInterno.controls as any) as GruppoFormOperazione; }
  @ViewChild('elementoForm', { static: true })
  private elementoForm: ElementRef;

  private subscriptionValueChangeEmail: Subscription;
  private subscriptionValueChangeOperatori: Subscription;
  private subscriptionValueChangeDescrizione: Subscription;
  private subscriptionValueChangeAbilitaAssociaBackup: Subscription;

  constructor(
    private formBuilder: FormBuilder,
    private servizioTraduzioni: TraduzioniService,
    private servizioDialog: DialogService,
    ref: ChangeDetectorRef,
    private servizioRecuperoDatiBackup: RecuperoDatiBackupService,
    private servizioSnackbar: SnackbarService
  ) {
    super(ref);
  }

  get operazioneCreazione() {
    return this.tipoOperazione == TipiOperazione.Creazione;
  }

  get operazioneInvito() {
    return this.tipoOperazione == TipiOperazione.Invito;
  }

  ngOnInit() {
    var controlEmail = new Modelli.FormControlWithType("", [Validators.required, Validators.email]);
    var controlOperatori = new Modelli.FormControlWithType(new Utilita.Set<string>());
    var controlDescrizione = new Modelli.FormControlWithType("", Validators.required);
    var controlIDGruppo = new Modelli.FormControlWithType("");
    var controlIDSerieChiave = new Modelli.FormControlWithType(this.datiInizializzazione.IDSerieChiaveIniziale, Validators.required);
    var controlIDLingua = new Modelli.FormControlWithType("");
    var controlAbilitaAssociaBackup = new Modelli.FormControlWithType(false);
    var controlIDBackup = new Modelli.FormControlWithType("", Validators.required);
    var controlIDDispositivo = new Modelli.FormControlWithType(Utilita.FunzioniVarie.isDefined(this.IDDispositivoValido) ? this.IDDispositivoValido : "", Validators.required);
    var controlIDHardware = new Modelli.FormControlWithType("", Validators.required);
    var controlPreferito = new Modelli.FormControlWithType<boolean>(false);

    if (this.gruppiDisponibili.size() <= 1) {
      controlIDGruppo.disable();
    }

    if (this.serieChiaviDisponibili.size() <= 1) {
      controlIDSerieChiave.disable();
    }

    if (this.lingueDisponibili.size() <= 1) {
      controlIDLingua.disable();
    }

    this.subscriptionValueChangeEmail = controlEmail.valueChanges.subscribe(() => {
      var emailDispositivo: string = controlEmail.value;
      if (!this.descrizioneModificataManualmente && !this.descrizioneModificataDaSelezioneOperatore && Utilita.FunzioniVarie.isDefined(emailDispositivo)) {
        var indiceChiocciola = emailDispositivo.indexOf("@");
        controlDescrizione.setValue(this.servizioTraduzioni.traduci("37_DISPOSITIVO_DI_NOMEASSOCIATOADISPOSITIVO",
          { nomeAssociatoADispositivo: emailDispositivo.substr(0, indiceChiocciola != -1 ? indiceChiocciola : emailDispositivo.length) }),
          {
            emitEvent: false
          });
      }
    });

    this.subscriptionValueChangeOperatori = controlOperatori.valueChanges.subscribe(() => {
      var operatoriDaAssociare: Utilita.Set<string> = controlOperatori.value;
      if (!this.descrizioneModificataManualmente && operatoriDaAssociare.size() == 1) {
        var IDOperatore = operatoriDaAssociare.keys()[0];
        var descrizioneDaApplicare = this.dizionarioOperatori.containsKey(IDOperatore) ?
          this.dizionarioOperatori.getValue(IDOperatore).DescrizioneOperatore : "";
        if (!Utilita.FunzioniVarie.stringIsNullOrEmpty(descrizioneDaApplicare)) {
          this.descrizioneModificataDaSelezioneOperatore = true;
          controlDescrizione.setValue(this.servizioTraduzioni.traduci("37_DISPOSITIVO_DI_NOMEASSOCIATOADISPOSITIVO",
            {
              nomeAssociatoADispositivo: descrizioneDaApplicare
            }),
            {
              emitEvent: false
            }
          )
        }
      }
    });

    this.subscriptionValueChangeDescrizione = controlDescrizione.valueChanges.subscribe(() => {
      var descrizioneDispositivo: string = controlDescrizione.value;
      this.descrizioneModificataManualmente = Utilita.FunzioniVarie.isDefined(descrizioneDispositivo) && descrizioneDispositivo.trim().length != 0
      this.descrizioneModificataDaSelezioneOperatore = false;
    });

    if (this.tipoOperazione == TipiOperazione.Creazione) {
      controlAbilitaAssociaBackup.disable();
      controlIDBackup.disable();
    }
    else {
      controlIDDispositivo.disable();
      controlIDHardware.disable();

      controlIDBackup.disable();
      this.subscriptionValueChangeAbilitaAssociaBackup = controlAbilitaAssociaBackup.valueChanges.subscribe(() => {
        this.onValueChangeAbilitaAssociaBackup(controlAbilitaAssociaBackup.value);
      });
    }



    this.formInterno = this.formBuilder.group(new GruppoFormOperazione(
      controlEmail,
      controlOperatori,
      controlDescrizione,
      controlIDGruppo,
      controlIDSerieChiave,
      controlIDLingua,
      new Modelli.FormControlWithType(""),
      controlAbilitaAssociaBackup,
      controlIDBackup,
      controlIDDispositivo,
      controlIDHardware,
      controlPreferito
    ))
  }

  mostraSelettoreOperatori(): void {
    if (Utilita.FunzioniVarie.isUndefinedOrNull(this.modelloPerSelettoreOperatori)) {
      this.modelloPerSelettoreOperatori = UtilitaCondiviseDispositivi.generaModelloSelettoreOperatori(
        this.dizionarioOperatori,
        this.servizioTraduzioni
      );
    }

    var opzioniDialog = UtilitaDialogSelezioneValori.generaDialogSelezioneValoriConConfigurazioniDefault(
      this.modelloPerSelettoreOperatori,
      this.servizioTraduzioni.traduci("7_SELEZIONA_OPERATORI"),
      (modelloChiusura) => {
        if (modelloChiusura.confermato) {
          var dati = modelloChiusura.datiChiusura;
          this.modelloPerSelettoreOperatori.elencoIDValoriSelezionati = dati.elencoIDValoriSelezionati;
          this.FC.Operatori.setValue(this.modelloPerSelettoreOperatori.elencoIDValoriSelezionati);
        }
      },
      this.servizioTraduzioni,
      this.servizioTraduzioni.traduci("52_TUTTI_GLI_OPERATORI")
    );

    this.servizioDialog.aggiungiDialog(opzioniDialog);
  }

  onValueChangeAbilitaAssociaBackup(abilitato: boolean) {
    var disabilitaAssociazioneBackup = () => {
      this.FC.AbilitaAssociaBackup.setValue(false, { emitEvent: false });
      this.datiGestioneBackup = null;
      this.FC.IDBackupSelezionato.disable();
      this.detectChangesInContestoAsincrono();
    }

    var onFallimentoRecuperoBackup = () => {
      UtilitaOperazioni.gestisciFallimentoConRiprova(
        this.servizioDialog,
        this.servizioTraduzioni,
        "225_NON_E_STATO_POSSIBILE_RECUPERARE_I_DATI_RELATIVI_AI_BACKUP_TI_INVITIAMO_A_RIPROVARE",
        (modelloChiusura) => {
          if (modelloChiusura.confermato) {
            this.onValueChangeAbilitaAssociaBackup(abilitato);
          }
          else {
            disabilitaAssociazioneBackup();
          }
        }
      )
    }

    this.FC.IDBackupSelezionato.enable();
    this.FC.IDBackupSelezionato.setValue("", { emitEvent: false });
    this.FC.IDBackupSelezionato.markAsPristine();
    this.FC.IDBackupSelezionato.markAsUntouched();
    this.servizioRecuperoDatiBackup.annullaRichiesta();
    if (abilitato) {
      this.servizioRecuperoDatiBackup.rilevaBackupDisponibili(TipiOperazioniDiGestioneDispositivi.INSERIMENTO_DISPOSITIVO).done((datiGestioneBackup: RecuperoDatiBackup.DatiGestioneBackup) => {
        this.datiGestioneBackup = datiGestioneBackup;
        this.detectChangesInContestoAsincrono();
      }).fail((reject: RecuperoDatiBackup.RejectBackup | any) => {
        if (Utilita.FunzioniVarie.isDefined(reject.tipo)) {
          switch (reject.tipo) {
            case RecuperoDatiBackup.TipiRejectBackup.FallimentoVerificaORiavvioConnessione:
            case RecuperoDatiBackup.TipiRejectBackup.FallimentoRichiestaAccodamentoRecuperoDati:
            case RecuperoDatiBackup.TipiRejectBackup.FallimentoRilevamentoDati: {
              onFallimentoRecuperoBackup();
              break;
            }
            case RecuperoDatiBackup.TipiRejectBackup.AnnullamentoDuranteAnalisi:
            case RecuperoDatiBackup.TipiRejectBackup.NessunBackupDisponibile: {
              disabilitaAssociazioneBackup();
              break;
            }
          }
        }
        else {
          console.log(reject);
          onFallimentoRecuperoBackup();
        }
      })
    }
    else {
      disabilitaAssociazioneBackup();
    }
  }

  invertiValorePreferito(): void {
    this.FC.Preferito.setValue(!this.FC.Preferito.value);
  }

  ottieniClasseColoreSerieChiave(serieChiave: ModelloSerieChiave = null): string {
    if (Utilita.FunzioniVarie.isUndefinedOrNull(serieChiave)) {
      serieChiave = this.serieChiaviDisponibili.getValue(this.FC.IDSerieChiave.value);
    }

    return UtilitaCondiviseDispositivi.ottieniClasseColoreSelectOOptionSerieChiave(serieChiave);
  }

  ottieniNomeOptionSerieChiave(serieChiave: ModelloSerieChiave): string {
    return UtilitaCondiviseDispositivi.ottieniNomeOptionSerieChiave(this.servizioTraduzioni, serieChiave);
  }

  richiediChiusura(tipoChiusura: TipiChiusuraDialog): Promise<EsitoRichiestaChiusuraDialog<void>> {
    var fallisci = (): EsitoRichiestaChiusuraDialog<void> => {
      this.erroriForm.push("erroreGenericoNonGestito");
      Utilita.FunzioniVarie.eseguiAnimazioneErroriForm(this.elementoForm);
      this.detectChangesInContestoAsincrono();
      return new EsitoRichiestaChiusuraDialog(false, null);
    }

    var mostraMessaggioErroreGenerico = () => {
      UtilitaDialogMessaggio.messaggioErroreSoloOK(
        this.servizioDialog,
        this.servizioTraduzioni,
        this.servizioTraduzioni.traduci("21_IMPOSSIBILE_INSERIRE_IL_NUOVO_DISPOSITIVO")
      )
    }

    if (tipoChiusura == TipiChiusuraDialog.Conferma) {
      this.erroriForm = [];
      this.formInterno.updateValueAndValidity();

      Utilita.FunzioniVarie.aggiungiFlagSubmitAForm(this.formInterno, this.elementoForm, this.ref);
      if (this.formInterno.valid) {
        if (this.operazioneInvito) {
          //OPERAZIONE INVITO
          return $.connection.hubGestioneInvitiDispositivi.server.confermaInvitoNuovoDispositivo(
            this.FC.Email.value,
            this.FC.Operatori.value.toArray(),
            this.FC.Descrizione.value,
            this.FC.IDSerieChiave.value,
            Utilita.FunzioniVarie.isDefined(this.FC.IDGruppo.value) ? this.FC.IDGruppo.value : "",
            Utilita.FunzioniVarie.isDefined(this.FC.IDLingua.value) ? this.FC.IDLingua.value : "",
            this.FC.Note.value,
            this.FC.Preferito.value,
            Utilita.FunzioniVarie.stringIsNullOrEmpty(this.FC.IDBackupSelezionato.value) ? null : this.FC.IDBackupSelezionato.value
          ).then((risultato) => {
            if (risultato.EsitoOperazione == PL.iSell.Console.ServerWeb.Hubs.Modelli.TipiEsitoOperazione.ErroreNonGestito) {
              return fallisci();
            }
            else if (risultato.EsitoOperazione == PL.iSell.Console.ServerWeb.Hubs.Modelli.TipiEsitoOperazione.Fallimento) {
              if (risultato.ErroriValidazione.length > 0) {
                risultato.ErroriValidazione.forEach((errore) => {
                  switch (errore) {
                    case PL.iSell.Console.ServerWeb.Hubs.HubGestioneInvitiDispositivi.ErroriValidazioneNuovoInvitoDispositivo.EmailNonSpecificata: {
                      this.erroriForm.push("emailNonSpecificata");
                      break;
                    }
                    case PL.iSell.Console.ServerWeb.Hubs.HubGestioneInvitiDispositivi.ErroriValidazioneNuovoInvitoDispositivo.EmailNonValida: {
                      this.erroriForm.push("formatoEmailNonValido");
                      break;
                    }
                    case PL.iSell.Console.ServerWeb.Hubs.HubGestioneInvitiDispositivi.ErroriValidazioneNuovoInvitoDispositivo.ErroreDescrizioneDispositivoNonSpecificata: {
                      this.erroriForm.push("descrizioneDispositivoNonSpecificata");
                      break;
                    }
                    case PL.iSell.Console.ServerWeb.Hubs.HubGestioneInvitiDispositivi.ErroriValidazioneNuovoInvitoDispositivo.ErroreSerieChiaveNonSpecificata: {
                      this.erroriForm.push("chiaveNonSpecificata");
                      break;
                    }
                  }
                });
              }
              else if (risultato.Errore.Impostato) {
                switch (risultato.Errore.Valore) {
                  case PL.iSell.Console.ServerWeb.Hubs.HubGestioneInvitiDispositivi.ErroriPostValidazioneNuovoInvitoDispositivo.ErroreChiaveDeviceNonDisponibile: {
                    UtilitaDialogMessaggio.messaggioErroreSoloOK(
                      this.servizioDialog,
                      this.servizioTraduzioni,
                      this.servizioTraduzioni.traduci("79_TUTTE_LE_CHIAVI_DISPONIBILI_SONO_GIA_STATE_ASSOCIATE")
                    )
                    break;
                  }
                }
              }
              else {
                mostraMessaggioErroreGenerico();
              }
              Utilita.FunzioniVarie.eseguiAnimazioneErroriForm(this.elementoForm);
              this.detectChangesInContestoAsincrono();
              return new EsitoRichiestaChiusuraDialog(false, null);
            }
            else {
              // { Email: Utilita.FunzioniVarie.wrapTestoConQuotesInElementoConColore(this.FC.Email.value, "div", "#fff") }
              this.servizioSnackbar.aggiungiNotifica(new ModelloNotificaSnackbar(this.servizioTraduzioni.traduci(
                "204_INVITO_IN_PREPARAZIONE"
              ), 3000));
              return new EsitoRichiestaChiusuraDialog(true, null);
            }
          }, () => {
            //Fail
            mostraMessaggioErroreGenerico();
            return fallisci();
          }).catch(() => {
            mostraMessaggioErroreGenerico();
            return fallisci();
          });
        }
        else {
          //OPERAZIONE CREAZIONE
          return $.connection.hubGestioneDispositivi.server.confermaCreazioneDispositivo(
            this.FC.IDDispositivo.value,
            this.FC.Email.value,
            this.FC.Operatori.value.toArray(),
            this.FC.Descrizione.value,
            Utilita.FunzioniVarie.isDefined(this.FC.IDGruppo.value) ? this.FC.IDGruppo.value : "",
            Utilita.FunzioniVarie.isDefined(this.FC.IDLingua.value) ? this.FC.IDLingua.value : "",
            this.FC.IDSerieChiave.value,
            this.FC.IDHardware.value,
            this.FC.Note.value,
            this.FC.Preferito.value,
          ).then((risultato) => {
            if (risultato.EsitoOperazione == PL.iSell.Console.ServerWeb.Hubs.Modelli.TipiEsitoOperazione.ErroreNonGestito) {
              return fallisci();
            }
            else if (risultato.EsitoOperazione == PL.iSell.Console.ServerWeb.Hubs.Modelli.TipiEsitoOperazione.Fallimento) {
              if (risultato.ErroriValidazione.length > 0) {
                risultato.ErroriValidazione.forEach((errore) => {
                  switch (errore) {
                    case PL.iSell.Console.ServerWeb.Hubs.HubGestioneDispositivi.ErroriValidazioneCreazioneDispositivo.EmailNonSpecificata: {
                      this.erroriForm.push("emailNonSpecificata");
                      break;
                    }
                    case PL.iSell.Console.ServerWeb.Hubs.HubGestioneDispositivi.ErroriValidazioneCreazioneDispositivo.EmailNonValida: {
                      this.erroriForm.push("formatoEmailNonValido");
                      break;
                    }
                    case PL.iSell.Console.ServerWeb.Hubs.HubGestioneDispositivi.ErroriValidazioneCreazioneDispositivo.ErroreDescrizioneDispositivoNonSpecificata: {
                      this.erroriForm.push("descrizioneDispositivoNonSpecificata");
                      break;
                    }
                    case PL.iSell.Console.ServerWeb.Hubs.HubGestioneDispositivi.ErroriValidazioneCreazioneDispositivo.ErroreSerieChiaveNonSpecificata: {
                      this.erroriForm.push("chiaveNonSpecificata");
                      break;
                    }
                    case PL.iSell.Console.ServerWeb.Hubs.HubGestioneDispositivi.ErroriValidazioneCreazioneDispositivo.ErroreIDDispositivoNonSpecificato: {
                      this.erroriForm.push("IDDispositivoNonSpecificato");
                      break;
                    }
                    case PL.iSell.Console.ServerWeb.Hubs.HubGestioneDispositivi.ErroriValidazioneCreazioneDispositivo.ErroreIDHardwareNonSpecificato: {
                      this.erroriForm.push("IDHardwareNonSpecificato");
                      break;
                    }
                  }
                });
              }
              else if (risultato.Errore.Impostato) {
                var codiceErrore = "";

                switch (risultato.Errore.Valore) {
                  case PL.iSell.Console.ServerWeb.Hubs.HubGestioneDispositivi.ErroriPostValidazioneCreazioneDispositivo.ErroreIDDispositivoGiaAssegnato: {
                    codiceErrore = "132_ID_DISPOSITIVO_GIA_ASSEGNATO";
                    break;
                  }
                  case PL.iSell.Console.ServerWeb.Hubs.HubGestioneDispositivi.ErroriPostValidazioneCreazioneDispositivo.ErroreIDHardwareNonValido: {
                    codiceErrore = "134_ID_HARDWARE_NON_VALIDO";
                    break;
                  }
                  case PL.iSell.Console.ServerWeb.Hubs.HubGestioneDispositivi.ErroriPostValidazioneCreazioneDispositivo.ErroreIDHardwareGiaAssociato: {
                    codiceErrore = "133_ID_HARDWARE_GIA_ASSOCIATO";
                    break;
                  }
                }

                UtilitaDialogMessaggio.messaggioErroreSoloOK(
                  this.servizioDialog,
                  this.servizioTraduzioni,
                  this.servizioTraduzioni.traduci(codiceErrore)
                )
              }
              else {
                mostraMessaggioErroreGenerico();
              }
              Utilita.FunzioniVarie.eseguiAnimazioneErroriForm(this.elementoForm);
              this.detectChangesInContestoAsincrono();
              return new EsitoRichiestaChiusuraDialog(false, null);
            }
            else {
              this.servizioSnackbar.aggiungiNotifica(new ModelloNotificaSnackbar(this.servizioTraduzioni.traduci(
                "212_DISPOSITIVO_DESCRIZIONE_INSERITO_CON_SUCCESSO",
                { Descrizione: Utilita.FunzioniVarie.wrapTestoConQuotesInElementoConColore(this.FC.IDDispositivo.value + " - " + this.FC.Descrizione.value, "span", "#fff") }
              ), 3000));
              return new EsitoRichiestaChiusuraDialog(true, null);
            }
          })
        }
      }
      else {
        return Utilita.FunzioniVarie.PromiseConValore(new EsitoRichiestaChiusuraDialog(false, null));
      }
    }
    else {
      return Utilita.FunzioniVarie.PromiseConValore(new EsitoRichiestaChiusuraDialog(true, null));
    }
  }

  ngOnDestroy(): void {
    this.servizioRecuperoDatiBackup.annullaRichiesta();
    this.subscriptionValueChangeEmail.unsubscribe();
    this.subscriptionValueChangeOperatori.unsubscribe();
    this.subscriptionValueChangeDescrizione.unsubscribe();
    if (Utilita.FunzioniVarie.isDefined(this.subscriptionValueChangeAbilitaAssociaBackup)) {
      this.subscriptionValueChangeAbilitaAssociaBackup.unsubscribe();
    }
    super.ngOnDestroy();
  }

  get dizionarioOperatori(): Utilita.Dizionario<string, InformazioniOperatore> {
    return this.datiInizializzazione.dizionarioOperatori;
  }

  get gruppiDisponibili(): Utilita.Dizionario<string, ModelloGruppoDispositivi> {
    return this.datiInizializzazione.dizionarioGruppiDispositivi;
  }

  get lingueDisponibili(): Utilita.Dizionario<string, ModelloLingua> {
    return this.datiInizializzazione.dizionarioLingue;
  }

  get serieChiaviDisponibili(): Utilita.Dizionario<string, ModelloSerieChiave> {
    return this.datiInizializzazione.dizionarioSerieChiavi;
  }

  protected onComunicazioneDaComponenteDialog(comunicazione: EventoComunicazioneDialog): void { }

  private static nuovoDialog(
    tipoOperazione: TipiOperazione,
    servizioGestioneConnessioneHubs: GestioneConnessioneHubService,
    servizioDati: DatiService,
    servizioLoader: LoaderModaleService,
    servizioDialog: DialogService,
    servizioTraduzioni: TraduzioniService,
    callbackChiusura: FunzioneChiusuraDialog<void>
  ): void {

    function gestisciFallimentoMostraDialog() {
      UtilitaOperazioni.gestisciFallimentoMostraDialogOperazione(
        servizioDialog,
        servizioTraduzioni,
        (modelloChiusura: ModelloChiusuraDialog<void>) => {
          if (modelloChiusura.confermato) {
            DialogInserimentoDispositivoComponent.nuovoDialog(
              tipoOperazione,
              servizioGestioneConnessioneHubs,
              servizioDati,
              servizioLoader,
              servizioDialog,
              servizioTraduzioni,
              callbackChiusura);
          }
        }
      )
    }

    var operazioneInvito = tipoOperazione == TipiOperazione.Invito;
    var UUIDLoader = servizioLoader.mostraLoader();

    servizioGestioneConnessioneHubs.verificaConnessioneORiavvia().done(() => {

      $.when(
        servizioDati.richiediDatiPerInserimentoEModificaDispositivo(),
        operazioneInvito ? null : $.connection.hubGestioneDispositivi.server.rilevaIDDispositivoValido(),
        operazioneInvito ? $.connection.hubGestioneInvitiDispositivi.server.verificaConfigurazioneInvioInviti() : null
      ).done((datiRichiesti, IDDispositivoValido: string, configurazioneInvitiNonValida: boolean) => {

        if (operazioneInvito && !configurazioneInvitiNonValida) {
          UtilitaDialogMessaggio.messaggioErroreSoloOK(
            servizioDialog,
            servizioTraduzioni,
            servizioTraduzioni.traduci("296_IMPOSSIBILE_PROCEDERE_CON_LOPERAZIONE_VERIFICARE_CHE_I_CANALI_PER_LINVIO_DEGLI_INVITI_SIANO_CONFIGURATI_E_FUNZIONANTI")
          );
        }
        else {
          //Creo una copia in modo che i dati modificati esternamente non influiscano sulla compilazione del form
          var clone = {} as DatiRichiestiPerInserimentoEModificaDispositivo;
          $.extend(clone, datiRichiesti);

          if (datiRichiesti.esistonoChiaviDisponibili == true) {
            var opzioniDialog = UtilitaDialog.generaOpzioniDialogDefault<void, DialogInserimentoDispositivoComponent>(
              new DatiComponenteParteDialog<DialogInserimentoDispositivoComponent>(
                DialogInserimentoDispositivoComponent,
                <DialogInserimentoDispositivoComponent>{
                  datiInizializzazione: clone,
                  tipoOperazione: tipoOperazione,
                  IDDispositivoValido: IDDispositivoValido
                }
              ),
              servizioTraduzioni.traduci(operazioneInvito ? "3_INVITA_DISPOSITIVO" : "140_INSERISCI_NUOVO_DISPOSITIVO"),
              callbackChiusura
            );

            opzioniDialog.stickyFooter = false;

            servizioDialog.aggiungiDialog(opzioniDialog);
          }
          else {
            UtilitaDialogMessaggio.messaggioErroreSoloOK(
              servizioDialog,
              servizioTraduzioni,
              servizioTraduzioni.traduci("79_TUTTE_LE_CHIAVI_DISPONIBILI_SONO_GIA_STATE_ASSOCIATE")
            );
          }
        }

      }).fail((errore) => {
        console.error(errore);
        gestisciFallimentoMostraDialog();
      }).always(() => {
        servizioLoader.nascondiLoader(UUIDLoader);
      });
    }).fail(() => {
      servizioLoader.nascondiLoader(UUIDLoader);
      gestisciFallimentoMostraDialog();
    });
  }

  static nuovoDialogInvito(
    servizioGestioneConnessioneHubs: GestioneConnessioneHubService,
    servizioDati: DatiService,
    servizioLoader: LoaderModaleService,
    servizioDialog: DialogService,
    servizioTraduzioni: TraduzioniService,
    callbackChiusura: FunzioneChiusuraDialog<void>
  ) {
    DialogInserimentoDispositivoComponent.nuovoDialog(
      TipiOperazione.Invito,
      servizioGestioneConnessioneHubs,
      servizioDati,
      servizioLoader,
      servizioDialog,
      servizioTraduzioni,
      callbackChiusura
    )
  }

  static nuovoDialogCreazione(
    servizioGestioneConnessioneHubs: GestioneConnessioneHubService,
    servizioDati: DatiService,
    servizioLoader: LoaderModaleService,
    servizioDialog: DialogService,
    servizioTraduzioni: TraduzioniService,
    callbackChiusura: FunzioneChiusuraDialog<void>
  ) {
    DialogInserimentoDispositivoComponent.nuovoDialog(
      TipiOperazione.Creazione,
      servizioGestioneConnessioneHubs,
      servizioDati,
      servizioLoader,
      servizioDialog,
      servizioTraduzioni,
      callbackChiusura
    )
  }
}
