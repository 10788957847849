import { Component, OnInit, OnDestroy, ChangeDetectorRef, Inject, LOCALE_ID, ElementRef, ViewEncapsulation, NgZone } from '@angular/core';
import { ModelloPaginaInvioORicezioneDatiComponent } from 'src/app/componenti/pagine/modello-pagina-invio-o-ricezione-dati/modello-pagina-invio-o-ricezione-dati.component';
import { DatiService, TipiRichiesteRecuperoDatiPerPagina } from 'src/app/servizi/dati.service';
import { LoaderModaleService, DialogService } from '@pl-web-angular/libreria';
import { TraduzioniService } from 'src/app/servizi/traduzioni.service';
import { UtenteConnessoService } from 'src/app/servizi/utente-connesso.service';
import { IconaDispositivoPreferitoService } from 'src/app/componenti/icona-dispositivo-preferito/icona-dispositivo-preferito.service';
import { GestioneConnessioneHubService } from 'src/app/servizi/gestione-connessione-hub.service';

@Component({
  selector: 'app-invio-dati',
  templateUrl: '../../componenti/pagine/modello-pagina-invio-o-ricezione-dati/modello-pagina-invio-o-ricezione-dati.html',
  styleUrls: ['./invio-dati.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class InvioDatiComponent extends ModelloPaginaInvioORicezioneDatiComponent implements OnInit, OnDestroy {

  constructor(
    ref: ChangeDetectorRef,
    servizioDati: DatiService,
    NgZone: NgZone,
    servizioLoader: LoaderModaleService,
    servizioTraduzioni: TraduzioniService,
    servizioUtenteConnesso: UtenteConnessoService,
    @Inject(LOCALE_ID) protected locale: string,
    elm: ElementRef,
    servizioDialog: DialogService,
    servizioIconaDispositivoPreferito: IconaDispositivoPreferitoService,
    servizioGestioneConnessioneHubs: GestioneConnessioneHubService
  ) {
    super(
      ref,
      servizioDati,
      TipiRichiesteRecuperoDatiPerPagina.InvioDati,
      NgZone,
      servizioLoader,
      servizioTraduzioni,
      servizioUtenteConnesso,
      locale,
      elm,
      servizioDialog,
      servizioIconaDispositivoPreferito,
      servizioGestioneConnessioneHubs
    );
  }

  ngOnInit() {
    this._onInitCallback();
  }

  ngOnDestroy(): void {
    this._onDestroyCallback();
  }
}
