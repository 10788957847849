import { Component, OnInit, ChangeDetectorRef, ElementRef, Inject, LOCALE_ID, OnDestroy, NgZone } from '@angular/core';
import { VisualizzazioneGruppi, Utilita, LoaderModaleService, DialogService, SnackbarService, ModelloChiusuraDialog, TipiChiusuraDialog, ModelliRecuperoDati, DialogSelezioneOperazioneComponent, DialogSelezioneOperazione } from '@pl-web-angular/libreria';
import { ModelloDispositivo, DatiService, DatiRichiestaDispositivi, ModelloGruppoDispositivi, TipiRichiesteRecuperoDatiPerPagina, PaginaConRicaricaDatiWebApp } from 'src/app/servizi/dati.service';
import { VisualizzazionePaginaConGruppiEFiltroStato, VoceSelezionabilePerFiltroStato } from 'src/app/utilita/modelliPagina';
import { TraduzioniService } from 'src/app/servizi/traduzioni.service';
import { UtenteConnessoService } from 'src/app/servizi/utente-connesso.service';
import { PL } from 'src/lib/isell';
import { UUID } from 'angular2-uuid';
import { UtilitaCondiviseDispositivi } from 'src/app/utilita/pagine/condivise/utilitaCondiviseDispositivi';
import { GestioneConnessioneHubService } from 'src/app/servizi/gestione-connessione-hub.service';
import { IconaDispositivoPreferitoService } from 'src/app/componenti/icona-dispositivo-preferito/icona-dispositivo-preferito.service';
import { Subscriber, Subscription } from 'rxjs';
import { DialogDettagliDispositivoComponent } from 'src/app/componenti/pagine/dialog-dettagli-dispositivo/dialog-dettagli-dispositivo.component';
import { DialogSostituisciORipristinaBackupComponent } from 'src/app/componenti/pagine/dialog-sostituisci-o-ripristina-backup/dialog-sostituisci-o-ripristina-backup.component';
import { RecuperoDatiBackupService } from 'src/app/servizi/recupero-dati-backup.service';
import { DialogInserimentoDispositivoComponent } from 'src/app/componenti/pagine/dialog-inserimento-dispositivo/dialog-inserimento-dispositivo.component';
import { TipiOperazioniDiCreazioneDispositivi, TipiOperazioniDiGestioneDispositivi } from './utilita-pagina-dispositivi';

enum FiltriStatiDispositivi {
  Nessuno = 0,
  Attivo = 1,
  Disabilitato = 2,
  Invitato = 3,
  ChiaveNonAssociata = 4
}

@Component({
  selector: 'app-dispositivi',
  templateUrl: './dispositivi.component.html',
  styleUrls: ['./dispositivi.component.less']
})
export class DispositiviComponent extends PaginaConRicaricaDatiWebApp<VisualizzazionePaginaDispositivi> implements OnInit, OnDestroy {

  private subscriberModificaPreferito: Subscription;

  constructor(
    ref: ChangeDetectorRef,
    servizioDati: DatiService,
    NgZone: NgZone,
    private elm: ElementRef,
    private servizioLoader: LoaderModaleService,
    servizioUtenteConnesso: UtenteConnessoService,
    private servizioTraduzioni: TraduzioniService,
    private servizioGestioneConnessioneHubs: GestioneConnessioneHubService,
    private servizioDialog: DialogService,
    private servizioSnackbar: SnackbarService,
    @Inject(LOCALE_ID) private locale: string,
    protected servizioIconaDispositivoPreferito: IconaDispositivoPreferitoService,
    private servizioRecuperoDatiBackup: RecuperoDatiBackupService
  ) {
    super(
      ref,
      servizioDati,
      NgZone
    );

    this.subscriberModificaPreferito = this.servizioIconaDispositivoPreferito.onModificaPreferito.subscribe((IDDispositivo: string) => {
      //Sono costretto a ricreare la struttura perchè il gruppo Preferiti potrebbe non esistere e dover essere creato
      //TODO gestire senza ricreare la struttura in caso di basse performance
      this.visualizzazioneGruppi.modificaDispositivi(this.servizioDati.datiDispositivi);
      this.ref.detectChanges();
    });

    this.visualizzazioneGruppi = new VisualizzazionePaginaDispositivi(
      ref,
      servizioLoader,
      servizioTraduzioni,
      servizioUtenteConnesso,
      servizioDati);

    this.inizializzaPaginaConRicaricaDati(
      [TipiRichiesteRecuperoDatiPerPagina.Dispositivi]
    );
  }

  ngOnInit() {
    $(this.elm.nativeElement).addClass(['paginaDispositivi']);
  }

  caricaDati(modelloStati: ModelliRecuperoDati.ModelloStatiRecuperoDatiPagina): void {
    if (modelloStati.statoUltimaRichiesta == ModelliRecuperoDati.StatiRichiestaRecuperoDati.TerminataConSuccesso) {
      this.visualizzazioneGruppi.modificaDispositivi(this.servizioDati.datiDispositivi);
    }
  }

  //Usato in html tramite confronto == 'abilitato'
  codiceStringaPerStatoDispositivo(dispositivo: PL.iSell.Console.ServerWeb.Hubs.Modelli.ModelloDispositivo): string {
    if (dispositivo.PossiedeChiave.Elaborato && !dispositivo.PossiedeChiave.Valore)
      return "nochiave";

    if (dispositivo.DispositivoTemporaneo)
      return "invitato";

    switch (dispositivo.TipoAbilitazione) {
      case PL.iSell.Console.ServerWeb.Hubs.Modelli.TipiAbilitazioneDispositivo.Abilitato: {
        return "abilitato";
      }
      case PL.iSell.Console.ServerWeb.Hubs.Modelli.TipiAbilitazioneDispositivo.AbilitatoDurante: {
        return dispositivo.Attivo ? "abilitato" : "disabilitato";
      }
      case PL.iSell.Console.ServerWeb.Hubs.Modelli.TipiAbilitazioneDispositivo.Disabilitato: {
        return "disabilitato";
      }
    }
    return "";
  }

  messaggioRapidoPerStatoDispositivo(dispositivo: PL.iSell.Console.ServerWeb.Hubs.Modelli.ModelloDispositivo): string {
    if (dispositivo.PossiedeChiave.Elaborato && !dispositivo.PossiedeChiave.Valore)
      return "27_CHIAVE_NON_ASSOCIATA";

    if (dispositivo.DispositivoTemporaneo)
      return "60_INVITATO";

    switch (dispositivo.TipoAbilitazione) {
      case PL.iSell.Console.ServerWeb.Hubs.Modelli.TipiAbilitazioneDispositivo.Abilitato: {
        return "58_ATTIVO";
      }
      case PL.iSell.Console.ServerWeb.Hubs.Modelli.TipiAbilitazioneDispositivo.AbilitatoDurante: {
        return dispositivo.Attivo ? "58_ATTIVO" : "59_DISABILITATO";
      }
      case PL.iSell.Console.ServerWeb.Hubs.Modelli.TipiAbilitazioneDispositivo.Disabilitato: {
        return "59_DISABILITATO";
      }
    }
    return "";
  }

  visualizzaDettagli(dispositivo: ModelloDispositivo) {
    DialogDettagliDispositivoComponent.nuovoDialog(
      this.servizioDati.dispositivoDaDizionarioLocale(dispositivo.IDDispositivo).dati,
      this.servizioDialog,
      this.servizioTraduzioni,
      this.servizioLoader,
      this.servizioGestioneConnessioneHubs,
      this.servizioDati
    );
  }

  mostraDialogSelezioneTipologiaAggiuntaNuovoDispositivo(): void {
    DialogSelezioneOperazioneComponent.nuovoDialog(
      [
        new DialogSelezioneOperazione.OpzioneConContenutoBottone(TipiOperazioniDiCreazioneDispositivi.INVITA, this.servizioTraduzioni.traduci("155_UTILIZZA_INVITO")),
        new DialogSelezioneOperazione.OpzioneConContenutoBottone(TipiOperazioniDiCreazioneDispositivi.CREA,
          this.servizioTraduzioni.traduci("156_INSERIMENTO_MANUALE")
          + '<div style="margin-top: 10px; font-size: 11px;">'
          + this.servizioTraduzioni.traduci("157_ATTENZIONELA_RICEZIONE_DEI_DATI_SUL_DISPOSITIVO_DOVRA_ESSERE_CONFIGURATA_MANUALMENTE_DALLOPERATORE")
          + '</div>'
        ),
      ],
      this.servizioDialog,
      this.servizioTraduzioni,
      (modelloChiusura) => {
        if (modelloChiusura.confermato && Utilita.FunzioniVarie.isDefined(modelloChiusura.datiChiusura)) {
          var operazione: TipiOperazioniDiCreazioneDispositivi = modelloChiusura.datiChiusura;
          if (operazione == TipiOperazioniDiCreazioneDispositivi.CREA) {
            DialogInserimentoDispositivoComponent.nuovoDialogCreazione(
              this.servizioGestioneConnessioneHubs,
              this.servizioDati,
              this.servizioLoader,
              this.servizioDialog,
              this.servizioTraduzioni,
              (modelloChiusura) => {
                if (modelloChiusura.confermato) {
                  this.servizioDati.ricaricaDatiPerPaginaAttuale();
                }
              }
            )
          }
          else {
            DialogInserimentoDispositivoComponent.nuovoDialogInvito(
              this.servizioGestioneConnessioneHubs,
              this.servizioDati,
              this.servizioLoader,
              this.servizioDialog,
              this.servizioTraduzioni,
              (modelloChiusura) => {
                if (modelloChiusura.confermato) {
                  this.servizioDati.ricaricaDatiPerPaginaAttuale();
                }
              }
            )
          }
        }
      }
    )
  }

  mostraDialogGestioneDispositivo(dispositivo: ModelloDispositivo): void {
    let elencoOperazioni: DialogSelezioneOperazione.OpzioneConContenutoBottone<TipiOperazioniDiGestioneDispositivi>[] = [];

    elencoOperazioni.push(new DialogSelezioneOperazione.OpzioneConContenutoBottone(TipiOperazioniDiGestioneDispositivi.VISUALIZZA_DETTAGLI, this.servizioTraduzioni.traduci("295_DATI_DISPOSITIVO")))
    elencoOperazioni.push(new DialogSelezioneOperazione.OpzioneConContenutoBottone(TipiOperazioniDiGestioneDispositivi.CONNETTI_O_SOSTITUISCI, this.servizioTraduzioni.traduci("229_CONNETTI_O_SOSTITUISCI_IL_DISPOSITIVO")))
    if (!Utilita.FunzioniVarie.stringIsNullOrEmpty(dispositivo.IDHardware) && !dispositivo.DispositivoTemporaneo) {
      elencoOperazioni.push(new DialogSelezioneOperazione.OpzioneConContenutoBottone(TipiOperazioniDiGestioneDispositivi.RIPRISTINA_BACKUP, this.servizioTraduzioni.traduci("206_RIPRISTINA_BACKUP")))
    }
    elencoOperazioni.push(new DialogSelezioneOperazione.OpzioneConContenutoBottone(TipiOperazioniDiGestioneDispositivi.ELIMINA, this.servizioTraduzioni.traduci("15_ELIMINA")))

    DialogSelezioneOperazioneComponent.nuovoDialog(
      elencoOperazioni,
      this.servizioDialog,
      this.servizioTraduzioni,
      (modelloChiusura) => {
        if (modelloChiusura.confermato && Utilita.FunzioniVarie.isDefined(modelloChiusura.datiChiusura)) {
          var operazione = modelloChiusura.datiChiusura as TipiOperazioniDiGestioneDispositivi;
          switch (operazione) {
            case TipiOperazioniDiGestioneDispositivi.CONNETTI_O_SOSTITUISCI:
            case TipiOperazioniDiGestioneDispositivi.RIPRISTINA_BACKUP: {
              DialogSostituisciORipristinaBackupComponent.nuovoDialog(
                operazione,
                dispositivo,
                this.servizioGestioneConnessioneHubs,
                this.servizioDati,
                this.servizioRecuperoDatiBackup,
                this.servizioDialog,
                this.servizioTraduzioni,
                (modelloChiusura) => {
                  if (modelloChiusura.confermato) {
                    this.servizioDati.ricaricaDatiPerPaginaAttuale();
                  }
                }
              )
              break;
            }
            case TipiOperazioniDiGestioneDispositivi.ELIMINA: {
              UtilitaCondiviseDispositivi.eliminaDispositivoEdInvito(
                this.servizioGestioneConnessioneHubs,
                this.servizioDialog,
                this.servizioTraduzioni,
                this.servizioLoader,
                this.servizioSnackbar,
                this.servizioTraduzioni.traduci(
                  "34_CONFERMI_LELIMINAZIONE_DEL_DISPOSITIVO_DESCRIZIONEDISPOSITIVO",
                  { descrizioneDispositivo: "<strong>" + dispositivo.IDDispositivo + " - " + dispositivo.DescrizioneDispositivo + "</strong>" }
                ),
                dispositivo.IDDispositivo,
                false,
                () => {
                  this.servizioDati.ricaricaDatiPerPaginaAttuale();
                }
              );
              break;
            }
            case TipiOperazioniDiGestioneDispositivi.VISUALIZZA_DETTAGLI: {
              this.visualizzaDettagli(dispositivo);
              break;
            }
          }
        }
      }
    );
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.subscriberModificaPreferito.unsubscribe();
  }
}

class VisualizzazionePaginaDispositivi
  extends VisualizzazionePaginaConGruppiEFiltroStato<
  string,
  string | number,
  ModelloDispositivo,
  string,
  VisualizzazioneGruppi.WrapperDatiEsteso,
  FiltriStatiDispositivi> {

  private dizionarioGruppiPerDispositivi: Utilita.Dizionario<string, Utilita.Set<string>> = new Utilita.Dizionario<string, Utilita.Set<string>>();
  private UUIDGruppoPreferitoAttuale: string;

  constructor(
    ref: ChangeDetectorRef,
    servizioLoader: LoaderModaleService,
    servizioTraduzioni: TraduzioniService,
    servizioUtenteConnesso: UtenteConnessoService,
    protected servizioDati: DatiService) {

    super(
      ref,
      servizioLoader,
      servizioTraduzioni,
      servizioUtenteConnesso,
      PL.iSell.Console.ServerWeb.Moduli.UtilitaPermessi.AmbitiPermessoServerWeb.Dispositivi,
      null
    );

    this.vociSelezionabiliPerFiltroStato = [
      new VoceSelezionabilePerFiltroStato<FiltriStatiDispositivi>(FiltriStatiDispositivi.Nessuno, servizioTraduzioni.traduci("6_TUTTI")),
      new VoceSelezionabilePerFiltroStato<FiltriStatiDispositivi>(FiltriStatiDispositivi.Attivo, servizioTraduzioni.traduci("58_ATTIVO")),
      new VoceSelezionabilePerFiltroStato<FiltriStatiDispositivi>(FiltriStatiDispositivi.Disabilitato, servizioTraduzioni.traduci("59_DISABILITATO")),
      new VoceSelezionabilePerFiltroStato<FiltriStatiDispositivi>(FiltriStatiDispositivi.Invitato, servizioTraduzioni.traduci("60_INVITATO")),
      new VoceSelezionabilePerFiltroStato<FiltriStatiDispositivi>(FiltriStatiDispositivi.ChiaveNonAssociata, servizioTraduzioni.traduci("27_CHIAVE_NON_ASSOCIATA")),
    ];
  }

  modificaDispositivi(datiRichiestaDispositivi: DatiRichiestaDispositivi) {
    this.dizionarioGruppiPerDispositivi.clear();

    var elencoGruppi: VisualizzazioneGruppi.ModelloGruppo<string, string | number>[] = [];
    var elencoVoci: VisualizzazioneGruppi.ModelloVoce<ModelloDispositivo, string>[] = [];

    var dizionarioGruppi = datiRichiestaDispositivi.dizionarioGruppiDispositivi;

    dizionarioGruppi.forEach((IDGruppo, gruppo: ModelloGruppoDispositivi) => {
      elencoGruppi.push(new VisualizzazioneGruppi.ModelloGruppo<string, string | number>(
        IDGruppo,
        gruppo.DescrizioneGruppoDispositivi,
        gruppo.DescrizioneGruppoDispositivi,
        gruppo.DescrizioneGruppoDispositivi,
        ""
      ))
    })

    var UUIDGruppoPreferito = null;

    if (Utilita.FunzioniVarie.isDefined(this.UUIDGruppoPreferitoAttuale)) {
      if (!dizionarioGruppi.containsKey(this.UUIDGruppoPreferitoAttuale)) {
        //Utilizzo l'id precedentemente generato
        UUIDGruppoPreferito = this.UUIDGruppoPreferitoAttuale;
      }
      else {
        //Genero un nuovo id e creo una copia del gruppoVisualizzazione attuale per i preferiti sotto il nuovo ID per permettere il mantenimento delle impostazioni di visibilità
        do {
          UUIDGruppoPreferito = UUID.UUID();
        } while (dizionarioGruppi.containsKey(UUIDGruppoPreferito) || this.dizionarioPerGruppo.containsKey(VisualizzazioneGruppi.generaIDGruppoUnivoco(UUIDGruppoPreferito)))
        this.dizionarioPerGruppo.setValue(UUIDGruppoPreferito, this.dizionarioPerGruppo.getValue(this.UUIDGruppoPreferitoAttuale));
      }
    }
    else {
      //Genero un nuovo id
      do {
        UUIDGruppoPreferito = UUID.UUID();
      } while (dizionarioGruppi.containsKey(UUIDGruppoPreferito))
    }

    var testoPreferiti = this.servizioTraduzioni.traduci("251_PREFERITI");
    elencoGruppi.push(new VisualizzazioneGruppi.ModelloGruppo<string, string | number>(
      UUIDGruppoPreferito,
      testoPreferiti,
      -1,
      testoPreferiti,
      ""
    ));
    this.UUIDGruppoPreferitoAttuale = UUIDGruppoPreferito;

    datiRichiestaDispositivi.dizionarioDispositivi.forEach((chiave, info: ModelloDispositivo) => {
      elencoVoci.push(new VisualizzazioneGruppi.ModelloVoce(
        info.IDDispositivo,
        info,
        info.DescrizioneDispositivo,
        info.DescrizioneDispositivo,
        "",
        info.IDGruppoDispositivi
      ))

      if (info.Preferito) {
        elencoVoci.push(new VisualizzazioneGruppi.ModelloVoce(
          info.IDDispositivo,
          info,
          info.DescrizioneDispositivo,
          info.DescrizioneDispositivo,
          "",
          UUIDGruppoPreferito
        ))
      }
    });

    this.modificaGruppiEVoci(
      elencoGruppi,
      elencoVoci,
      (IDGruppo: string, modelloGruppo: VisualizzazioneGruppi.ModelloGruppo<string, string | number>) => {
        return new VisualizzazioneGruppi.WrapperDatiEsteso(IDGruppo)
      },
      VisualizzazioneGruppi.ConfigurazioneOrdinamento.nuovaConfigurazioneAvanzata(
        VisualizzazioneGruppi.DirezioniSortingElementoDiOrdinamento.ASC,
        VisualizzazioneGruppi.DirezioniSortingElementoDiOrdinamento.ASC,
        VisualizzazioneGruppi.ConfigurazioneOrdinamento.ordinamentoStandard,
        (modello1, modello2) => {
          var datiOrd1 = modello1.dati;
          var datiOrd2 = modello2.dati;

          var Dispositivo1 = this.servizioDati.dispositivoDaDizionarioLocale(datiOrd1.IDDispositivo).dati;
          var Dispositivo2 = this.servizioDati.dispositivoDaDizionarioLocale(datiOrd2.IDDispositivo).dati;
          var Preferito1 = Utilita.FunzioniVarie.isDefined(Dispositivo1) ? Dispositivo1.Preferito : false;
          var Preferito2 = Utilita.FunzioniVarie.isDefined(Dispositivo2) ? Dispositivo2.Preferito : false;

          if (Preferito1 && !Preferito2) {
            return -1;
          }
          else if (!Preferito1 && Preferito2) {
            return 1;
          }
          else {
            return modello1.elementoDiOrdinamento.localeCompare(modello2.elementoDiOrdinamento);
          }
        },
      ),
      (gruppo, datiGruppo, numeroTotaleElementi) => {
        if (numeroTotaleElementi > 30) {
          gruppo.gruppoVisibile = false;
          gruppo.gruppoVisibileDuranteRicerca = false;
        }
      },
      this.onSingolaAggiuntaVoce.bind(this)
    );
  }

  private onSingolaAggiuntaVoce(IDGruppo: string, voce: VisualizzazioneGruppi.ModelloVoce<ModelloDispositivo, string>) {
    var IDDispositivo = voce.dati.IDDispositivo;
    if (!this.dizionarioGruppiPerDispositivi.containsKey(IDDispositivo)) {
      this.dizionarioGruppiPerDispositivi.setValue(IDDispositivo, new Utilita.Set<string>());
    }
    this.dizionarioGruppiPerDispositivi.getValue(IDDispositivo).add(IDGruppo);
  }


  esisteFiltroAttivo(): boolean {
    return this._esisteFiltroTesto || this._esisteFiltroStato;
  }

  private get _esisteFiltroTesto(): boolean {
    return this.filtroRicerca.length > 0;
  }

  private get _esisteFiltroStato(): boolean {
    return this.voceSelezionataPerFiltroStato != FiltriStatiDispositivi.Nessuno;
  }

  protected _callbackModificaFiltroAttivo_Pre() {
  }
  protected _callbackModificaFiltroAttivo_Post() {
  }

  protected _applicaFiltroDiRicerca(IDGruppo: string, elementoDaValutare: VisualizzazioneGruppi.ModelloVoce<ModelloDispositivo, string>): boolean {
    var dispositivo = elementoDaValutare.dati;
    var filtraPerTesto = () => {
      if (this._esisteFiltroTesto) {
        var filtro = this.filtroRicerca.toLowerCase();

        return ((dispositivo.DescrizioneDispositivo.toLowerCase().indexOf(filtro) != -1) ||
          (dispositivo.Email.toLowerCase().indexOf(filtro) != -1));
      }
      else {
        return true;
      }
    }

    var verificaSeDispositivoCorrispondeAStato = (): boolean => {
      if (this._esisteFiltroStato) {
        var chiaveNonAssociata = dispositivo.PossiedeChiave.Elaborato && !dispositivo.PossiedeChiave.Valore;
        var dispositivoInvitato = dispositivo.DispositivoTemporaneo;

        return (
          (this.voceSelezionataPerFiltroStato == FiltriStatiDispositivi.Nessuno)
          || (chiaveNonAssociata && this.voceSelezionataPerFiltroStato == FiltriStatiDispositivi.ChiaveNonAssociata)
          || (dispositivoInvitato && this.voceSelezionataPerFiltroStato == FiltriStatiDispositivi.Invitato)
          || ((!chiaveNonAssociata && !dispositivoInvitato) && dispositivo.TipoAbilitazione == PL.iSell.Console.ServerWeb.Hubs.Modelli.TipiAbilitazioneDispositivo.Abilitato && this.voceSelezionataPerFiltroStato == FiltriStatiDispositivi.Attivo)
          || ((!chiaveNonAssociata && !dispositivoInvitato) && dispositivo.TipoAbilitazione == PL.iSell.Console.ServerWeb.Hubs.Modelli.TipiAbilitazioneDispositivo.AbilitatoDurante && dispositivo.Attivo && this.voceSelezionataPerFiltroStato == FiltriStatiDispositivi.Attivo)
          || ((!chiaveNonAssociata && !dispositivoInvitato) && dispositivo.TipoAbilitazione == PL.iSell.Console.ServerWeb.Hubs.Modelli.TipiAbilitazioneDispositivo.AbilitatoDurante && !dispositivo.Attivo && this.voceSelezionataPerFiltroStato == FiltriStatiDispositivi.Disabilitato)
          || ((!chiaveNonAssociata && !dispositivoInvitato) && dispositivo.TipoAbilitazione == PL.iSell.Console.ServerWeb.Hubs.Modelli.TipiAbilitazioneDispositivo.Disabilitato && this.voceSelezionataPerFiltroStato == FiltriStatiDispositivi.Disabilitato)
        );
      }
      else {
        return true;
      }
    }

    return verificaSeDispositivoCorrispondeAStato() && filtraPerTesto();
  }

  protected _callbackModificaFiltroStato_Pre() {
  }
  protected _callbackModificaFiltroStato_Post() {
  }
}