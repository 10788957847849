export enum TipiOperazioniDiCreazioneDispositivi {
    INVITA = 0,
    CREA = 1
}

export enum TipiOperazioniDiGestioneDispositivi {
    RIPRISTINA_BACKUP = 0,
    CONNETTI_O_SOSTITUISCI = 1,
    ELIMINA = 2,
    VISUALIZZA_DETTAGLI = 3,
    INSERIMENTO_DISPOSITIVO = 4
}