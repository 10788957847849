import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from '../pagine/login/login.component';
import { GuardRedirectSeAutenticatoService } from '../servizi/guard-redirect-se-autenticato.service';
import { HomeComponent } from '../pagine/home/home.component';
import { GuardAutenticazioneService } from '../servizi/guard-autenticazione.service';
import { DatiGuardAutenticazione, DatiRouteDefault, AmbitoConValorePermesso, TipiVerificaPermessiRichiestiAutenticazione } from '../utilita/modelli';
import { PL } from 'src/lib/isell';
import { AttivitaComponent } from '../pagine/attivita/attivita.component';
import { PaginaErroreComponent, ModelloPaginaErrore } from '../pagine/pagina-errore/pagina-errore.component';
import { DispositiviComponent } from '../pagine/dispositivi/dispositivi.component';
import { InvioDatiComponent } from '../pagine/invio-dati/invio-dati.component';
import { RicezioneDatiComponent } from '../pagine/ricezione-dati/ricezione-dati.component';
import { EstensioniComponent } from '../pagine/estensioni/estensioni.component';
import { DocumentiComponent } from '../pagine/documenti/documenti.component';
import { InvitiDispositiviComponent } from '../pagine/inviti-dispositivi/inviti-dispositivi.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [GuardRedirectSeAutenticatoService]
  },
  {
    path: '',
    component: HomeComponent,
    canActivate: [GuardAutenticazioneService]
  },
  {
    path: 'attivita',
    component: AttivitaComponent,
    canActivate: [GuardAutenticazioneService],
    data: new DatiRouteDefault(
      new DatiGuardAutenticazione([
        new AmbitoConValorePermesso(
          PL.iSell.Console.ServerWeb.Moduli.UtilitaPermessi.AmbitiPermessoServerWeb.Attivita,
          PL.iSell.Console.ServerWeb.Moduli.UtilitaPermessi.ValoriPermessoServerWeb.Lettura
        )
      ], TipiVerificaPermessiRichiestiAutenticazione.Tutti)
    )
  },
  {
    path: 'dispositivi',
    component: DispositiviComponent,
    canActivate: [GuardAutenticazioneService],
    data: new DatiRouteDefault(
      new DatiGuardAutenticazione([
        new AmbitoConValorePermesso(
          PL.iSell.Console.ServerWeb.Moduli.UtilitaPermessi.AmbitiPermessoServerWeb.Dispositivi,
          PL.iSell.Console.ServerWeb.Moduli.UtilitaPermessi.ValoriPermessoServerWeb.Lettura
        )
      ], TipiVerificaPermessiRichiestiAutenticazione.Tutti)
    )
  },
  {
    path: 'invitidispositivi',
    component: InvitiDispositiviComponent,
    canActivate: [GuardAutenticazioneService],
    data: new DatiRouteDefault(
      new DatiGuardAutenticazione([
        new AmbitoConValorePermesso(
          PL.iSell.Console.ServerWeb.Moduli.UtilitaPermessi.AmbitiPermessoServerWeb.Dispositivi,
          PL.iSell.Console.ServerWeb.Moduli.UtilitaPermessi.ValoriPermessoServerWeb.Lettura
        )
      ], TipiVerificaPermessiRichiestiAutenticazione.Tutti)
    )
  },
  {
    path: 'inviodati',
    component: InvioDatiComponent,
    canActivate: [GuardAutenticazioneService],
    data: new DatiRouteDefault(
      new DatiGuardAutenticazione([], TipiVerificaPermessiRichiestiAutenticazione.Tutti)
    )
  },
  {
    path: 'ricezionedati',
    component: RicezioneDatiComponent,
    canActivate: [GuardAutenticazioneService],
    data: new DatiRouteDefault(
      new DatiGuardAutenticazione([], TipiVerificaPermessiRichiestiAutenticazione.Tutti)
    )
  },
  {
    path: 'estensioni',
    component: EstensioniComponent,
    canActivate: [GuardAutenticazioneService],
    data: new DatiRouteDefault(
      new DatiGuardAutenticazione([], TipiVerificaPermessiRichiestiAutenticazione.Tutti)
    ),
    children: [
      {
        path: '**',
        component: EstensioniComponent,
        canActivate: [GuardAutenticazioneService],
        data: new DatiRouteDefault(
          new DatiGuardAutenticazione([], TipiVerificaPermessiRichiestiAutenticazione.Tutti)
        ),
      }
    ]
  },
  {
    path: 'documenti',
    component: DocumentiComponent,
    canActivate: [GuardAutenticazioneService],
    data: new DatiRouteDefault(
      new DatiGuardAutenticazione([], TipiVerificaPermessiRichiestiAutenticazione.Tutti)
    )
  },
  {
    path: '404',
    component: PaginaErroreComponent,
    data: new ModelloPaginaErrore("404", "186_PAGINA_NON_TROVATA")
  },
  {
    path: '500',
    component: PaginaErroreComponent,
    data: new ModelloPaginaErrore("500", "301_SI_E_VERIFICATO_UN_ERRORE")
  },
  {
    path: '**',
    component: PaginaErroreComponent,
    data: new ModelloPaginaErrore("404", "186_PAGINA_NON_TROVATA")
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }