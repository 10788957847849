import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID } from '@angular/core';

import localeIt from '@angular/common/locales/it';
import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import localeEn from '@angular/common/locales/en';
import localeDe from '@angular/common/locales/de';

(window as any)._ISELL_LOCALE_ANGULAR_CONFIGS = {
  locale: $("html").attr("lang"),
};

switch (_ISELL_LOCALE_ANGULAR_CONFIGS.locale) {
  case "it": {
    registerLocaleData(localeIt, _ISELL_LOCALE_ANGULAR_CONFIGS.locale);
    break;
  }
  case "es": {
    registerLocaleData(localeEs, _ISELL_LOCALE_ANGULAR_CONFIGS.locale);
    break;
  }
  case "fr": {
    registerLocaleData(localeFr, _ISELL_LOCALE_ANGULAR_CONFIGS.locale);
    break;
  }
  case "en": {
    registerLocaleData(localeEn, _ISELL_LOCALE_ANGULAR_CONFIGS.locale);
    break;
  }
  case "de": {
    registerLocaleData(localeDe, _ISELL_LOCALE_ANGULAR_CONFIGS.locale);
    break;
  }
}

import { AppComponent } from './app.component';
import { HomeComponent } from './pagine/home/home.component';
import { AppRoutingModule } from './routing/app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  LibreriaComponentiImportsBaseModule,
  CheckboxModule,
  EllipsisModule,
  LoaderModaleModule,
  RigaDatiModule,
  DialogModule,
  SelettoreDataModule,
  ErroriFormModule,
  DialogMessaggioModule,
  SelettoreDataAnypickerModule,
  SnackbarModule,
  LayoutPrincipaleModule,
  SelezioneValoriModule,
  PartiVisualizzazioneModule,
  VisualizzatoreValoriModule,
  RigaModificabileModule,
  Modelli,
  AbsTraduzioniService,
  DialogSelezioneOperazioneModule
} from '@pl-web-angular/libreria';
import { TranslateModule } from '@ngx-translate/core';
import { AttivitaComponent } from './pagine/attivita/attivita.component';
import { LoginComponent } from './pagine/login/login.component';
import { DispositiviComponent } from './pagine/dispositivi/dispositivi.component';
import { InvitiDispositiviComponent } from './pagine/inviti-dispositivi/inviti-dispositivi.component';
import { EstensioniComponent } from './pagine/estensioni/estensioni.component';
import { DocumentiComponent } from './pagine/documenti/documenti.component';
import { InvioDatiComponent } from './pagine/invio-dati/invio-dati.component';
import { RicezioneDatiComponent } from './pagine/ricezione-dati/ricezione-dati.component';
import { PaginaErroreComponent } from './pagine/pagina-errore/pagina-errore.component';
import { TodoTraduciPipe } from './utilita/todo-traduci.pipe';
import { registerLocaleData } from '@angular/common';
import { PermessoLetturaPipe } from './pipes/permesso-lettura.pipe';
import { PermessoModificaPipe } from './pipes/permesso-modifica.pipe';
import { DialogGestioneAvvioAttivitaComponent } from './pagine/attivita/dialog-gestione-avvio-attivita/dialog-gestione-avvio-attivita.component';
import { IconaDispositivoPreferitoComponent } from './componenti/icona-dispositivo-preferito/icona-dispositivo-preferito.component';
import { DialogDettagliDispositivoComponent } from './componenti/pagine/dialog-dettagli-dispositivo/dialog-dettagli-dispositivo.component';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { DialogDettagliPacchettoComponent } from './componenti/pagine/dialog-dettagli-pacchetto/dialog-dettagli-pacchetto.component';
import { DialogSostituisciORipristinaBackupComponent } from './componenti/pagine/dialog-sostituisci-o-ripristina-backup/dialog-sostituisci-o-ripristina-backup.component';
import { DialogInserimentoDispositivoComponent } from './componenti/pagine/dialog-inserimento-dispositivo/dialog-inserimento-dispositivo.component';
import { TraduzioniService } from './servizi/traduzioni.service';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AttivitaComponent,
    LoginComponent,
    DispositiviComponent,
    InvitiDispositiviComponent,
    EstensioniComponent,
    DocumentiComponent,
    InvioDatiComponent,
    RicezioneDatiComponent,
    PaginaErroreComponent,
    TodoTraduciPipe,
    PermessoLetturaPipe,
    PermessoModificaPipe,
    DialogGestioneAvvioAttivitaComponent,
    IconaDispositivoPreferitoComponent,
    DialogDettagliDispositivoComponent,
    DialogDettagliPacchettoComponent,
    DialogSostituisciORipristinaBackupComponent,
    DialogInserimentoDispositivoComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    LibreriaComponentiImportsBaseModule,
    CheckboxModule,
    EllipsisModule,
    LoaderModaleModule,
    RigaDatiModule,
    DialogModule,
    SelettoreDataModule,
    ErroriFormModule,
    DialogMessaggioModule,
    SelettoreDataAnypickerModule,
    SnackbarModule,
    LayoutPrincipaleModule,
    TranslateModule.forRoot(),
    SelezioneValoriModule,
    RigaDatiModule,
    PartiVisualizzazioneModule,
    VisualizzatoreValoriModule,
    VirtualScrollerModule,
    RigaModificabileModule,
    DialogSelezioneOperazioneModule
  ],
  entryComponents: [
    DialogGestioneAvvioAttivitaComponent,
    DialogDettagliDispositivoComponent,
    DialogDettagliPacchettoComponent,
    DialogSostituisciORipristinaBackupComponent,
    DialogInserimentoDispositivoComponent
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    { provide: LOCALE_ID, useValue: _ISELL_LOCALE_ANGULAR_CONFIGS.locale },
    { provide: AbsTraduzioniService, useClass: TraduzioniService }
  ]
})
export class AppModule { }
