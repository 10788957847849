import { Injectable, Inject, LOCALE_ID } from '@angular/core';
import { LoaderModaleService, DialogService, ModelloChiusuraDialog, BottoniDialog, Utilita, UtilitaDialogMessaggio } from '@pl-web-angular/libreria';
import { GestioneConnessioneHubService } from './gestione-connessione-hub.service';
import { TraduzioniService } from './traduzioni.service';
import { UUID } from 'angular2-uuid';
import { PL } from 'src/lib/isell';
import { formatDate } from '@angular/common';
import { GestioneNotificheDaServerService } from './gestione-notifiche-da-server.service';
import { RiferimentoDialog, TipiChiusuraDialog } from '@pl-web-angular/libreria';
import { TipiOperazioniDiGestioneDispositivi } from '../pagine/dispositivi/utilita-pagina-dispositivi';

export namespace RecuperoDatiBackup {
  export type ModelloDatiPacchettoBackupDispositivo = PL.iSell.Console.ServerWeb.Hubs.Modelli.ModelloDatiPacchettoBackupDispositivo;

  export enum TipiRejectBackup {
    FallimentoVerificaORiavvioConnessione = 0,
    FallimentoRichiestaAccodamentoRecuperoDati = 1,
    FallimentoRilevamentoDati = 2,
    AnnullamentoDuranteAnalisi = 3,
    NessunBackupDisponibile = 4
  }

  export class RejectBackup {
    constructor(public tipo: TipiRejectBackup, public messaggio: string) { }
  }

  export class ChiamataRichiestaBackup {
    constructor(
      public IDOperazione: string,
      public DeferredObj: JQuery.Deferred<DatiGestioneBackup, any, any>,
      public modelloDispositivo: PL.iSell.Console.ServerWeb.Hubs.Modelli.ModelloDispositivo,
      public riferimentoDialog: RiferimentoDialog,
      public tipoOperazione: TipiOperazioniDiGestioneDispositivi
    ) { }
  }

  export interface BackupConDescrizione extends ModelloDatiPacchettoBackupDispositivo {
    descrizioneGenerata: string;
  }

  export class DatiGestioneBackup {
    constructor(
      public dizionarioBackup: Utilita.Dizionario<string, BackupConDescrizione> = new Utilita.Dizionario<string, BackupConDescrizione>(),
      public elencoBackupDaDispositivo: BackupConDescrizione[] = [],
      public elencoBackupAltriDispositivi: BackupConDescrizione[] = []
    ) { }
  }
}

@Injectable({
  providedIn: 'root'
})
export class RecuperoDatiBackupService {

  //L'operazione di recupero dati è intesa per essere eseguita ed essere bloccante(tramite Loader), pertanto ci sarà sempre solo una chiamata
  private chiamataAttuale: RecuperoDatiBackup.ChiamataRichiestaBackup;

  constructor(
    private servizioLoader: LoaderModaleService,
    private servizioGestioneConnessioneHubs: GestioneConnessioneHubService,
    private servizioDialog: DialogService,
    private servizioTraduzioni: TraduzioniService,
    @Inject(LOCALE_ID) private locale: string,
    private servizioGestioneNotificheDaServer: GestioneNotificheDaServerService
  ) {
    this.servizioGestioneNotificheDaServer.onNotificaTermineAttesaRicezioneBackupDispositivi.subscribe((UUIDOperazione: string) => {
      if (Utilita.FunzioniVarie.isDefined(this.chiamataAttuale) && UUIDOperazione == this.chiamataAttuale.IDOperazione) {
        var deferredObj = this.chiamataAttuale.DeferredObj;
        var UUIDLoader = servizioLoader.mostraLoader();

        $.connection.hubGestioneDispositivi.server.rilevaBackupDisponibili().done((
          elencoBackup: RecuperoDatiBackup.ModelloDatiPacchettoBackupDispositivo[]
        ) => {
          if(Utilita.FunzioniVarie.isUndefinedOrNull(elencoBackup))
          {
              elencoBackup = [];
          }

          if (elencoBackup.length == 0 && (this.chiamataAttuale.tipoOperazione == TipiOperazioniDiGestioneDispositivi.RIPRISTINA_BACKUP || this.chiamataAttuale.tipoOperazione == TipiOperazioniDiGestioneDispositivi.INSERIMENTO_DISPOSITIVO)) {
            UtilitaDialogMessaggio.messaggioAvvisoSoloOK(
              servizioDialog,
              servizioTraduzioni,
              servizioTraduzioni.traduci("219_NON_ESISTONO_BACKUP_DISPONIBILI")
            );

            this.rejectDeferred(deferredObj, RecuperoDatiBackup.TipiRejectBackup.NessunBackupDisponibile, "Nessun backup disponibile");
          }
          else {
            this.chiamataAttuale.riferimentoDialog.richiediChiusura(TipiChiusuraDialog.Conferma);
            deferredObj.resolve(this.generaElementiPerGestioneSelezioneBackupConDescrizione(elencoBackup, this.chiamataAttuale.modelloDispositivo));
          }
        }).fail((errore) => {
          console.error(errore);
          this.rejectDeferred(deferredObj, RecuperoDatiBackup.TipiRejectBackup.FallimentoRilevamentoDati, "Impossibile rilevare i backup disponibili");
        }).always(() => {
          servizioLoader.nascondiLoader(UUIDLoader);
        });
      }
    });
  }

  rilevaBackupDisponibili(tipoOperazione : TipiOperazioniDiGestioneDispositivi, modelloDispositivo: PL.iSell.Console.ServerWeb.Hubs.Modelli.ModelloDispositivo = null): JQueryPromise<RecuperoDatiBackup.DatiGestioneBackup> {
    this.annullaRichiesta();
    var deferredObj = $.Deferred<RecuperoDatiBackup.DatiGestioneBackup>();
    var UUIDOperazione = UUID.UUID();

    var UUIDLoader = this.servizioLoader.mostraLoader();

    UtilitaDialogMessaggio.nuovoMessaggio(
      UtilitaDialogMessaggio.TipiMessaggio.Info,
      this.servizioDialog,
      this.servizioTraduzioni,
      this.servizioTraduzioni.traduci("223_ANALISI_BACKUP_DISPONIBILI") + '<div class="custom-loader isell-loader loaderBackup"></div>',
      (modelloChiusura) => {
        if (!modelloChiusura.confermato) {
          this.rejectDeferred(deferredObj, RecuperoDatiBackup.TipiRejectBackup.AnnullamentoDuranteAnalisi, "Annullamento recupero dati");
        }
      },
      BottoniDialog.Singolo_Annulla,
      null,
      this.servizioTraduzioni.traduci("12_ANNULLA")
    ).then((riferimentoDialog: RiferimentoDialog) => {
      this.chiamataAttuale = new RecuperoDatiBackup.ChiamataRichiestaBackup(UUIDOperazione, deferredObj, modelloDispositivo, riferimentoDialog, tipoOperazione);
      this.servizioGestioneConnessioneHubs.verificaConnessioneORiavvia().done(() => {
        $.connection.hubGestioneDispositivi.server.accodaRicezioneBackupDispositivi(UUIDOperazione).done(() => {
        }).fail((errore) => {
          console.error(errore);
          this.rejectDeferred(
            deferredObj,
            RecuperoDatiBackup.TipiRejectBackup.FallimentoRichiestaAccodamentoRecuperoDati,
            "Chiamata per accodamento ricezione backup dispositivi fallita");
        }).always(() => {
          this.servizioLoader.nascondiLoader(UUIDLoader);
        });
      }).fail((errore) => {
        console.error(errore);
        this.servizioLoader.nascondiLoader(UUIDLoader);
        this.rejectDeferred(deferredObj, RecuperoDatiBackup.TipiRejectBackup.FallimentoVerificaORiavvioConnessione, "Impossibile verificare e riavviare la connessione");
      });
    });

    return deferredObj.promise();
  }

  private rejectDeferred(
    deferredObj: JQuery.Deferred<RecuperoDatiBackup.DatiGestioneBackup, any, any>,
    tipo: RecuperoDatiBackup.TipiRejectBackup,
    messaggio: string): void {
    this.annullaRichiesta();
    deferredObj.reject(new RecuperoDatiBackup.RejectBackup(tipo, messaggio));
  }

  private generaElementiPerGestioneSelezioneBackupConDescrizione(
    elencoBackup: RecuperoDatiBackup.ModelloDatiPacchettoBackupDispositivo[],
    dispositivoPerSeparazione: PL.iSell.Console.ServerWeb.Hubs.Modelli.ModelloDispositivo,
  ): RecuperoDatiBackup.DatiGestioneBackup {

    var daTornare = new RecuperoDatiBackup.DatiGestioneBackup();

    elencoBackup.sort((backup1, backup2) => {
      var d1 = new Date(backup1.DataBackup);
      var d2 = new Date(backup2.DataBackup);

      if (d1 < d2) {
        return 1;
      }
      else if (d1 > d2) {
        return -1;
      }
      else {
        return 0;
      }
    });

    elencoBackup.forEach((backup) => {
      var dataFormattata = formatDate(new Date(backup.DataBackup).getTime(), "medium", this.locale);
      var descrizione = dataFormattata + " - " + backup.DescrizioneDispositivoOrigine;

      var backupConvertito = (backup as RecuperoDatiBackup.BackupConDescrizione);
      backupConvertito.descrizioneGenerata = descrizione;

      daTornare.dizionarioBackup.setValue(backupConvertito.NomeFile, backupConvertito);

      if (Utilita.FunzioniVarie.isDefined(dispositivoPerSeparazione) && backup.IDDispositivoOrigine == dispositivoPerSeparazione.IDDispositivo) {
        daTornare.elencoBackupDaDispositivo.push(backupConvertito);
      }
      else {
        daTornare.elencoBackupAltriDispositivi.push(backupConvertito);
      }
    });

    return daTornare;
  }

  annullaRichiesta(): void {
    if (Utilita.FunzioniVarie.isDefined(this.chiamataAttuale)) {
      this.chiamataAttuale.riferimentoDialog.richiediChiusura(TipiChiusuraDialog.Conferma);
    }
    this.chiamataAttuale = null;
  }
}
