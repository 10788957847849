import { Pipe, PipeTransform } from '@angular/core';
import { PL } from 'src/lib/isell';

@Pipe({
  name: 'PermessoLettura'
})
export class PermessoLetturaPipe implements PipeTransform {

  transform(value: PL.iSell.Console.ServerWeb.Moduli.UtilitaPermessi.ValoriPermessoServerWeb, args?: any): any {
    return (value == PL.iSell.Console.ServerWeb.Moduli.UtilitaPermessi.ValoriPermessoServerWeb.Lettura)
      || (value == PL.iSell.Console.ServerWeb.Moduli.UtilitaPermessi.ValoriPermessoServerWeb.Modifica);
  }

}
