import { Component, OnInit, Input, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { GestioneConnessioneHubService } from 'src/app/servizi/gestione-connessione-hub.service';
import { DatiService, ModelloDispositivo } from 'src/app/servizi/dati.service';
import { DialogService, FunzioneChiusuraDialog, ModelloChiusuraDialog, TipiChiusuraDialog, Utilita, UtilitaDialog, DatiComponenteParteDialog, AbsModelloContenutoDialog, EventoComunicazioneDialog, EsitoRichiestaChiusuraDialog, LoaderModaleService, SnackbarService, ModelloNotificaSnackbar, Modelli, UtilitaDialogMessaggio, UtilitaOperazioni } from '@pl-web-angular/libreria';
import { TraduzioniService } from 'src/app/servizi/traduzioni.service';
import { RecuperoDatiBackupService, RecuperoDatiBackup } from 'src/app/servizi/recupero-dati-backup.service';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { PL } from 'src/lib/isell';
import { TipiOperazioniDiGestioneDispositivi } from 'src/app/pagine/dispositivi/utilita-pagina-dispositivi';

class DatiInizializzazione {
  constructor(
    public operazione: TipiOperazioniDiGestioneDispositivi,
    public dispositivo: ModelloDispositivo,
    public datiGestioneBackup: RecuperoDatiBackup.DatiGestioneBackup
  ) { }
}

class GruppoFormPerBackup {
  constructor(
    public Email: Modelli.FormControlWithType<string>,
    public IDBackupSelezionato: Modelli.FormControlWithType<string>
  ) { }
}


@Component({
  selector: 'app-dialog-sostituisci-o-ripristina-backup',
  templateUrl: './dialog-sostituisci-o-ripristina-backup.component.html',
  styleUrls: ['./dialog-sostituisci-o-ripristina-backup.component.less']
})
export class DialogSostituisciORipristinaBackupComponent extends AbsModelloContenutoDialog<void> implements OnInit {

  @Input() datiInizializzazione: DatiInizializzazione;

  erroriForm: string[] = [];
  public formInterno: FormGroup;
  get FC(): GruppoFormPerBackup { return (this.formInterno.controls as any) as GruppoFormPerBackup; }
  @ViewChild('elementoForm', { static: true })
  private elementoForm: ElementRef;

  constructor(
    ref: ChangeDetectorRef,
    protected servizioLoader: LoaderModaleService,
    protected servizioDialog: DialogService,
    protected servizioTraduzioni: TraduzioniService,
    protected servizioSnackbar: SnackbarService,
    protected formBuilder: FormBuilder
  ) {
    super(ref);
  }

  ngOnInit() {
    var controlEmail = new Modelli.FormControlWithType(this.connettiOSostituisci ? this.datiInizializzazione.dispositivo.Email : "", [Validators.required, Validators.email]);
    if (this.ripristinoBackup) {
      controlEmail.disable();
    }
    var controlIDBackup = new Modelli.FormControlWithType("");
    if (this.ripristinoBackup) {
      controlIDBackup.setValidators([Validators.required]);
    }

    this.formInterno = this.formBuilder.group(new GruppoFormPerBackup(
      controlEmail,
      controlIDBackup
    ))
  }

  richiediChiusura(tipoChiusura: TipiChiusuraDialog): Promise<EsitoRichiestaChiusuraDialog<void>> {

    var fallisci = (): EsitoRichiestaChiusuraDialog<void> => {
      this.erroriForm.push("erroreGenericoNonGestito");
      Utilita.FunzioniVarie.eseguiAnimazioneErroriForm(this.elementoForm);
      this.detectChangesInContestoAsincrono();
      return new EsitoRichiestaChiusuraDialog(false, null);
    }

    var mostraMessaggioErroreGenerico = () => {
      UtilitaDialogMessaggio.messaggioErroreSoloOK(
        this.servizioDialog,
        this.servizioTraduzioni,
        this.servizioTraduzioni.traduci("215_IMPOSSIBILE_ESEGUIRE_LOPERAZIONE")
      )
    }

    if (tipoChiusura == TipiChiusuraDialog.Conferma) {
      this.erroriForm = [];
      this.formInterno.updateValueAndValidity();

      Utilita.FunzioniVarie.aggiungiFlagSubmitAForm(this.formInterno, this.elementoForm, this.ref);
      if (this.formInterno.valid) {

      } else {
        return Utilita.FunzioniVarie.PromiseConValore(new EsitoRichiestaChiusuraDialog(false, null));
      }
    }
    else {
      return Utilita.FunzioniVarie.PromiseConValore(new EsitoRichiestaChiusuraDialog(true, null));
    }

    return $.connection.hubGestioneDispositivi.server.sostituisciORipristinaDispositivo(
      this.connettiOSostituisci,
      this.datiInizializzazione.dispositivo.IDDispositivo,
      Utilita.FunzioniVarie.stringIsNullOrEmpty(this.FC.IDBackupSelezionato.value) ? null : this.FC.IDBackupSelezionato.value,
      this.connettiOSostituisci ? this.FC.Email.value : null,
    ).then((risultato) => {

      if (risultato.EsitoOperazione == PL.iSell.Console.ServerWeb.Hubs.Modelli.TipiEsitoOperazione.ErroreNonGestito) {
        return fallisci();
      }
      else if (risultato.EsitoOperazione == PL.iSell.Console.ServerWeb.Hubs.Modelli.TipiEsitoOperazione.Fallimento) {
        if (risultato.ErroriValidazione.length > 0) {
          risultato.ErroriValidazione.forEach((errore) => {
            switch (errore) {
              case PL.iSell.Console.ServerWeb.Hubs.HubGestioneDispositivi.ErroriValidazioneRipristinoBackup.EmailNonSpecificata: {
                this.erroriForm.push("emailNonSpecificata");
                break;
              }
              case PL.iSell.Console.ServerWeb.Hubs.HubGestioneDispositivi.ErroriValidazioneRipristinoBackup.EmailNonValida: {
                this.erroriForm.push("formatoEmailNonValido");
                break;
              }
              case PL.iSell.Console.ServerWeb.Hubs.HubGestioneDispositivi.ErroriValidazioneRipristinoBackup.IDDispositivoNonSpecificato: {
                this.erroriForm.push("dispositivoNonSpecificato");
                break;
              }
              case PL.iSell.Console.ServerWeb.Hubs.HubGestioneDispositivi.ErroriValidazioneRipristinoBackup.NomeFileBackupNonSpecificato: {
                this.erroriForm.push("backupNonSpecificato");
                break;
              }
            }
          });
        }
        else if (risultato.Errore.Impostato) {
          switch (risultato.Errore.Valore) {
            case PL.iSell.Console.ServerWeb.Hubs.HubGestioneDispositivi.ErroriPostValidazioneRipristinoBackup.DispositivoNonTrovato: {
              this.erroriForm.push("dispositivoNonTrovato");
              break;
            }
            case PL.iSell.Console.ServerWeb.Hubs.HubGestioneDispositivi.ErroriPostValidazioneRipristinoBackup.Nessuno: {
              mostraMessaggioErroreGenerico();
              break;
            }
          }
        }
        else {
          mostraMessaggioErroreGenerico();
        }
        Utilita.FunzioniVarie.eseguiAnimazioneErroriForm(this.elementoForm);
        this.detectChangesInContestoAsincrono();
        return new EsitoRichiestaChiusuraDialog(false, null);
      }
      else {
        this.servizioSnackbar.aggiungiNotifica(new ModelloNotificaSnackbar(this.servizioTraduzioni.traduci(
          this.connettiOSostituisci
            ? "227_RICHIESTA_DI_SOSTITUZIONE_DEL_DISPOSITIVO_DESCRIZIONEDISPOSITIVO_ESEGUITA_CON_SUCCESSO_VERIFICA_LA_TUA_CASELLA_DI_POSTA_ELETTRONICA_EMAIL"
            : "228_RICHIESTA_DI_RIPRISTINO_DEL_DISPOSITIVO_DESCRIZIONEDISPOSITIVO_ESEGUITA_CON_SUCCESSO",
          {
            DescrizioneDispositivo: Utilita.FunzioniVarie.wrapTestoConQuotesInElementoConColore(this.datiInizializzazione.dispositivo.IDDispositivo + " - " + this.datiInizializzazione.dispositivo.DescrizioneDispositivo, "span", "#fff"),
            Email: Utilita.FunzioniVarie.wrapTestoConQuotesInElementoConColore(this.FC.Email.value, "div", "#fff")
          }
        ), 3000));
        return new EsitoRichiestaChiusuraDialog(true, null);
      }
    }, () => {
      //Fail
      mostraMessaggioErroreGenerico();
      return fallisci();
    }).catch(() => {
      mostraMessaggioErroreGenerico();
      return fallisci();
    })
  }

  protected onComunicazioneDaComponenteDialog(comunicazione: EventoComunicazioneDialog): void {
  }

  get connettiOSostituisci(): boolean {
    return this.datiInizializzazione.operazione == TipiOperazioniDiGestioneDispositivi.CONNETTI_O_SOSTITUISCI;
  }

  get ripristinoBackup(): boolean {
    return this.datiInizializzazione.operazione == TipiOperazioniDiGestioneDispositivi.RIPRISTINA_BACKUP;
  }

  static nuovoDialog(
    operazione: TipiOperazioniDiGestioneDispositivi,
    dispositivo: ModelloDispositivo,
    servizioGestioneConnessioneHubs: GestioneConnessioneHubService,
    servizioDati: DatiService,
    servizioRecuperoDatiBackup: RecuperoDatiBackupService,
    servizioDialog: DialogService,
    servizioTraduzioni: TraduzioniService,
    callbackChiusura: FunzioneChiusuraDialog<void>
  ): void {

    if (operazione == TipiOperazioniDiGestioneDispositivi.RIPRISTINA_BACKUP && Utilita.FunzioniVarie.stringIsNullOrEmpty(dispositivo.Email)) {
      UtilitaDialogMessaggio.messaggioErroreSoloOK(
        servizioDialog,
        servizioTraduzioni,
        servizioTraduzioni.traduci("270_IMPOSSIBILE_PROCEDERE_CON_LOPERAZIONE_POICHE_IL_DISPOSITIVO_NON_DISPONE_DI_UN_INDIRIZZO_EMAIL_ASSOCIATO")
      );

      return;
    }

    var onFallimentoRecuperoBackup = () => {
      UtilitaOperazioni.gestisciFallimentoConRiprova(
        servizioDialog,
        servizioTraduzioni,
        "225_NON_E_STATO_POSSIBILE_RECUPERARE_I_DATI_RELATIVI_AI_BACKUP_TI_INVITIAMO_A_RIPROVARE",
        (modelloChiusura: ModelloChiusuraDialog<void>) => {
          if (modelloChiusura.confermato) {
            DialogSostituisciORipristinaBackupComponent.nuovoDialog(
              operazione,
              dispositivo,
              servizioGestioneConnessioneHubs,
              servizioDati,
              servizioRecuperoDatiBackup,
              servizioDialog,
              servizioTraduzioni,
              callbackChiusura
            )
          }
        }
      )
    }

    servizioRecuperoDatiBackup.rilevaBackupDisponibili(operazione).done((datiGestioneBackup: RecuperoDatiBackup.DatiGestioneBackup) => {
      var opzioniDialog = UtilitaDialog.generaOpzioniDialogDefault(
        new DatiComponenteParteDialog<DialogSostituisciORipristinaBackupComponent>(
          DialogSostituisciORipristinaBackupComponent,
          <DialogSostituisciORipristinaBackupComponent>{
            datiInizializzazione: new DatiInizializzazione(operazione, dispositivo, datiGestioneBackup)
          }
        ),
        servizioTraduzioni.traduci(
          operazione == TipiOperazioniDiGestioneDispositivi.CONNETTI_O_SOSTITUISCI ? "229_CONNETTI_O_SOSTITUISCI_IL_DISPOSITIVO" : "206_RIPRISTINA_BACKUP"),
        callbackChiusura
      );

      opzioniDialog.stickyFooter = false;

      servizioDialog.aggiungiDialog(opzioniDialog);
    }).fail((reject: RecuperoDatiBackup.RejectBackup | any) => {
      if (Utilita.FunzioniVarie.isDefined(reject.tipo)) {
        switch (reject.tipo) {
          case RecuperoDatiBackup.TipiRejectBackup.FallimentoVerificaORiavvioConnessione:
          case RecuperoDatiBackup.TipiRejectBackup.FallimentoRichiestaAccodamentoRecuperoDati:
          case RecuperoDatiBackup.TipiRejectBackup.FallimentoRilevamentoDati: {
            onFallimentoRecuperoBackup();
            break;
          }
          case RecuperoDatiBackup.TipiRejectBackup.AnnullamentoDuranteAnalisi:
          case RecuperoDatiBackup.TipiRejectBackup.NessunBackupDisponibile: {
            break;
          }
        }
      }
      else {
        console.log(reject);
        onFallimentoRecuperoBackup();
      }
    })

  }
}
