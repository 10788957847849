import { Injectable, ChangeDetectorRef, NgZone } from '@angular/core';
import { Utilita, Modelli, ModelliRecuperoDati, VisualizzazionePerGruppiEFiltro } from '@pl-web-angular/libreria';
import { TraduzioniService } from './traduzioni.service';
import { PL } from 'src/lib/isell';

export enum TipiRichiesteRecuperoDatiPerPagina {
  Attivita = 0,
  InvitiDispositivi = 1,
  Dispositivi = 2,
  InvioDati = 3,
  RicezioneDati = 4
}

export abstract class PaginaConRicaricaDatiWebApp<T extends VisualizzazionePerGruppiEFiltro<any, any, any, any, any>> extends ModelliRecuperoDati.PaginaConRicaricaDati<TipiRichiesteRecuperoDatiPerPagina, DatiService> {
  visualizzazioneGruppi: T;
  get VG() {
    return this.visualizzazioneGruppi;
  }

  constructor(
    ref: ChangeDetectorRef,
    servizioDati: DatiService,
    NgZone: NgZone
  ) {
    super(
      ref,
      servizioDati,
      NgZone
    );
  }

  callbackModificaFiltroRicerca_Post(): void {
    this.VG.filtroRicerca = this.filtroRicerca;
    this.VG.callbackModificaFiltroRicerca();
  }
}

export type ModelloAttivitaSemplificato = PL.iSell.Console.ServerWeb.Hubs.HubGestioneAttivita.ModelloAttivitaSemplificato;
export type ModelloGruppoAttivita = PL.iSell.Console.ServerWeb.Hubs.HubGestioneAttivita.ModelloGruppoAttivita;
export type ModelloInvitoDispositivo = PL.iSell.Console.ServerWeb.Hubs.HubGestioneInvitiDispositivi.ModelloInvitoDispositivo;
export type ModelloDispositivo = PL.iSell.Console.ServerWeb.Hubs.Modelli.ModelloDispositivo;
export type ModelloGruppoDispositivi = PL.iSell.Console.ServerWeb.Hubs.Modelli.ModelloGruppoDispositivi;
export type ModelloLingua = PL.iSell.Console.ServerWeb.Hubs.Modelli.ModelloLingua;
export type InformazioniOperatore = PL.iSell.Console.ServerWeb.Hubs.Modelli.InformazioniOperatore;
export type ModelloSerieChiave = PL.iSell.Console.ServerWeb.Hubs.Modelli.ModelloSerieChiave;
export type ModelloDatiElaborazione = PL.iSell.Console.ServerWeb.Hubs.HubGestioneInvioERicezioneDati.ModelloDatiElaborazione;
export type ModelloInformazioniBasePianificazioneAttivita = PL.iSell.Console.ServerWeb.Hubs.HubGestioneAttivita.ModelloInformazioniBasePianificazioneAttivita;

export class DatiRichiestaAttivita {
  constructor(
    public listaAttivita: ModelloAttivitaSemplificato[],
    public listaGruppiAttivita: ModelloGruppoAttivita[],
    public listaPianificazioni: ModelloInformazioniBasePianificazioneAttivita[]
  ) { }
}

export class DatiRichiestaInvitiDispositivi {
  constructor(
    public invitiDispositivi: ModelloInvitoDispositivo[]
  ) { }
}

export class DatiRichiestaDispositivi {
  constructor(
    private servizioTraduzioni: TraduzioniService,
    public dizionarioDispositivi: Utilita.Dizionario<string, ModelloDispositivo> = new Utilita.Dizionario<string, ModelloDispositivo>(),
    public dizionarioOperatori: Utilita.Dizionario<string, InformazioniOperatore> = new Utilita.Dizionario<string, InformazioniOperatore>(),
    public dizionarioGruppiDispositivi: Utilita.Dizionario<string, ModelloGruppoDispositivi> = new Utilita.Dizionario<string, ModelloGruppoDispositivi>(),
    public dizionarioLingue: Utilita.Dizionario<string, ModelloLingua> = new Utilita.Dizionario<string, ModelloLingua>()
  ) { }

  modificaDispositivi(dispositivi: ModelloDispositivo[]): void {
    dispositivi.sort((modello1: ModelloDispositivo, modello2: ModelloDispositivo) => {
      return modello1.DescrizioneDispositivo.localeCompare(modello2.DescrizioneDispositivo);
    });

    var dizionario = this.dizionarioDispositivi;
    dizionario.clear();
    dispositivi.forEach((dispositivo) => {
      dizionario.setValue(dispositivo.IDDispositivo, dispositivo);
    });
  }

  modificaGruppiDisposiviti(gruppi: ModelloGruppoDispositivi[]): void {
    gruppi.sort((gruppo1: ModelloGruppoDispositivi, gruppo2: ModelloGruppoDispositivi) => {
      return gruppo1.DescrizioneGruppoDispositivi.localeCompare(gruppo2.DescrizioneGruppoDispositivi);
    });

    var dizionario = this.dizionarioGruppiDispositivi;
    dizionario.clear();
    dizionario.setValue("", <ModelloGruppoDispositivi>{
      IDGruppoDispositivi: "",
      DescrizioneGruppoDispositivi: this.servizioTraduzioni.traduci("120_GRUPPO_PREDEFINITO")
    });
    gruppi.forEach((gruppo) => {
      dizionario.setValue(gruppo.IDGruppoDispositivi, gruppo);
    });
  }

  modificaLingue(lingue: ModelloLingua[]): void {
    lingue.sort((lingua1: ModelloLingua, lingua2: ModelloLingua) => {
      return lingua1.Descrizione.localeCompare(lingua2.Descrizione);
    });

    var dizionario = this.dizionarioLingue;
    dizionario.clear();
    if (!(lingue.length == 1 && lingue[0].UgualeALinguaArchivio)) {
      lingue.forEach((lingua) => {
        dizionario.setValue(lingua.ID, lingua);
      });
    }
    dizionario.setValue("", <ModelloLingua>{
      ID: "",
      Descrizione: "[" + this.servizioTraduzioni.traduci("32_DEFAULT") + "]",
      UgualeALinguaArchivio: false
    });
  }

  modificaOperatori(operatori: InformazioniOperatore[]): void {
    operatori.sort((operatore1, operatore2) => {
      return operatore1.DescrizioneOperatore.localeCompare(operatore2.DescrizioneOperatore);
    })

    var dizionario = this.dizionarioOperatori;
    dizionario.clear();
    operatori.forEach((operatore) => {
      dizionario.setValue(operatore.IDOperatore, operatore);
    });
  }
}

export class DatiRichiestiPerInserimentoEModificaDispositivo {
  constructor(
    public dizionarioOperatori: Utilita.Dizionario<string, InformazioniOperatore>,
    public dizionarioGruppiDispositivi: Utilita.Dizionario<string, ModelloGruppoDispositivi>,
    public dizionarioLingue: Utilita.Dizionario<string, ModelloLingua>,
    public dizionarioSerieChiavi: Utilita.Dizionario<string, ModelloSerieChiave>,
    public IDSerieChiaveIniziale: string,
    public esistonoChiaviDisponibili: boolean
  ) { }
}

export class DatiConStatoDefinito<T> {
  constructor(public dati: T) { }

  get definiti(): boolean {
    return Utilita.FunzioniVarie.isDefined(this.dati);
  }
}

export class DatiRichiestaInvioORicezioneDati {
  constructor(
    public listaElaborazioni: ModelloDatiElaborazione[]
  ) { }
}

@Injectable({
  providedIn: 'root'
})
export class DatiService extends ModelliRecuperoDati.AbsDatiService<TipiRichiesteRecuperoDatiPerPagina> {

  datiAttivita: DatiRichiestaAttivita = new DatiRichiestaAttivita([], [], []);
  datiInvitiDispositivi: DatiRichiestaInvitiDispositivi = new DatiRichiestaInvitiDispositivi([]);
  datiDispositivi: DatiRichiestaDispositivi = null;
  datiInvioDati: DatiRichiestaInvioORicezioneDati = new DatiRichiestaInvioORicezioneDati([]);
  datiRicezioneDati: DatiRichiestaInvioORicezioneDati = new DatiRichiestaInvioORicezioneDati([]);

  onSuccessoRicaricaDatiAttivita: Modelli.EmettitoreEventi<void>
    = new Modelli.EmettitoreEventi<void>();
  onSuccessoRicaricaDatiInvitiDispositivi: Modelli.EmettitoreEventi<void>
    = new Modelli.EmettitoreEventi<void>();
  onSuccessoRicaricaDatiDispositivi: Modelli.EmettitoreEventi<void>
    = new Modelli.EmettitoreEventi<void>();
  onSuccessoRicaricaDatiInvioDati: Modelli.EmettitoreEventi<void>
    = new Modelli.EmettitoreEventi<void>();
  onSuccessoRicaricaDatiRicezioneDati: Modelli.EmettitoreEventi<void>
    = new Modelli.EmettitoreEventi<void>();

  constructor(
    private servizioTraduzioni: TraduzioniService
  ) {
    super();

    this.datiDispositivi = new DatiRichiestaDispositivi(servizioTraduzioni);
  }

  protected generaElencoChiamate(): JQueryPromise<any>[] {
    var promisesDaChiamare: JQueryPromise<any>[] = [];
    this.tipiRichiesteRecuperoDatiPerPagina.forEach((tipo) => {
      switch (tipo) {
        case TipiRichiesteRecuperoDatiPerPagina.Attivita: {
          promisesDaChiamare.push(this.ricaricaDatiAttivita());
          break;
        }
        case TipiRichiesteRecuperoDatiPerPagina.InvitiDispositivi: {
          promisesDaChiamare.push(this.ricaricaDatiInvitiDispositivi())
          break;
        }
        case TipiRichiesteRecuperoDatiPerPagina.Dispositivi: {
          promisesDaChiamare.push(this.ricaricaDatiDispositivi())
          break;
        }
        case TipiRichiesteRecuperoDatiPerPagina.InvioDati:
        case TipiRichiesteRecuperoDatiPerPagina.RicezioneDati: {
          promisesDaChiamare.push(this.ricaricaDatiInvioORicezione(tipo))
          break;
        }
      }
    });
    return promisesDaChiamare;
  }

  private ricaricaDatiAttivita()
    : JQueryPromise<any> {

    return $.when(
      $.connection.hubGestioneAttivita.server.rilevaAttivitaConStato(true),
      $.connection.hubGestioneAttivita.server.rilevaElencoGruppiAttivita(),
      $.connection.hubGestioneAttivita.server.rilevaElencoAttivitaPianificate()
    ).done((
      listaAttivita: ModelloAttivitaSemplificato[],
      listaGruppiAttivita: ModelloGruppoAttivita[],
      listaPianificazioni: ModelloInformazioniBasePianificazioneAttivita[]
    ) => {
      var dati = new DatiRichiestaAttivita(listaAttivita, listaGruppiAttivita, listaPianificazioni);
      this.datiAttivita = dati;
      this.onSuccessoRicaricaDatiAttivita.next();
    })
  }

  private ricaricaDatiInvitiDispositivi()
    : JQueryPromise<any> {

    return $.when(
      $.connection.hubGestioneInvitiDispositivi.server.rilevaInvitiDispositivi()
    ).done((
      invitiDispositivi: ModelloInvitoDispositivo[]
    ) => {
      var dati = new DatiRichiestaInvitiDispositivi(invitiDispositivi);
      this.datiInvitiDispositivi = dati;
      this.onSuccessoRicaricaDatiInvitiDispositivi.next();
    })
  }

  private aggiornaGruppiDispositivi(): JQueryPromise<ModelloGruppoDispositivi[]> {
    return $.connection.hubGestioneDispositivi.server.rilevaGruppiDisponibili();
  }

  private aggiornaLingue(): JQueryPromise<ModelloLingua[]> {
    return $.connection.hubGestioneDispositivi.server.rilevaLingueDisponibili();
  }

  private aggiornaOperatori(): JQueryPromise<InformazioniOperatore[]> {
    return $.connection.hubGestioneDispositivi.server.rilevaOperatoriDisponibili();
  }

  private ricaricaDatiDispositivi()
    : JQueryPromise<any> {

    return $.when<any>(
      $.connection.hubGestioneDispositivi.server.rilevaDispositivi(false),
      this.aggiornaGruppiDispositivi(),
      this.aggiornaLingue(),
      this.aggiornaOperatori()
    ).done((
      dispositivi: ModelloDispositivo[],
      gruppi: ModelloGruppoDispositivi[],
      lingue: ModelloLingua[],
      operatori: InformazioniOperatore[]
    ) => {
      var dati = this.datiDispositivi
      dati.modificaDispositivi(dispositivi);
      dati.modificaGruppiDisposiviti(gruppi);
      dati.modificaLingue(lingue);
      dati.modificaOperatori(operatori);
      this.onSuccessoRicaricaDatiDispositivi.next();
    })
  }

  richiediDatiPerInserimentoEModificaDispositivo(): JQueryPromise<DatiRichiestiPerInserimentoEModificaDispositivo> {
    var deferredObj = $.Deferred<DatiRichiestiPerInserimentoEModificaDispositivo>();

    $.when<any>(
      this.aggiornaGruppiDispositivi(),
      this.aggiornaLingue(),
      this.aggiornaOperatori(),
      $.connection.hubGestioneDispositivi.server.rilevaChiaviDisponibili()
    ).done((
      gruppi: ModelloGruppoDispositivi[],
      lingue: ModelloLingua[],
      operatori: InformazioniOperatore[],
      serieChiavi: ModelloSerieChiave[]
    ) => {
      var dati = this.datiDispositivi;
      dati.modificaGruppiDisposiviti(gruppi);
      dati.modificaLingue(lingue);
      dati.modificaOperatori(operatori);

      var dizionarioSerieChiavi = new Utilita.Dizionario<string, ModelloSerieChiave>();
      var IDSerieChiaveIniziale: string = null;
      var esistonoChiaviDisponibili: boolean = false;

      serieChiavi.sort((serieChiave1, serieChiave2) => {
        return serieChiave1.DescrizioneSerie.localeCompare(serieChiave2.DescrizioneSerie);
      });

      serieChiavi.forEach((serieChiave: ModelloSerieChiave) => {
        if (serieChiave.Predefinita) {
          IDSerieChiaveIniziale = serieChiave.IDSerie;
          serieChiave.DescrizioneSerie = serieChiave.DescrizioneSerie + " (" + this.servizioTraduzioni.traduci("127_PREDEFINITA") + ")";
        }
        if (serieChiave.NumeroChiaviDeviceDisponibili > 0) {
          esistonoChiaviDisponibili = true;
        }
        dizionarioSerieChiavi.setValue(serieChiave.IDSerie, serieChiave);
      });

      if (Utilita.FunzioniVarie.isUndefinedOrNull(IDSerieChiaveIniziale)) {
        var serieChiaviOrdinate = (([]).concat(serieChiavi) as PL.iSell.Console.ServerWeb.Hubs.Modelli.ModelloSerieChiave[]).sort((serieChiave1, serieChiave2) => {
          if (serieChiave1.ChiaveTest && !serieChiave2.ChiaveTest) {
            return 1;
          }
          else if (!serieChiave1.ChiaveTest && serieChiave2.ChiaveTest) {
            return -1;
          }
          else if (serieChiave1.ChiaveTest && serieChiave2.ChiaveTest) {
            return 0;
          }
          else {
            var d1 = new Date(serieChiave1.DataInizioValidita);
            var d2 = new Date(serieChiave2.DataInizioValidita);
            if (d1 < d2) {
              return -1;
            }
            else if (d1 > d2) {
              return 1;
            }
            else {
              return 0;
            }
          }
        });

        if (serieChiaviOrdinate.length > 0) {
          IDSerieChiaveIniziale = serieChiaviOrdinate[0].IDSerie;
        }
      }

      var datiPerOperazione = new DatiRichiestiPerInserimentoEModificaDispositivo(
        dati.dizionarioOperatori,
        dati.dizionarioGruppiDispositivi,
        dati.dizionarioLingue,
        dizionarioSerieChiavi,
        IDSerieChiaveIniziale,
        esistonoChiaviDisponibili
      );

      deferredObj.resolve(datiPerOperazione);
    }).fail(() => {
      deferredObj.reject();
    }).always(() => {
    })

    return deferredObj.promise();
  }

  private ricaricaDatiInvioORicezione(tipoRichiesta: TipiRichiesteRecuperoDatiPerPagina.InvioDati | TipiRichiesteRecuperoDatiPerPagina.RicezioneDati): JQueryPromise<ModelloDatiElaborazione[]> {
    var tipoRichiestaEffettivo = tipoRichiesta == TipiRichiesteRecuperoDatiPerPagina.InvioDati
      ? PL.iSell.Console.ServerWeb.Hubs.HubGestioneInvioERicezioneDati.TipiRichiestaElaborazioni.InvioDati
      : PL.iSell.Console.ServerWeb.Hubs.HubGestioneInvioERicezioneDati.TipiRichiestaElaborazioni.RicezioneDati;

    return $.connection.hubGestioneInvioERicezioneDati.server.rilevaDatiElaborazioni(tipoRichiestaEffettivo, true)
      .done((
        datiElaborazione: ModelloDatiElaborazione[]
      ) => {
        if (tipoRichiesta == TipiRichiesteRecuperoDatiPerPagina.InvioDati) {
          this.datiInvioDati = new DatiRichiestaInvioORicezioneDati(datiElaborazione);
          this.onSuccessoRicaricaDatiInvioDati.next()
        }
        else {
          this.datiRicezioneDati = new DatiRichiestaInvioORicezioneDati(datiElaborazione);
          this.onSuccessoRicaricaDatiRicezioneDati.next()
        }
      })
  }

  dispositivoDaDizionarioLocale(IDDispositivo: string): DatiConStatoDefinito<ModelloDispositivo> {
    return new DatiConStatoDefinito(this.datiDispositivi.dizionarioDispositivi.getValue(IDDispositivo));
  }
}
