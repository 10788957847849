import { PL } from "src/lib/isell";

export namespace UtilitaAttivita {
    export function messaggioPerStatoAttivita(attivita: PL.iSell.Console.ServerWeb.Hubs.HubGestioneAttivita.ModelloAttivitaSemplificato): string {
        switch (attivita.StatoAttivita) {
            case PL.iSell.Console.ServerWeb.Hubs.HubGestioneAttivita.StatiAttivita.InCoda:
                return "96_IN_CODA_DI_ESECUZIONE";
            case PL.iSell.Console.ServerWeb.Hubs.HubGestioneAttivita.StatiAttivita.InElaborazione:
                return "95_IN_ESECUZIONE";
            case PL.iSell.Console.ServerWeb.Hubs.HubGestioneAttivita.StatiAttivita.NonAttiva:
                return "97_NON_ATTIVA";
            case PL.iSell.Console.ServerWeb.Hubs.HubGestioneAttivita.StatiAttivita.NonDisponibile:
            default:
                return "119_NON_DISPONIBILE";
        }
    }

    export function messaggioPerTipoAttivita(attivita: PL.iSell.Console.ServerWeb.Hubs.HubGestioneAttivita.ModelloAttivitaSemplificato): string {
        switch (attivita.TipoAttivita) {
            case PL.iSell.Console.ServerWeb.Hubs.HubGestioneAttivita.TipiAttivita.ElaborazioneDatiDaDispositivi:
                return "89_ELABORAZIONE_DATI_DA_DISPOSITIVI";
            case PL.iSell.Console.ServerWeb.Hubs.HubGestioneAttivita.TipiAttivita.InvioDatiADispositivi:
                return "90_INVIA_DATI_A_DISPOSITIVI";
            case PL.iSell.Console.ServerWeb.Hubs.HubGestioneAttivita.TipiAttivita.InvioScriptADispositivi:
                return "91_INVIA_SCRIPT_A_DISPOSITIVI";
            case PL.iSell.Console.ServerWeb.Hubs.HubGestioneAttivita.TipiAttivita.OperazioniGeneriche:
                return "92_OPERAZIONI_GENERICHE";
            case PL.iSell.Console.ServerWeb.Hubs.HubGestioneAttivita.TipiAttivita.PreparazioneDatiPerDispositivi:
                return "93_PREPARAZIONE_DATI_PER_DISPOSITIVI";
            case PL.iSell.Console.ServerWeb.Hubs.HubGestioneAttivita.TipiAttivita.RicezioneDatiDaDispositivi:
                return "94_RICEZIONE_DATI_DA_DISPOSITIVI";
            case PL.iSell.Console.ServerWeb.Hubs.HubGestioneAttivita.TipiAttivita.Sconosciuto:
            default:
                return "88_INFORMAZIONE_NON_DISPONIBILE";
        }
    }
}