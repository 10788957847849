import { PL } from "src/lib/isell";
import { SnackbarService, ModelloNotificaSnackbar, EsitoRichiestaChiusuraDialog, DialogService, ModelloChiusuraDialog, TipiChiusuraDialog, UtilitaDialogMessaggio } from "@pl-web-angular/libreria";
import { TraduzioniService } from "src/app/servizi/traduzioni.service";

export namespace UtilitaAvvioAttivita {
    export const MSG_ERRORE_NO_CANALE_PREDEFINITO: string = "201_IMPOSSIBILE_AVVIARE_LATTIVITA_POICHE_NON_POSSIEDE_UN_CANALE_PREDEFINITO_ASSOCIATO";

    export function avviaAttivita(
        servizioDialog: DialogService,
        servizioSnackbar: SnackbarService,
        servizioTraduzioni: TraduzioniService,
        attivita: PL.iSell.Console.ServerWeb.Hubs.HubGestioneAttivita.ModelloAttivitaSemplificato,
        IDDispositiviSelezionati: string[]): JQueryPromise<EsitoRichiestaChiusuraDialog<void>> {

        return $.connection.hubGestioneAttivita.server.avviaAttivita(attivita.IDAttivita, IDDispositiviSelezionati).then((risultatoOperazione: PL.iSell.Console.ServerWeb.Hubs.HubGestioneAttivita.TipologiaErroreAvvioAttivita) => {

            if (risultatoOperazione == PL.iSell.Console.ServerWeb.Hubs.HubGestioneAttivita.TipologiaErroreAvvioAttivita.NessunErrore) {
                servizioSnackbar.aggiungiNotifica(new ModelloNotificaSnackbar(
                    servizioTraduzioni.traduci("101_ATTIVITA_DESCRIZIONE_AVVIATA_CON_SUCCESSO", {
                        Descrizione: ("'" + attivita.DescrizioneAttivita + "'")
                    }), 3000
                ))
                return new EsitoRichiestaChiusuraDialog(true, null);
            } else {
                var codiceErrore = "";

                switch (risultatoOperazione) {
                    case PL.iSell.Console.ServerWeb.Hubs.HubGestioneAttivita.TipologiaErroreAvvioAttivita.ErroreDispositiviMancanti:
                        codiceErrore = "116_DISPOSITIVI_MANCANTI_PER_LAVVIO_ATTIVITA";
                        break;
                    case PL.iSell.Console.ServerWeb.Hubs.HubGestioneAttivita.TipologiaErroreAvvioAttivita.ErroreMultipliDispositiviSuSelezioneSingola:
                        codiceErrore = "118_IMPOSSIBILE_SELEZIONARE_PIU_DI_UN_DISPOSITIVO_PER_QUESTA_ATTIVITA";
                        break;
                    case PL.iSell.Console.ServerWeb.Hubs.HubGestioneAttivita.TipologiaErroreAvvioAttivita.ErroreNessunCanalePredefinitoAssociatoAdAttivita:
                        codiceErrore = MSG_ERRORE_NO_CANALE_PREDEFINITO;
                        break;
                    case PL.iSell.Console.ServerWeb.Hubs.HubGestioneAttivita.TipologiaErroreAvvioAttivita.ErroreCanaleAssociatoAdAttivitaNonTrovato:
                        codiceErrore = "202_IMPOSSIBILE_AVVIARE_LATTIVITA_POICHE_NON_E_STATO_POSSIBILE_RILEVARE_IL_CANALE_PREDEFINITO_ASSOCIATO";
                        break;
                    case PL.iSell.Console.ServerWeb.Hubs.HubGestioneAttivita.TipologiaErroreAvvioAttivita.ErroreAvvioAttivita:
                    default:
                        codiceErrore = "100_NON_E_STATO_POSSIBILE_AVVIARE_LATTIVITA";
                        break;
                }

                UtilitaDialogMessaggio.messaggioErroreSoloOK(
                    servizioDialog,
                    servizioTraduzioni,
                    servizioTraduzioni.traduci(codiceErrore)
                );
                return new EsitoRichiestaChiusuraDialog(false, null);
            }
        }, (e) => {
            console.error(e);
            UtilitaDialogMessaggio.messaggioErroreSoloOK(
                servizioDialog,
                servizioTraduzioni,
                servizioTraduzioni.traduci("100_NON_E_STATO_POSSIBILE_AVVIARE_LATTIVITA")
            );
            return new EsitoRichiestaChiusuraDialog(false, null);
        })
    }

    export function avviaPianificazione(
        servizioDialog: DialogService,
        servizioSnackbar: SnackbarService,
        servizioTraduzioni: TraduzioniService,
        pianificazione: PL.iSell.Console.ServerWeb.Hubs.HubGestioneAttivita.ModelloInformazioniBasePianificazioneAttivita): JQueryPromise<EsitoRichiestaChiusuraDialog<void>> {

        return $.connection.hubGestioneAttivita.server.avviaPianificazioneAttivita(pianificazione.IDPianificazioneAttivita).then(
            (risultatoOperazione: boolean) => {

                if (risultatoOperazione) {
                    servizioSnackbar.aggiungiNotifica(new ModelloNotificaSnackbar(
                        servizioTraduzioni.traduci("304_PIANIFICAZIONE_AVVIATA_CON_SUCCESSO", {
                            Descrizione: ("'" + pianificazione.DescrizionePianificazioneAttivita + "'")
                        }), 3000
                    ))
                    return new EsitoRichiestaChiusuraDialog(true, null);
                } else {
                    UtilitaDialogMessaggio.messaggioErroreSoloOK(
                        servizioDialog,
                        servizioTraduzioni,
                        servizioTraduzioni.traduci("215_IMPOSSIBILE_ESEGUIRE_LOPERAZIONE")
                    );
                    return new EsitoRichiestaChiusuraDialog(false, null);
                }
            }, (e) => {
                console.error(e);
                UtilitaDialogMessaggio.messaggioErroreSoloOK(
                    servizioDialog,
                    servizioTraduzioni,
                    servizioTraduzioni.traduci("215_IMPOSSIBILE_ESEGUIRE_LOPERAZIONE")
                );
                return new EsitoRichiestaChiusuraDialog(false, null);
            })
    }
}