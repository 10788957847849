import { Injectable } from '@angular/core';
import { DatiService } from 'src/app/servizi/dati.service';
import { LoaderModaleService, SnackbarService, ModelloNotificaSnackbar, Modelli } from '@pl-web-angular/libreria';
import { TraduzioniService } from 'src/app/servizi/traduzioni.service';

@Injectable({
  providedIn: 'root'
})
export class IconaDispositivoPreferitoService {

  onModificaPreferito: Modelli.EmettitoreEventi<string> = new Modelli.EmettitoreEventi<string>();

  constructor(
    private servizioDati: DatiService,
    private servizioLoader: LoaderModaleService,
    private servizioTraduzioni: TraduzioniService,
    private servizioSnackbar: SnackbarService
  ) { }

  aggiungiORimuoviDaPreferiti(
    IDDispositivo: string
  ): void {
    var UUIDLoader = this.servizioLoader.mostraLoader();

    var datiDispositivo = this.servizioDati.dispositivoDaDizionarioLocale(IDDispositivo);

    if (!datiDispositivo.definiti) {
      this.servizioLoader.nascondiLoader(UUIDLoader);
      //TODO notifica?
      return;
    }

    var dispositivo = datiDispositivo.dati;
    var ValoreDaImpostare = !dispositivo.Preferito;

    $.connection.hubGestioneDispositivi.server.aggiornaERilevaStatoPreferitoPerDispositivo(IDDispositivo, ValoreDaImpostare).done((valoreAttuale: boolean) => {
      this.modificaPreferitoENotifica(IDDispositivo, valoreAttuale);
      var oggettoTraduzione = {
        DescrizioneDispositivo: dispositivo.DescrizioneDispositivo
      };
      if (valoreAttuale) {
        this.servizioSnackbar.aggiungiNotifica(
          new ModelloNotificaSnackbar(this.servizioTraduzioni.traduci("252_DISPOSITIVO_DESCRIZIONEDISPOSITIVO_AGGIUNTO_AI_PREFERITI", oggettoTraduzione), 1000));
      }
      else {
        this.servizioSnackbar.aggiungiNotifica(
          new ModelloNotificaSnackbar(this.servizioTraduzioni.traduci("253_DISPOSITIVO_DESCRIZIONEDISPOSITIVO_RIMOSSO_DAI_PREFERITI", oggettoTraduzione), 1000));
      }
    }).fail((error) => {
      console.error(error);
      //TODO
    }).always(() => {
      this.servizioLoader.nascondiLoader(UUIDLoader);
    });
  }

  private modificaPreferitoENotifica(IDDispositivo: string, valoreDaImpostare: boolean) {
    var datiDispositivo = this.servizioDati.dispositivoDaDizionarioLocale(IDDispositivo);
    if (datiDispositivo.definiti) {
      datiDispositivo.dati.Preferito = valoreDaImpostare;
      this.onModificaPreferito.next(IDDispositivo);
    }
  }
}
