import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'TODOTraduci'
})
export class TodoTraduciPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return value;
  }

}
