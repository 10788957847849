import { Component, OnInit, ChangeDetectorRef, Input } from '@angular/core';
import { AbsModelloContenutoDialog, EsitoRichiestaChiusuraDialog, EventoComunicazioneDialog, TipiChiusuraDialog, DialogService, Utilita, UtilitaDialog, DatiComponenteParteDialog, GestioneDimensioniDialog, BottoniDialog } from '@pl-web-angular/libreria';
import { PL } from 'src/lib/isell';
import { TraduzioniService } from 'src/app/servizi/traduzioni.service';

export namespace DettagliPacchetto {
  export class DatiInizializzazione {
    constructor(
      public pacchetto: PL.iSell.Console.ServerWeb.Hubs.HubGestioneInvioERicezioneDati.ModelloDatiPacchettoElaborazione,
      public datiDescrizionePacchetto: PL.iSell.Console.ServerWeb.Hubs.HubGestioneInvioERicezioneDati.ModelloPerDescrizionePacchetto
    ) { }
  }
}

@Component({
  selector: 'app-dialog-dettagli-pacchetto',
  templateUrl: './dialog-dettagli-pacchetto.component.html',
  styleUrls: ['./dialog-dettagli-pacchetto.component.less']
})
export class DialogDettagliPacchettoComponent extends AbsModelloContenutoDialog<void> implements OnInit {

  @Input() datiInizializzazione: DettagliPacchetto.DatiInizializzazione;

  constructor(
    ref: ChangeDetectorRef
  ) {
    super(ref);
  }

  ngOnInit() {
  }

  richiediChiusura(tipoChiusura: TipiChiusuraDialog): Promise<EsitoRichiestaChiusuraDialog<void>> {
    return Utilita.FunzioniVarie.PromiseConValore(new EsitoRichiestaChiusuraDialog(true, null));
  }
  protected onComunicazioneDaComponenteDialog(comunicazione: EventoComunicazioneDialog): void {
  }

  static nuovoDialog(
    pacchetto: PL.iSell.Console.ServerWeb.Hubs.HubGestioneInvioERicezioneDati.ModelloDatiPacchettoElaborazione,
    datiDescrizionePacchetto: PL.iSell.Console.ServerWeb.Hubs.HubGestioneInvioERicezioneDati.ModelloPerDescrizionePacchetto,
    servizioDialog: DialogService,
    servizioTraduzioni: TraduzioniService
  ): void {
    if (Utilita.FunzioniVarie.isDefined(pacchetto) && Utilita.FunzioniVarie.isDefined(datiDescrizionePacchetto)) {
      var opzioniDialog = UtilitaDialog.generaOpzioniDialogDefault<void, DialogDettagliPacchettoComponent>(
        new DatiComponenteParteDialog<DialogDettagliPacchettoComponent>(
          DialogDettagliPacchettoComponent,
          <DialogDettagliPacchettoComponent>{
            datiInizializzazione: new DettagliPacchetto.DatiInizializzazione(pacchetto, datiDescrizionePacchetto)
          }
        ),
        servizioTraduzioni.traduci("235_DETTAGLI"),
        () => { },
        new GestioneDimensioniDialog(),
        BottoniDialog.Singolo_Annulla,
        null,
        servizioTraduzioni.traduci("73_CHIUDI"),
        true,
        true
      );

      servizioDialog.aggiungiDialog(opzioniDialog);
    }
  }
}
