import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GestioneConnessioneHubService {


  constructor() {
  }

  private codaCallbackOnStart: any[] = [];

  avvia(): JQueryPromise<any> {
    return $.connection.hub.start(() => {
      while (this.codaCallbackOnStart.length > 0) {
        this.codaCallbackOnStart[0]();
        this.codaCallbackOnStart.splice(0, 1);
      }
    });
  }

  registraCallbackAdAvvioConnessione(callback: any): void {
    this.codaCallbackOnStart.push(callback);
  }

  connesso(): boolean {
    return $.connection.hub.state == SignalR.ConnectionState.Connected;
  }

  verificaConnessioneORiavvia(): JQueryPromise<any> {
    if ($.connection.hub.state == SignalR.ConnectionState.Disconnected) {
      return $.connection.hub.start();
    }

    return $.when(null);
  }
}
