import { Injectable } from '@angular/core';
import { GestioneConnessioneHubService } from './gestione-connessione-hub.service';
import { Utilita } from '@pl-web-angular/libreria';
import { PL } from 'src/lib/isell';

export class DatiUtenteEstesi implements PL.iSell.Console.ServerWeb.Hubs.HubLogin.DatiUtente {
  Descrizione: string;
  IDArchivio: string;
  ElencoPermessiUtente: PL.iSell.Console.ServerWeb.Moduli.UtilitaPermessi.AmbitoConValorePermesso[];

  dizionarioPermessiUtenti: Utilita.Dizionario<
    PL.iSell.Console.ServerWeb.Moduli.UtilitaPermessi.AmbitiPermessoServerWeb,
    PL.iSell.Console.ServerWeb.Moduli.UtilitaPermessi.ValoriPermessoServerWeb>;
}

export class ModelloDatiSessioneUtenteConnessoEsteso implements PL.iSell.Console.ServerWeb.Hubs.HubLogin.ModelloDatiSessioneUtenteConnesso {
  SezioniAbilitate: PL.iSell.Console.ServerWeb.InformazioniServerWebConsole.SezioniAbilitabili[];
  DataScadenzaSessione: string;
  DatiUtente: DatiUtenteEstesi;
  PresenzaLogoArchivio: boolean;
  IDArchivio: string;
  DescrizioneArchivio: string;
  ElencoLinkAusiliariArchivio: PL.iSell.Console.ServerWeb.Hubs.HubLogin.ModelloLinkAusiliarioArchivio[];

  static converti(mb: PL.iSell.Console.ServerWeb.Hubs.HubLogin.ModelloDatiSessioneUtenteConnesso) {
    var dizionarioPermessiUtente = new Utilita.Dizionario<
      PL.iSell.Console.ServerWeb.Moduli.UtilitaPermessi.AmbitiPermessoServerWeb,
      PL.iSell.Console.ServerWeb.Moduli.UtilitaPermessi.ValoriPermessoServerWeb>();

    mb.DatiUtente.ElencoPermessiUtente.forEach((ambitoConValore) => {
      dizionarioPermessiUtente.setValue(ambitoConValore.Ambito, ambitoConValore.Valore);
    });

    return <ModelloDatiSessioneUtenteConnessoEsteso>{
      DataScadenzaSessione: mb.DataScadenzaSessione,
      DatiUtente: <DatiUtenteEstesi>{
        IDArchivio: mb.DatiUtente.IDArchivio,
        Descrizione: mb.DatiUtente.Descrizione,
        ElencoPermessiUtente: mb.DatiUtente.ElencoPermessiUtente,
        dizionarioPermessiUtenti: dizionarioPermessiUtente
      },
      SezioniAbilitate: mb.SezioniAbilitate,
      PresenzaLogoArchivio: mb.PresenzaLogoArchivio,
      IDArchivio: mb.IDArchivio,
      DescrizioneArchivio: mb.IDArchivio,
      ElencoLinkAusiliariArchivio: mb.ElencoLinkAusiliariArchivio
    }
  }
}


@Injectable({
  providedIn: 'root'
})
export class UtenteConnessoService {

  static readonly CHIAVE_IDARCHIVIO_SESSIONSTORAGE: string = "iSellConsole_UltimoIDArchivio";

  public datiLogin: ModelloDatiSessioneUtenteConnessoEsteso;

  constructor(
    private servizioGestioneConnessioneHub: GestioneConnessioneHubService
  ) { }

  rilevaUtente(): Promise<boolean> {
    let rilevaUtenteDaServer = (): JQueryPromise<boolean> => {
      //CONNESSIONE SIGNALR
      return $.connection.hubLogin.server.rilevaUtenteConnesso().then((datiLogin: PL.iSell.Console.ServerWeb.Hubs.HubLogin.ModelloDatiSessioneUtenteConnesso) => {
        //SUCCESSO RICHIESTA UTENTE
        if (Utilita.FunzioniVarie.isDefined(datiLogin)) {
          this.datiLogin = ModelloDatiSessioneUtenteConnessoEsteso.converti(datiLogin);
          this.salvaIDArchivioInSessionStorage();
          return true;
        }
        else {
          this.pulisciIDArchivioInSessionStorage();
          return false;
        }
      }, () => {
        //FALLIMENTO RICHIESTA UTENTE
        this.pulisciIDArchivioInSessionStorage();
        return false;
      });
    }

    return Promise.resolve().then(() => {
      return this.servizioGestioneConnessioneHub.verificaConnessioneORiavvia();
    }).then(() => {
      if (Utilita.FunzioniVarie.isDefined(this.datiLogin)
        && Utilita.FunzioniVarie.isDefined(this.datiLogin.DatiUtente)
        && Utilita.FunzioniVarie.isDefined(this.datiLogin.DataScadenzaSessione)
        && (new Date(this.datiLogin.DataScadenzaSessione).getTime() > (new Date().getTime() + 60000))//5 min
      ) {
        return $.Deferred().resolve(true).promise();
      }
      else {
        return rilevaUtenteDaServer();
      }
    }, () => {
      //CONNESSIONE SIGNALR FALLITA
      return false;
    });
  }

  get connesso(): boolean {
    return Utilita.FunzioniVarie.isDefined(this.datiLogin);
  }

  private verificaPermesso(
    ambito: PL.iSell.Console.ServerWeb.Moduli.UtilitaPermessi.AmbitiPermessoServerWeb,
    valoreRichiesto: PL.iSell.Console.ServerWeb.Moduli.UtilitaPermessi.ValoriPermessoServerWeb): boolean {
    if (this.connesso) {
      var valore = this.datiLogin.DatiUtente.dizionarioPermessiUtenti.getValue(ambito);
      if (Utilita.FunzioniVarie.isDefined(valore)) {
        return valore >= valoreRichiesto;
      }
      else {
        return false;
      }
    }
    else {
      return false;
    }
  }

  permessoLettura(ambito: PL.iSell.Console.ServerWeb.Moduli.UtilitaPermessi.AmbitiPermessoServerWeb): boolean {
    return this.verificaPermesso(ambito, PL.iSell.Console.ServerWeb.Moduli.UtilitaPermessi.ValoriPermessoServerWeb.Lettura);
  }

  permessoModifica(ambito: PL.iSell.Console.ServerWeb.Moduli.UtilitaPermessi.AmbitiPermessoServerWeb): boolean {
    return this.verificaPermesso(ambito, PL.iSell.Console.ServerWeb.Moduli.UtilitaPermessi.ValoriPermessoServerWeb.Modifica);
  }

  sezioneAbilitata(daVerificare: PL.iSell.Console.ServerWeb.InformazioniServerWebConsole.SezioniAbilitabili): boolean {
    if (this.connesso) {
      return this.datiLogin.SezioniAbilitate.indexOf(daVerificare) != -1;
    }
    else {
      return false;
    }
  }

  valorePermessoAmbito(ambito: PL.iSell.Console.ServerWeb.Moduli.UtilitaPermessi.AmbitiPermessoServerWeb)
    : PL.iSell.Console.ServerWeb.Moduli.UtilitaPermessi.ValoriPermessoServerWeb {
    if (this.connesso) {
      var valore = this.datiLogin.DatiUtente.dizionarioPermessiUtenti.getValue(ambito);
      if (Utilita.FunzioniVarie.isDefined(valore)) {
        return valore;
      }
      else {
        return PL.iSell.Console.ServerWeb.Moduli.UtilitaPermessi.ValoriPermessoServerWeb.Nessuno;
      }
    }
    else {
      return PL.iSell.Console.ServerWeb.Moduli.UtilitaPermessi.ValoriPermessoServerWeb.Nessuno;
    }
  }

  private salvaIDArchivioInSessionStorage(): void {
    try {
      if (this.connesso && Utilita.FunzioniVarie.isDefined(window.sessionStorage)) {
        window.sessionStorage[UtenteConnessoService.CHIAVE_IDARCHIVIO_SESSIONSTORAGE] = this.datiLogin.DatiUtente.IDArchivio;
      }
    } catch (e) { }
  }

  private pulisciIDArchivioInSessionStorage(): void {
    try {
      if (this.connesso && Utilita.FunzioniVarie.isDefined(window.sessionStorage)) {
        delete window.sessionStorage[UtenteConnessoService.CHIAVE_IDARCHIVIO_SESSIONSTORAGE];
      }
    } catch (e) { }
  }

  rilevaIDArchivioInSessionStorage(): string {
    try {
      if (Utilita.FunzioniVarie.isDefined(window.sessionStorage)) {
        var valore = window.sessionStorage[UtenteConnessoService.CHIAVE_IDARCHIVIO_SESSIONSTORAGE];
        return Utilita.FunzioniVarie.isDefined(valore) ? valore : null;
      }
      else {
        return null;
      }
    } catch (e) {
      return null;
    }
  }
}
