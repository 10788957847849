import { UtenteConnessoService } from "../servizi/utente-connesso.service";
import { Router } from "@angular/router";
import { Utilita } from "@pl-web-angular/libreria";

export namespace UtilitaAutenticazione {
    export function logoutConRicaricaPagina(router: Router, servizioUtenteConnesso: UtenteConnessoService) {
        var redirectTo = router.url;
        var IDArchivioPerQueryParams = null;
        $.when(
            $.connection.hubLogin.server.supportaArchiviMultipli(),
            $.post("/logout")
        ).done((supportaArchiviMultipli: boolean) => {
            if (servizioUtenteConnesso.connesso) {
                var IDArchivio = servizioUtenteConnesso.datiLogin.DatiUtente.IDArchivio;
                servizioUtenteConnesso.datiLogin = null;

                if (Utilita.FunzioniVarie.isDefined(IDArchivio) && supportaArchiviMultipli) {
                    IDArchivioPerQueryParams = IDArchivio;
                }
            }
        }).fail(() => {

        }).always(() => {
            //La pagina viene richiesta nuovamente dal server in modo che i dati locali vengano cancellati
            window.location.href = router.createUrlTree(
                ["/login"], {
                    queryParams: creaQueryParams(redirectTo, IDArchivioPerQueryParams)
                }
            ).toString();
        })
    }

    export interface QueryParamsLogin {
        redirectTo?: string;
        Archivio?: string;
    }

    export function creaQueryParams(redirectTo: string, IDArchivio: string = null): QueryParamsLogin {
        var daTornare: QueryParamsLogin = {};

        if (Utilita.FunzioniVarie.isDefined(redirectTo)) {
            daTornare.redirectTo = redirectTo;
        }

        if (Utilita.FunzioniVarie.isDefined(IDArchivio)) {
            daTornare.Archivio = IDArchivio;
        }

        return daTornare;
    }
}