import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AbsTraduzioniService } from '@pl-web-angular/libreria';

@Injectable({
  providedIn: 'root'
})
export class TraduzioniService implements AbsTraduzioniService {
  traduzione_INFO: string;
  traduzione_ATTENZIONE: string;
  traduzione_ERRORE: string;
  traduzione_RIPROVA: string;
  traduzione_IMPOSSIBILE_STABILIRE_LA_CONNESSIONE: string;
  traduzione_IMPOSSIBILE_ESEGUIRE_LOPERAZIONE: string;
  traduzione_IMPOSSIBILE_RECUPERARE_I_DATI_NECESSARI_PER_LOPERAZIONE_RICHIESTA: string;
  traduzione_selettoredata_titolo_SELEZIONA_DATA: string;
  traduzione_postHeaderSelezioneValori_placeholderInputRicerca_CERCA: string;
  traduzione_postHeaderSelezioneValori_testoBottoneMostraSelezionati_MOSTRA_SELEZIONATI: string;
  traduzione_postHeaderSelezioneValori_testoBottoneMostraTutti_MOSTRA_TUTTI: string;
  traduzione_footerDefaultDialog_bottonePrimario_CONFERMA: string;
  traduzione_footerDefaultDialog_bottoneSecondario_ANNULLA: string;
  traduzione_CHIUDI: string;
  traduzione_SELEZIONA_OPERAZIONE: string;

  constructor(private translate: TranslateService) {
    var linguaPagina = _ISELL_LOCALE_ANGULAR_CONFIGS.locale;
    translate.setDefaultLang(linguaPagina);
    translate.use(linguaPagina);
    translate.setTranslation(linguaPagina, PL_TRADUZIONI);

    this.traduzione_INFO = this.traduci("41_INFO");
    this.traduzione_ATTENZIONE = this.traduci("42_ATTENZIONE");
    this.traduzione_ERRORE = this.traduci("43_ERRORE");
    this.traduzione_RIPROVA = this.traduci("78_RIPROVA");
    this.traduzione_IMPOSSIBILE_STABILIRE_LA_CONNESSIONE = this.traduci("188_IMPOSSIBILE_STABILIRE_LA_CONNESSIONE");
    this.traduzione_IMPOSSIBILE_ESEGUIRE_LOPERAZIONE = this.traduci("215_IMPOSSIBILE_ESEGUIRE_LOPERAZIONE");
    this.traduzione_IMPOSSIBILE_RECUPERARE_I_DATI_NECESSARI_PER_LOPERAZIONE_RICHIESTA = this.traduci("77_IMPOSSIBILE_RECUPERARE_I_DATI_NECESSARI_PER_LOPERAZIONE_RICHIESTA");
    this.traduzione_selettoredata_titolo_SELEZIONA_DATA = this.traduci("192_SELEZIONA_DATA");
    this.traduzione_postHeaderSelezioneValori_placeholderInputRicerca_CERCA = this.traduci("104_CERCA");
    this.traduzione_postHeaderSelezioneValori_testoBottoneMostraSelezionati_MOSTRA_SELEZIONATI = this.traduci("171_MOSTRA_SELEZIONATI");
    this.traduzione_postHeaderSelezioneValori_testoBottoneMostraTutti_MOSTRA_TUTTI = this.traduci("170_MOSTRA_TUTTI");
    this.traduzione_footerDefaultDialog_bottonePrimario_CONFERMA = this.traduci("11_CONFERMA");
    this.traduzione_footerDefaultDialog_bottoneSecondario_ANNULLA = this.traduci("12_ANNULLA");
    this.traduzione_CHIUDI = this.traduci("73_CHIUDI");
    this.traduzione_SELEZIONA_OPERAZIONE = this.traduci("139_SELEZIONA_OPERAZIONE");
  }

  traduci(translationId: string, interpolateParams?: any): string {
    return this.translate.instant(translationId, interpolateParams).toString();
  }
}
