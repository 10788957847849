import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-documenti',
  templateUrl: './documenti.component.html',
  styleUrls: ['./documenti.component.less']
})
export class DocumentiComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
