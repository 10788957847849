import { GestioneConnessioneHubService } from "src/app/servizi/gestione-connessione-hub.service";
import { DialogService, LoaderModaleService, SnackbarService, ModelloNotificaSnackbar, ModelloChiusuraDialog, TipiChiusuraDialog, Utilita, SelezioneValori, BottoniDialog, FunzioneChiusuraDialog, UtilitaOperazioni, UtilitaDialogMessaggio } from "@pl-web-angular/libreria";
import { TraduzioniService } from "src/app/servizi/traduzioni.service";
import { PL } from "src/lib/isell";
import { InformazioniOperatore, DatiService } from "src/app/servizi/dati.service";

export namespace UtilitaCondiviseDispositivi {
    export function eliminaDispositivoEdInvito(
        servizioGestioneConnessioneHubs: GestioneConnessioneHubService,
        servizioDialog: DialogService,
        servizioTraduzioni: TraduzioniService,
        servizioLoader: LoaderModaleService,
        servizioSnackbar: SnackbarService,
        messaggio: string,
        IDDispositivo: string,
        eliminaDaInvito: boolean,
        callbackSuccesso: Function
    ) {
        var codiceTraduzioneFallimento = eliminaDaInvito ? "154_NON_E_STATO_POSSIBILE_ANNULLARE_LINVITO" : "36_NON_E_STATO_POSSIBILE_ELIMINARE_IL_DISPOSITIVO";
        var codiceTraduzioneSuccesso = eliminaDaInvito ? "29_INVITO_ELIMINATO_CON_SUCCESSO" : "35_DISPOSITIVO_ELIMINATO_CON_SUCCESSO";

        function gestisciFallimento(codiceErrore: string): void {
            UtilitaOperazioni.gestisciFallimentoConRiprova(
                servizioDialog,
                servizioTraduzioni,
                codiceErrore,
                (modelloChiusura) => {
                    if (modelloChiusura.confermato) {
                        eseguiOperazione();
                    }
                }
            );
        }

        function eseguiOperazione() {
            var UUIDLoader = servizioLoader.mostraLoader();

            servizioGestioneConnessioneHubs.verificaConnessioneORiavvia().done(() => {
                $.connection.hubGestioneDispositivi.server.eliminaDispositivoEdInvitoDispositivo(IDDispositivo).done(
                    (risultatoOperazione: PL.iSell.Console.ServerWeb.Hubs.HubGestioneDispositivi.RisultatoOperazioneEliminaDispositivoEdInvito) => {
                        var codiceErrore = "";

                        switch (risultatoOperazione) {
                            case PL.iSell.Console.ServerWeb.Hubs.HubGestioneDispositivi.RisultatoOperazioneEliminaDispositivoEdInvito.InformazioniDispositivoNonTrovate: {
                                codiceErrore = "62_INFORMAZIONI_DISPOSITIVO_NON_TROVATE";
                                break;
                            }
                            case PL.iSell.Console.ServerWeb.Hubs.HubGestioneDispositivi.RisultatoOperazioneEliminaDispositivoEdInvito.NessunErrore: {
                                servizioSnackbar.aggiungiNotifica(new ModelloNotificaSnackbar(servizioTraduzioni.traduci(codiceTraduzioneSuccesso), 3000));

                                callbackSuccesso();
                                break;
                            }
                            default: {
                                codiceErrore = codiceTraduzioneFallimento;
                                break;
                            }
                        }

                        if (codiceErrore.length != 0) {
                            gestisciFallimento(codiceErrore)
                        }

                    }).fail((errore) => {
                        console.error(errore);
                        gestisciFallimento(codiceTraduzioneFallimento);
                    }).always(() => {
                        servizioLoader.nascondiLoader(UUIDLoader);
                    });
            }).fail(() => {
                servizioLoader.nascondiLoader(UUIDLoader);
                gestisciFallimento(codiceTraduzioneFallimento);
            })
        }

        UtilitaDialogMessaggio.messaggioAvviso(
            servizioDialog,
            servizioTraduzioni,
            messaggio,
            (modelloChiusura) => {
                if (modelloChiusura.confermato) {
                    eseguiOperazione();
                }
            }
        )
    }

    const SEPARATORE_OPERATORI: string = ", ";

    export function operatoriToString(IDOpetatori: string[], servizioDati: DatiService): string {
        var elencoDescrizioni: string[] = [];
        var dizionarioOperatori = servizioDati.datiDispositivi.dizionarioOperatori;

        IDOpetatori.forEach((ID) => {
            var info = dizionarioOperatori.getValue(ID);
            elencoDescrizioni.push(Utilita.FunzioniVarie.isDefined(info) ? info.DescrizioneOperatore : ID);
        })

        elencoDescrizioni.sort(function (desc1, desc2) {
            return desc1.localeCompare(desc2);
        });

        return elencoDescrizioni.join(SEPARATORE_OPERATORI);
    }

    export function generaModelloSelettoreOperatori(
        dizionarioOperatori: Utilita.Dizionario<string, InformazioniOperatore>,
        servizioTraduzioni: TraduzioniService
    ):
        SelezioneValori.ModelloStrutturaSelettore<string, InformazioniOperatore> {

        var elencoGruppi: SelezioneValori.ModelloGruppoPerVociSelezionabili<string>[] = [];
        var elencoVoci: SelezioneValori.ModelloVoceSelezionabile<InformazioniOperatore>[] = [];
        elencoGruppi.push(new SelezioneValori.ModelloGruppoPerVociSelezionabili("", servizioTraduzioni.traduci("269_OPERATORI")));

        dizionarioOperatori.forEach((IDOperatore: string, datiOperatore: InformazioniOperatore) => {
            elencoVoci.push(new SelezioneValori.ModelloVoceSelezionabile(
                "",
                datiOperatore
            ));
        })

        var elaboratoreGruppi = new SelezioneValori.ElaboratoreGruppi<string, InformazioniOperatore>(
            (mg: string, voci: never) => {
                return mg;
            }
        );

        var elaboratoreVoci = new SelezioneValori.ElaboratoreVoci<InformazioniOperatore>(
            (md: InformazioniOperatore) => {
                //id
                return md.IDOperatore;
            },
            (md: InformazioniOperatore) => {
                //descrizione
                return md.DescrizioneOperatore;
            }
        )

        return SelezioneValori.ModelloStrutturaSelettore.nuovaSelezioneMultipla(
            new Utilita.Set<string>(),
            elencoGruppi,
            elaboratoreGruppi,
            elencoVoci,
            elaboratoreVoci,
            true,
            null,
            null
        );
    }

    export function ottieniClasseColoreSelectOOptionSerieChiave(serieChiave: PL.iSell.Console.ServerWeb.Hubs.Modelli.ModelloSerieChiave): string {
        if (serieChiave.Stato == PL.iSell.Console.ServerWeb.Hubs.Modelli.ModelloSerieChiave.StatiChiave.Scaduta || serieChiave.NumeroChiaviDeviceDisponibili == 0) {
            return "colorRed"
        }
        return "colorInitial";
    }

    export function ottieniNomeOptionSerieChiave(
        servizioTraduzioni: TraduzioniService,
        serieChiave: PL.iSell.Console.ServerWeb.Hubs.Modelli.ModelloSerieChiave): string {

        var codiceTraduzione = "";

        if (serieChiave.Stato == PL.iSell.Console.ServerWeb.Hubs.Modelli.ModelloSerieChiave.StatiChiave.Scaduta) {
            codiceTraduzione = "145_DESCRIZIONESERIECHIAVE_SCADUTA";
        }
        else if (serieChiave.NumeroChiaviDeviceDisponibili == 0) {
            codiceTraduzione = "144_DECRIZIONESERIECHIAVE_NESSUNA_DISPONIBILE";
        }
        else if (serieChiave.NumeroChiaviDeviceDisponibili == 1) {
            codiceTraduzione = "143_DECRIZIONESERIECHIAVE_NUMERO_DISPONIBILE__SINGOLARE";
        }
        else {
            codiceTraduzione = "129_DECRIZIONESERIECHIAVE_NUMERO_DISPONIBILI__PLURALE";
        }

        return servizioTraduzioni.traduci(codiceTraduzione, {
            descrizioneSerieChiave: serieChiave.DescrizioneSerie,
            numero: serieChiave.NumeroChiaviDeviceDisponibili
        }) + (serieChiave.ChiaveTest ? " [" + servizioTraduzioni.traduci("291_TEST") + "]" : "");
    }
}