import { DatiService, TipiRichiesteRecuperoDatiPerPagina, ModelloInvitoDispositivo, ModelloDispositivo, PaginaConRicaricaDatiWebApp } from 'src/app/servizi/dati.service';
import { Component, OnInit, ChangeDetectorRef, ElementRef, Inject, LOCALE_ID, NgZone } from '@angular/core';
import { VoceSelezionabilePerFiltroStato, VisualizzazionePaginaConGruppiEFiltroStato } from 'src/app/utilita/modelliPagina';
import { LoaderModaleService, DialogService, SnackbarService, Utilita, ModelloChiusuraDialog, TipiChiusuraDialog, VisualizzazioneGruppi, UtilitaTraduzioneData, ModelliRecuperoDati } from '@pl-web-angular/libreria';
import { UtenteConnessoService } from 'src/app/servizi/utente-connesso.service';
import { TraduzioniService } from 'src/app/servizi/traduzioni.service';
import { GestioneConnessioneHubService } from 'src/app/servizi/gestione-connessione-hub.service';
import { PL } from 'src/lib/isell';
import { UtilitaCondiviseDispositivi } from 'src/app/utilita/pagine/condivise/utilitaCondiviseDispositivi';
import { Subscriber, Subscription } from 'rxjs';
import { DialogDettagliDispositivoComponent } from 'src/app/componenti/pagine/dialog-dettagli-dispositivo/dialog-dettagli-dispositivo.component';
import { GestioneNotificheDaServerService } from 'src/app/servizi/gestione-notifiche-da-server.service';
import { IconaDispositivoPreferitoService } from 'src/app/componenti/icona-dispositivo-preferito/icona-dispositivo-preferito.service';
import { DialogInserimentoDispositivoComponent } from 'src/app/componenti/pagine/dialog-inserimento-dispositivo/dialog-inserimento-dispositivo.component';
import { formatDate } from '@angular/common';


enum FiltriStatiInvitiDispositivo {
  Nessuno = 0,
  Collegato = 1,
  Inviato = 2,
  InPreparazione = 3,
  ChiaveNonAssociata = 4
}

enum IdentificatoriDiStato {
  Collegato = 0, //COLLEGATI
  Inviato = 1,    //IN ATTESA
  InPreparazione = 2, //IN PREPARAZIONE
  ChiaveNonAssociata = 3
}

class InformazioniInvitoDispositivo {
  constructor(
    public IDDispositivo: string,
    public descrizioneDispositivo: string,
    public email: string,
    public identificatoreDiStato: IdentificatoriDiStato,
    public IDGruppo: string,
    public dataCollegamentoDispositivo: Date = null,
    public dataInvito: Date = null
  ) { }
}

@Component({
  selector: 'app-inviti-dispositivi',
  templateUrl: './inviti-dispositivi.component.html',
  styleUrls: ['./inviti-dispositivi.component.less']
})
export class InvitiDispositiviComponent extends PaginaConRicaricaDatiWebApp<VisualizzazionePaginaInvitiDispositivi> implements OnInit {

  private subscriberModificaPreferito: Subscription;
  private subscriberNotificaElaborazioneInvitoTerminata: Subscription;

  constructor(
    ref: ChangeDetectorRef,
    servizioDati: DatiService,
    NgZone: NgZone,
    private elm: ElementRef,
    private servizioLoader: LoaderModaleService,
    servizioUtenteConnesso: UtenteConnessoService,
    private servizioTraduzioni: TraduzioniService,
    private servizioGestioneConnessioneHubs: GestioneConnessioneHubService,
    private servizioDialog: DialogService,
    private servizioSnackbar: SnackbarService,
    @Inject(LOCALE_ID) private locale: string,
    private servizioGestioneNotificheDaServer: GestioneNotificheDaServerService,
    protected servizioIconaDispositivoPreferito: IconaDispositivoPreferitoService
  ) {
    super(
      ref,
      servizioDati,
      NgZone
    );

    this.subscriberNotificaElaborazioneInvitoTerminata = this.servizioGestioneNotificheDaServer.onNotificaElaborazioneInvitoTerminata.subscribe(() => {
      servizioDati.ricaricaDatiPerPaginaAttuale();
    })

    this.subscriberModificaPreferito = this.servizioIconaDispositivoPreferito.onModificaPreferito.subscribe((IDDispositivo: string) => {
      this.visualizzazioneGruppi.riordinaGruppiConDispositivo(IDDispositivo);
    });

    this.visualizzazioneGruppi = new VisualizzazionePaginaInvitiDispositivi(
      ref,
      servizioLoader,
      servizioTraduzioni,
      servizioUtenteConnesso,
      servizioDati,
      locale);

    this.inizializzaPaginaConRicaricaDati(
      [TipiRichiesteRecuperoDatiPerPagina.InvitiDispositivi, TipiRichiesteRecuperoDatiPerPagina.Dispositivi]
    );
  }

  ngOnInit() {
    $(this.elm.nativeElement).addClass(['paginaInvitiDispositivi']);
  }

  caricaDati(modelloStati: ModelliRecuperoDati.ModelloStatiRecuperoDatiPagina): void {
    if (modelloStati.statoUltimaRichiesta == ModelliRecuperoDati.StatiRichiestaRecuperoDati.TerminataConSuccesso) {
      this.visualizzazioneGruppi.modificaInviti(this.servizioDati.datiInvitiDispositivi.invitiDispositivi, this.servizioDati.datiDispositivi.dizionarioDispositivi);
    }
  }

  codiceStringaDaIdentificatoreDiStato(identificatoreDiStato: IdentificatoriDiStato): string {
    switch (identificatoreDiStato) {
      case IdentificatoriDiStato.Collegato: {
        return "collegato";
      }
      case IdentificatoriDiStato.Inviato: {
        return "inviato";
      }
      case IdentificatoriDiStato.InPreparazione: {
        return "preparazione";
      }
      case IdentificatoriDiStato.ChiaveNonAssociata: {
        return "nochiave";
      }
    }
    return "";
  }

  messaggioPerInvitoDispositivo(invito: InformazioniInvitoDispositivo): string {
    switch (invito.identificatoreDiStato) {
      case IdentificatoriDiStato.Collegato: {
        return UtilitaTraduzioneData.formattaData(
          this.locale,
          this.servizioTraduzioni,
          invito.dataCollegamentoDispositivo.toISOString(),
          new UtilitaTraduzioneData.StringaLocalizzabileUserFriendlySoloOra("141_COLLEGATO_OGGI_ALLE_ORA"),
          new UtilitaTraduzioneData.StringaLocalizzabileUserFriendlySoloOra("142_COLLEGATO_IERI_ALLE_ORA"),
          new UtilitaTraduzioneData.StringaLocalizzabileUserFriendlySoloOra("264_COLLEGATO_GIORNO_ALLE_ORA"),
          new UtilitaTraduzioneData.StringaLocalizzabileUserFriendlyDataEOra("24_COLLEGATO_IL_DATA_ALLE_ORA", "mediumDate")
        );
      }
      case IdentificatoriDiStato.Inviato: {
        return UtilitaTraduzioneData.formattaData(
          this.locale,
          this.servizioTraduzioni,
          invito.dataInvito.toISOString(),
          new UtilitaTraduzioneData.StringaLocalizzabileUserFriendlySoloOra("150_INVIATO_OGGI"),
          new UtilitaTraduzioneData.StringaLocalizzabileUserFriendlySoloOra("151_INVIATO_IERI"),
          new UtilitaTraduzioneData.StringaLocalizzabileUserFriendlySoloOra("265_INVIATO_GIORNO"),
          new UtilitaTraduzioneData.StringaLocalizzabileUserFriendlyDataEOra("25_INVIATO_IL_DATA", "mediumDate")
        );
      }
      case IdentificatoriDiStato.InPreparazione: {
        return "26_IN_PREPARAZIONE";
      }
      case IdentificatoriDiStato.ChiaveNonAssociata: {
        return "27_CHIAVE_NON_ASSOCIATA";
      }
    }
    return "";
  }

  invitaNuovoDispositivo(): void {
    DialogInserimentoDispositivoComponent.nuovoDialogInvito(
      this.servizioGestioneConnessioneHubs,
      this.servizioDati,
      this.servizioLoader,
      this.servizioDialog,
      this.servizioTraduzioni,
      (modelloChiusura) => {
        if (modelloChiusura.confermato) {
          this.servizioDati.ricaricaDatiPerPaginaAttuale();
        }
      }
    )
  }

  visualizzaDettagli(invito: InformazioniInvitoDispositivo) {
    DialogDettagliDispositivoComponent.nuovoDialog(
      this.servizioDati.dispositivoDaDizionarioLocale(invito.IDDispositivo).dati,
      this.servizioDialog,
      this.servizioTraduzioni,
      this.servizioLoader,
      this.servizioGestioneConnessioneHubs,
      this.servizioDati
    );
  }

  eliminaDispositivoEdInvitoDispositivo(invitoDaEliminare: InformazioniInvitoDispositivo) {
    UtilitaCondiviseDispositivi.eliminaDispositivoEdInvito(
      this.servizioGestioneConnessioneHubs,
      this.servizioDialog,
      this.servizioTraduzioni,
      this.servizioLoader,
      this.servizioSnackbar,
      this.servizioTraduzioni.traduci(
        "28_CONFERMI_LANNULLAMENTO_DELLINVITO_PER_LEMAIL_EMAILINVITODAELIMINARE",
        { emailInvitoDaEliminare: "<strong>" + invitoDaEliminare.email + "</strong>" }
      ),
      invitoDaEliminare.IDDispositivo,
      true,
      () => {
        this.servizioDati.ricaricaDatiPerPaginaAttuale();
      }
    );
  }

  ngOnDestroy() {
    this.subscriberNotificaElaborazioneInvitoTerminata.unsubscribe();
    this.subscriberModificaPreferito.unsubscribe();
    super.ngOnDestroy();
  }
}

class VisualizzazionePaginaInvitiDispositivi
  extends VisualizzazionePaginaConGruppiEFiltroStato<
  string,
  number,
  InformazioniInvitoDispositivo,
  string,
  VisualizzazioneGruppi.WrapperDatiEsteso,
  FiltriStatiInvitiDispositivo> {

  CHIAVIDIZIONARIO = {
    INVIATOOINPREPARAZIONE: "inviatoOInPreparazione",
    CHIAVENONASSOCIATA: "chiavenonassociata",
    REGISTRATO_OGGI: "registratoOggi",
    REGISTRATO_IERI: "registratoIeri",
    REGISTRATO_DATA_PREFIX: "registrato_"
  }

  private elencoGruppiStandard: VisualizzazioneGruppi.ModelloGruppo<string, number>[] = [];
  private dizionarioGruppiPerDispositivi: Utilita.Dizionario<string, Utilita.Set<string>> = new Utilita.Dizionario<string, Utilita.Set<string>>();

  constructor(
    ref: ChangeDetectorRef,
    servizioLoader: LoaderModaleService,
    servizioTraduzioni: TraduzioniService,
    servizioUtenteConnesso: UtenteConnessoService,
    protected servizioDati: DatiService,
    private locale: string) {

    super(
      ref,
      servizioLoader,
      servizioTraduzioni,
      servizioUtenteConnesso,
      PL.iSell.Console.ServerWeb.Moduli.UtilitaPermessi.AmbitiPermessoServerWeb.Dispositivi,
      null
    );

    this.vociSelezionabiliPerFiltroStato = [
      new VoceSelezionabilePerFiltroStato<FiltriStatiInvitiDispositivo>(FiltriStatiInvitiDispositivo.Nessuno, servizioTraduzioni.traduci("6_TUTTI")),
      new VoceSelezionabilePerFiltroStato<FiltriStatiInvitiDispositivo>(FiltriStatiInvitiDispositivo.Collegato, servizioTraduzioni.traduci("210_COLLEGATO")),
      new VoceSelezionabilePerFiltroStato<FiltriStatiInvitiDispositivo>(FiltriStatiInvitiDispositivo.Inviato, servizioTraduzioni.traduci("60_INVITATO")),
      new VoceSelezionabilePerFiltroStato<FiltriStatiInvitiDispositivo>(FiltriStatiInvitiDispositivo.InPreparazione, servizioTraduzioni.traduci("26_IN_PREPARAZIONE")),
      new VoceSelezionabilePerFiltroStato<FiltriStatiInvitiDispositivo>(FiltriStatiInvitiDispositivo.ChiaveNonAssociata, servizioTraduzioni.traduci("27_CHIAVE_NON_ASSOCIATA")),
    ];
  }

  modificaInviti(invitiDispositivi: ModelloInvitoDispositivo[], dizionarioDispositivi: Utilita.Dizionario<string, ModelloDispositivo>) {
    this.dizionarioGruppiPerDispositivi.clear();

    var elencoInformazioni = this.generaElencoInformazioniInvitoDispositivi(invitiDispositivi, dizionarioDispositivi);

    //Pulisco i gruppi e reinserisco i gruppo base.
    this.reimpostaElencoGruppiBase();
    var ElencoGruppiDaInserire = new Utilita.Set<string>();

    var elencoVoci: VisualizzazioneGruppi.ModelloVoce<InformazioniInvitoDispositivo, string>[] = [];

    elencoInformazioni.forEach((info: InformazioniInvitoDispositivo) => {
      elencoVoci.push(new VisualizzazioneGruppi.ModelloVoce(
        info.IDDispositivo,
        info,
        info.descrizioneDispositivo,
        info.descrizioneDispositivo,
        "",
        info.IDGruppo
      ));

      ElencoGruppiDaInserire.add(info.IDGruppo);
    });

    ElencoGruppiDaInserire.sort((valore1, valore2) => {
      return -valore1.localeCompare(valore2);
    });

    var IndiceOrdinamentoPerData = 3;//Valore iniziale altri gruppi
    ElencoGruppiDaInserire.forEach((IDGruppo) => {
      if (IDGruppo != this.CHIAVIDIZIONARIO.CHIAVENONASSOCIATA
        && IDGruppo != this.CHIAVIDIZIONARIO.INVIATOOINPREPARAZIONE
        && IDGruppo != this.CHIAVIDIZIONARIO.REGISTRATO_IERI
        && IDGruppo != this.CHIAVIDIZIONARIO.REGISTRATO_OGGI) {
        var Data = new Date(IDGruppo.replace(this.CHIAVIDIZIONARIO.REGISTRATO_DATA_PREFIX, ''));
        var Testo = UtilitaTraduzioneData.formattaData(
          this.locale,
          this.servizioTraduzioni,
          Data.toISOString(),
          new UtilitaTraduzioneData.StringaLocalizzabileUserFriendlySoloOra(""),
          new UtilitaTraduzioneData.StringaLocalizzabileUserFriendlySoloOra(""),
          new UtilitaTraduzioneData.StringaLocalizzabileUserFriendlySoloOra("300_COLLEGATO_GIORNO"),
          new UtilitaTraduzioneData.StringaLocalizzabileUserFriendlyDataEOra("299_COLLEGATI_IL_DATA", "mediumDate"));
        this.elencoGruppiStandard.push(
          new VisualizzazioneGruppi.ModelloGruppo(IDGruppo, Testo, IndiceOrdinamentoPerData++, Testo, ""));
      }
    });

    this.modificaGruppiEVoci(
      this.elencoGruppiStandard,
      elencoVoci,
      (IDGruppo: string, modelloGruppo: VisualizzazioneGruppi.ModelloGruppo<string, number>) => {
        return new VisualizzazioneGruppi.WrapperDatiEsteso(IDGruppo)
      },
      VisualizzazioneGruppi.ConfigurazioneOrdinamento.nuovaConfigurazioneAvanzata(
        VisualizzazioneGruppi.DirezioniSortingElementoDiOrdinamento.ASC,
        VisualizzazioneGruppi.DirezioniSortingElementoDiOrdinamento.ASC,
        VisualizzazioneGruppi.ConfigurazioneOrdinamento.ordinamentoStandard,
        (modello1, modello2) => {
          var datiOrd1 = modello1.dati;
          var datiOrd2 = modello2.dati;

          var Dispositivo1 = this.servizioDati.dispositivoDaDizionarioLocale(datiOrd1.IDDispositivo).dati;
          var Dispositivo2 = this.servizioDati.dispositivoDaDizionarioLocale(datiOrd2.IDDispositivo).dati;
          var Preferito1 = Utilita.FunzioniVarie.isDefined(Dispositivo1) ? Dispositivo1.Preferito : false;
          var Preferito2 = Utilita.FunzioniVarie.isDefined(Dispositivo2) ? Dispositivo2.Preferito : false;

          if (Preferito1 && !Preferito2) {
            return -1;
          }
          else if (!Preferito1 && Preferito2) {
            return 1;
          }
          else {
            return modello1.elementoDiOrdinamento.localeCompare(modello2.elementoDiOrdinamento);
          }
        },
      ),
      (gruppo, datiGruppo, numeroTotaleElementi) => {
        if (numeroTotaleElementi > 12) {
          gruppo.gruppoVisibile = false;
          gruppo.gruppoVisibileDuranteRicerca = false;
        }
      },
      this.onSingolaAggiuntaVoce.bind(this)
    );
  }

  private reimpostaElencoGruppiBase(): void {
    this.elencoGruppiStandard = [];

    var testoSconosciuto = this.servizioTraduzioni.traduci("33_SCONOSCIUTO");
    this.elencoGruppiStandard.push(
      new VisualizzazioneGruppi.ModelloGruppo("", testoSconosciuto, 0, testoSconosciuto, ""));

    var testoAttesa = this.servizioTraduzioni.traduci("161_IN_ATTESA");
    this.elencoGruppiStandard.push(
      new VisualizzazioneGruppi.ModelloGruppo(this.CHIAVIDIZIONARIO.INVIATOOINPREPARAZIONE, testoAttesa, 1, testoAttesa, ""));

    var testoNoChiave = this.servizioTraduzioni.traduci("27_CHIAVE_NON_ASSOCIATA");
    this.elencoGruppiStandard.push(
      new VisualizzazioneGruppi.ModelloGruppo(this.CHIAVIDIZIONARIO.CHIAVENONASSOCIATA, testoNoChiave, Number.MAX_SAFE_INTEGER, testoNoChiave, ""));

    //Gruppi per giorni
    var testoCollegatiOggi = this.servizioTraduzioni.traduci("297_COLLEGATI_OGGI");
    this.elencoGruppiStandard.push(
      new VisualizzazioneGruppi.ModelloGruppo(this.CHIAVIDIZIONARIO.REGISTRATO_OGGI, testoCollegatiOggi, 2, testoCollegatiOggi, ""));

    var testoCollegatiIeri = this.servizioTraduzioni.traduci("298_COLLEGATI_IERI");
    this.elencoGruppiStandard.push(
      new VisualizzazioneGruppi.ModelloGruppo(this.CHIAVIDIZIONARIO.REGISTRATO_IERI, testoCollegatiIeri, 3, testoCollegatiIeri, ""));
  }

  private onSingolaAggiuntaVoce(IDGruppo: string, voce: VisualizzazioneGruppi.ModelloVoce<InformazioniInvitoDispositivo, string>) {
    var IDDispositivo = voce.dati.IDDispositivo;
    if (!this.dizionarioGruppiPerDispositivi.containsKey(IDDispositivo)) {
      this.dizionarioGruppiPerDispositivi.setValue(IDDispositivo, new Utilita.Set<string>());
    }
    this.dizionarioGruppiPerDispositivi.getValue(IDDispositivo).add(IDGruppo);
  }

  private generaElencoInformazioniInvitoDispositivi(invitiDispositivi: ModelloInvitoDispositivo[], dizionarioDispositivi: Utilita.Dizionario<string, ModelloDispositivo>): InformazioniInvitoDispositivo[] {
    var nuovaListaInviti: InformazioniInvitoDispositivo[] = [];

    for (var invitoDispositivo of invitiDispositivi) {
      var dispositivo = dizionarioDispositivi.getValue(invitoDispositivo.IDDispositivo);

      if (Utilita.FunzioniVarie.isUndefinedOrNull(dispositivo)) {
        console.debug("Dispositivo non trovato");
        break;
      }
      else {
        var informazioniRiga = new InformazioniInvitoDispositivo(
          dispositivo.IDDispositivo,
          dispositivo.DescrizioneDispositivo,
          invitoDispositivo.Email,
          IdentificatoriDiStato.InPreparazione,
          ""
        );

        var dataRegistrazione = new Date(<string>invitoDispositivo.DataOraRegistrazione);
        var dataAttuale = new Date();
        var ieri = new Date();
        ieri.setDate(new Date().getDate() - 1);
        if (dataRegistrazione.getTime() > new Date(0).getTime()) {
          informazioniRiga.identificatoreDiStato = IdentificatoriDiStato.Collegato;
          informazioniRiga.dataCollegamentoDispositivo = new Date(<string>invitoDispositivo.DataOraRegistrazione);
          if (Utilita.FunzioniVarie.dateNelMedesimoGiorno(dataRegistrazione, dataAttuale)) {
            informazioniRiga.IDGruppo = this.CHIAVIDIZIONARIO.REGISTRATO_OGGI;
          }
          else if (Utilita.FunzioniVarie.dateNelMedesimoGiorno(dataRegistrazione, ieri)) {
            informazioniRiga.IDGruppo = this.CHIAVIDIZIONARIO.REGISTRATO_IERI;
          }
          else {
            var dataIso = dataRegistrazione.toISOString();
            dataIso = dataIso.substring(0, dataIso.indexOf('T'));
            informazioniRiga.IDGruppo = this.CHIAVIDIZIONARIO.REGISTRATO_DATA_PREFIX + dataIso;
          }
        }
        else if (dispositivo.PossiedeChiave.Elaborato && !dispositivo.PossiedeChiave.Valore) {
          informazioniRiga.identificatoreDiStato = IdentificatoriDiStato.ChiaveNonAssociata;
          informazioniRiga.IDGruppo = this.CHIAVIDIZIONARIO.CHIAVENONASSOCIATA;
        }
        else if (new Date(<string>invitoDispositivo.DataOraInvito).getTime() > new Date(0).getTime()) {
          informazioniRiga.identificatoreDiStato = IdentificatoriDiStato.Inviato;
          informazioniRiga.dataInvito = new Date(<string>invitoDispositivo.DataOraInvito);
          informazioniRiga.IDGruppo = this.CHIAVIDIZIONARIO.INVIATOOINPREPARAZIONE;
        }
        else {
          informazioniRiga.identificatoreDiStato = IdentificatoriDiStato.InPreparazione;
          informazioniRiga.IDGruppo = this.CHIAVIDIZIONARIO.INVIATOOINPREPARAZIONE;
        }

        nuovaListaInviti.push(informazioniRiga);
      }
    }

    return nuovaListaInviti;
  }

  esisteFiltroAttivo(): boolean {
    return this._esisteFiltroTesto || this._esisteFiltroStato;
  }

  private get _esisteFiltroTesto(): boolean {
    return this.filtroRicerca.length > 0;
  }

  private get _esisteFiltroStato(): boolean {
    return this.voceSelezionataPerFiltroStato != FiltriStatiInvitiDispositivo.Nessuno;
  }

  protected _callbackModificaFiltroAttivo_Pre() {
  }
  protected _callbackModificaFiltroAttivo_Post() {
  }

  protected _applicaFiltroDiRicerca(IDGruppo: string, elementoDaValutare: VisualizzazioneGruppi.ModelloVoce<InformazioniInvitoDispositivo, string>): boolean {
    var invitoDispositivo = elementoDaValutare.dati;
    var filtraPerTesto = () => {
      if (this._esisteFiltroTesto) {
        var filtro = this.filtroRicerca.toLowerCase();

        return ((invitoDispositivo.descrizioneDispositivo.toLowerCase().indexOf(filtro) != -1) ||
          (invitoDispositivo.email.toLowerCase().indexOf(filtro) != -1));
      }
      else {
        return true;
      }
    }

    var verificaSeInvitoDispositivoCorrispondeAStato = (): boolean => {
      if (this._esisteFiltroStato) {
        return (
          (this.voceSelezionataPerFiltroStato == FiltriStatiInvitiDispositivo.Nessuno)
          || ((invitoDispositivo.identificatoreDiStato == IdentificatoriDiStato.Collegato) && this.voceSelezionataPerFiltroStato == FiltriStatiInvitiDispositivo.Collegato)
          || ((invitoDispositivo.identificatoreDiStato == IdentificatoriDiStato.Inviato) && this.voceSelezionataPerFiltroStato == FiltriStatiInvitiDispositivo.Inviato)
          || ((invitoDispositivo.identificatoreDiStato == IdentificatoriDiStato.InPreparazione) && this.voceSelezionataPerFiltroStato == FiltriStatiInvitiDispositivo.InPreparazione)
          || ((invitoDispositivo.identificatoreDiStato == IdentificatoriDiStato.ChiaveNonAssociata) && this.voceSelezionataPerFiltroStato == FiltriStatiInvitiDispositivo.ChiaveNonAssociata)
        );
      }
      else {
        return true;
      }
    }

    return verificaSeInvitoDispositivoCorrispondeAStato() && filtraPerTesto();
  }

  protected _callbackModificaFiltroStato_Pre() {
  }
  protected _callbackModificaFiltroStato_Post() {
  }

  riordinaGruppiConDispositivo(IDDispositivo: string) {
    var elencoGruppi = this.dizionarioGruppiPerDispositivi.getValue(IDDispositivo);
    if (Utilita.FunzioniVarie.isDefined(elencoGruppi)) {
      elencoGruppi.forEach((IDGruppo: string) => {
        this.riordinaVociGruppo(IDGruppo, false, false);
      });
      this.aggiornaElencoOrdinatoFiltrato();
      this.ref.detectChanges();
    }
  }
}