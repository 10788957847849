import { Component, OnInit, OnDestroy, ChangeDetectorRef, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

export class ModelloPaginaErrore {
  constructor(public codiceErrore: string, public messaggio: string) { }
}

@Component({
  selector: 'app-pagina-errore',
  templateUrl: './pagina-errore.component.html',
  styleUrls: ['./pagina-errore.component.less']
})
export class PaginaErroreComponent implements OnInit, OnDestroy {
  private subscription: Subscription = null;
  modelloDati: ModelloPaginaErrore = null;

  constructor(
    private route: ActivatedRoute,
    private ref: ChangeDetectorRef,
    private elm: ElementRef
  ) { }

  ngOnInit() {
    $(this.elm.nativeElement).addClass('paginaErrore');

    this.subscription = this.route.data.subscribe((dati: ModelloPaginaErrore) => {
      this.modelloDati = dati;
      this.ref.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
