import { Component, OnInit } from '@angular/core';
import { Utilita, ConfigurazioneLayoutPrincipaleService, ModelloPaginaEVoceMenu, SezioneMenu, TipiGestioneTargetUrl } from '@pl-web-angular/libreria';
import { GestioneConnessioneHubService } from './servizi/gestione-connessione-hub.service';
import { UtenteConnessoService } from './servizi/utente-connesso.service';
import { Router } from '@angular/router';
import { PL } from 'src/lib/isell';
import { TraduzioniService } from './servizi/traduzioni.service';
import { GestioneNotificheDaServerService } from './servizi/gestione-notifiche-da-server.service';
import { UtilitaAutenticazione } from './utilita/UtilitaAutenticazione';

enum Pagine {
  RicezioneDati = "/ricezionedati",
  InvioDati = "/inviodati",
  Attivita = "/attivita",
  Dispositivi = "/dispositivi",
  InvitiDispositivi = "/invitidispositivi",
  Documenti = "/documenti",
  Estensioni = "/estensioni"
}

//TODOtraduci TODO traduci TODO translate
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  constructor(
    servizioGestioneConnessioneHub: GestioneConnessioneHubService,
    servizioConfigurazioneLayoutPrincipale: ConfigurazioneLayoutPrincipaleService,
    router: Router,
    servizioUtenteConnesso: UtenteConnessoService,
    private servizioTraduzioni: TraduzioniService,
    private servizioGestioneNotificheDaServer: GestioneNotificheDaServerService,
  ) {
    this.servizioGestioneNotificheDaServer.inizializza();

    var dizionarioPagine = new Utilita.Dizionario<string, ModelloPaginaEVoceMenu>();

    var aggiungiPagina = (pagina: Pagine, codiceTitolo: string, icona: string): void => {
      dizionarioPagine.setValue(pagina, new ModelloPaginaEVoceMenu(this.servizioTraduzioni.traduci(codiceTitolo), "/res/img/icone/" + icona, pagina, TipiGestioneTargetUrl.Standard));
    }

    aggiungiPagina(Pagine.RicezioneDati, "276_DATI_RICEVUTI", "ricezionedatidadispositivi.svg");
    aggiungiPagina(Pagine.InvioDati, "277_DATI_INVIATI", "inviodatiadispositivi.svg");
    aggiungiPagina(Pagine.Attivita, "197_ATTIVITA", "attivita.svg");
    aggiungiPagina(Pagine.Dispositivi, "198_DISPOSITIVI", "dispositivi.svg");
    aggiungiPagina(Pagine.InvitiDispositivi, "275_INVITI_DISPOSITIVI", "inviti.svg");
    aggiungiPagina(Pagine.Estensioni, "Estensioni", "estensioni.svg");
    aggiungiPagina(Pagine.Documenti, "Documenti", "documenti.svg");

    servizioConfigurazioneLayoutPrincipale.inizializza(
      () => {
        UtilitaAutenticazione.logoutConRicaricaPagina(router, servizioUtenteConnesso);
      },
      () => {
        return servizioUtenteConnesso.datiLogin.DatiUtente.Descrizione
      },
      () => {
        return "";
      },
      dizionarioPagine,
      () => {
        var sezioniMenu: SezioneMenu[] = [];

        if (servizioUtenteConnesso.sezioneAbilitata(PL.iSell.Console.ServerWeb.InformazioniServerWebConsole.SezioniAbilitabili.StatoGenerale)) {
          var sezioneStatoGenerale = new SezioneMenu(this.servizioTraduzioni.traduci("293_STATO"), []);
          sezioniMenu.push(sezioneStatoGenerale);
          sezioneStatoGenerale.vociMenu.push(dizionarioPagine.getValue(Pagine.RicezioneDati));
          sezioneStatoGenerale.vociMenu.push(dizionarioPagine.getValue(Pagine.InvioDati));
        }

        if ((servizioUtenteConnesso.sezioneAbilitata(PL.iSell.Console.ServerWeb.InformazioniServerWebConsole.SezioniAbilitabili.Attivita)) &&
          servizioUtenteConnesso.permessoLettura(PL.iSell.Console.ServerWeb.Moduli.UtilitaPermessi.AmbitiPermessoServerWeb.Attivita)
        ) {
          var sezioneAttivita = new SezioneMenu(this.servizioTraduzioni.traduci("197_ATTIVITA"), []);
          sezioniMenu.push(sezioneAttivita);
          sezioneAttivita.vociMenu.push(dizionarioPagine.getValue(Pagine.Attivita));
        }

        if (servizioUtenteConnesso.permessoLettura(PL.iSell.Console.ServerWeb.Moduli.UtilitaPermessi.AmbitiPermessoServerWeb.Dispositivi)
        ) {
          var sezioneDispositivi = new SezioneMenu(this.servizioTraduzioni.traduci("198_DISPOSITIVI"), []);

          if (servizioUtenteConnesso.sezioneAbilitata(PL.iSell.Console.ServerWeb.InformazioniServerWebConsole.SezioniAbilitabili.Dispositivi)) {
            sezioneDispositivi.vociMenu.push(dizionarioPagine.getValue(Pagine.Dispositivi));
          }
          if (servizioUtenteConnesso.sezioneAbilitata(PL.iSell.Console.ServerWeb.InformazioniServerWebConsole.SezioniAbilitabili.InvitiDispositivi)) {
            sezioneDispositivi.vociMenu.push(dizionarioPagine.getValue(Pagine.InvitiDispositivi));
          }
          if (sezioneDispositivi.vociMenu.length > 0) {
            sezioniMenu.push(sezioneDispositivi);
          }
        }

        if (servizioUtenteConnesso.sezioneAbilitata(PL.iSell.Console.ServerWeb.InformazioniServerWebConsole.SezioniAbilitabili.Estensioni)) {
          var sezioneEstensioni = new SezioneMenu("Estensioni", []);
          sezioniMenu.push(sezioneEstensioni);
          sezioneEstensioni.vociMenu.push(dizionarioPagine.getValue(Pagine.Estensioni));
        }

        if (servizioUtenteConnesso.sezioneAbilitata(PL.iSell.Console.ServerWeb.InformazioniServerWebConsole.SezioniAbilitabili.Documenti)) {
          var sezioneDocumenti = new SezioneMenu("Documenti", []);
          sezioniMenu.push(sezioneDocumenti);
          sezioneDocumenti.vociMenu.push(dizionarioPagine.getValue(Pagine.Documenti));
        }

        if (servizioUtenteConnesso.connesso) {
          let ElencoLinkAusiliari = servizioUtenteConnesso.datiLogin.ElencoLinkAusiliariArchivio;
          let sezioneLinkAusiliari = new SezioneMenu("-", []);
          if (Utilita.FunzioniVarie.isDefined(ElencoLinkAusiliari)) {
            if (ElencoLinkAusiliari.length > 0) {
              sezioniMenu.push(sezioneLinkAusiliari);
            }
            ElencoLinkAusiliari.sort((SingoloLink1, SingoloLink2) => { return SingoloLink1.Ordinamento - SingoloLink2.Ordinamento; }).forEach(SingoloLink => {
              var TipoGestione = TipiGestioneTargetUrl.ApriLinkEsternamente;
              if (SingoloLink.ValoreLink.startsWith('https://') && !SingoloLink.ForzaAperturaEsterna) {
                TipoGestione = TipiGestioneTargetUrl.ApriLinkInIframeInterno;
              }
              sezioneLinkAusiliari.vociMenu.push(new ModelloPaginaEVoceMenu(SingoloLink.DescrizioneLink, "/res/img/icone/paginaonline.svg", SingoloLink.ValoreLink, TipoGestione))
            });
          }
        }

        return sezioniMenu;
      },
      "/res/img/logo.svg",
      "/res/img/logo.svg",
      "/res/img/icone/utenteweb.svg",
      this.servizioTraduzioni.traduci("211_CONNESSO_COME"),
      this.servizioTraduzioni.traduci("158_DISCONNETTI")
    )
    servizioGestioneConnessioneHub.avvia();
  }

  ngOnInit() {
    if (Utilita.FunzioniVarie.supportaTouch()) {
      $('body').addClass('supportaTouch');
    }

    $('#tabellaLogoAttesaCaricamentoIniziale').css("display", "none");
    $('app-root').css("display", "");
  }
}
