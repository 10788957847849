import { LoaderModaleService, VisualizzazionePerGruppiEFiltro, VisualizzazioneGruppi } from "@pl-web-angular/libreria";
import { PL } from "src/lib/isell";
import { UtenteConnessoService } from "../servizi/utente-connesso.service";
import { ChangeDetectorRef } from "@angular/core";
import { TraduzioniService } from "../servizi/traduzioni.service";

export abstract class VisualizzazionePaginaConGruppi<TG, TOG extends string | number | TG, TV, TOV extends string | number | TV, G extends VisualizzazioneGruppi.WrapperDatiEsteso> extends VisualizzazionePerGruppiEFiltro<TG, TOG, TV, TOV, G> {

    permessoPagina: PL.iSell.Console.ServerWeb.Moduli.UtilitaPermessi.ValoriPermessoServerWeb = PL.iSell.Console.ServerWeb.Moduli.UtilitaPermessi.ValoriPermessoServerWeb.Nessuno;

    constructor(
        ref: ChangeDetectorRef,
        servizioLoader: LoaderModaleService,
        protected servizioTraduzioni: TraduzioniService,
        servizioUtenteConnesso: UtenteConnessoService,
        ambitoPermesso: PL.iSell.Console.ServerWeb.Moduli.UtilitaPermessi.AmbitiPermessoServerWeb,
        configurazioniVirtualScroll: VisualizzazioneGruppi.ConfigurazioniVirtualScroll
    ) {
        super(
            ref,
            servizioLoader,
            configurazioniVirtualScroll
        )

        if (ambitoPermesso != PL.iSell.Console.ServerWeb.Moduli.UtilitaPermessi.AmbitiPermessoServerWeb.Nessuno) {
            this.permessoPagina = servizioUtenteConnesso.valorePermessoAmbito(ambitoPermesso);
        }
    }
}

export class VoceSelezionabilePerFiltroStato<ENUMSTATO> {
    constructor(public stato: ENUMSTATO, public descrizione: string) { }
}

export abstract class VisualizzazionePaginaConGruppiEFiltroStato<TG, TOG extends string | number | TG, TV, TOV extends string | number | TV, G extends VisualizzazioneGruppi.WrapperDatiEsteso, ENUMSTATO> extends VisualizzazionePaginaConGruppi<TG, TOG, TV, TOV, G> {
    vociSelezionabiliPerFiltroStato: VoceSelezionabilePerFiltroStato<ENUMSTATO>[] = [];
    voceSelezionataPerFiltroStato: ENUMSTATO = 0 as any;
    protected abstract _callbackModificaFiltroStato_Pre();
    protected abstract _callbackModificaFiltroStato_Post();

    constructor(
        ref: ChangeDetectorRef,
        servizioLoader: LoaderModaleService,
        servizioTraduzioni: TraduzioniService,
        servizioUtenteConnesso: UtenteConnessoService,
        ambitoPermesso: PL.iSell.Console.ServerWeb.Moduli.UtilitaPermessi.AmbitiPermessoServerWeb,
        configurazioniVirtualScroll: VisualizzazioneGruppi.ConfigurazioniVirtualScroll
    ) {
        super(
            ref,
            servizioLoader,
            servizioTraduzioni,
            servizioUtenteConnesso,
            ambitoPermesso,
            configurazioniVirtualScroll
        )
    }

    callbackModificaFiltroStato(): void {
        this._callbackModificaFiltroStato_Pre();
        this.aggiornaListeValoriFiltrati(true);
        this._callbackModificaFiltroStato_Post();
    }
}