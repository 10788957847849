import { Injectable } from '@angular/core';
import { Modelli } from '@pl-web-angular/libreria';

@Injectable({
  providedIn: 'root'
})
export class GestioneNotificheDaServerService {

  onNotificaElaborazioneInvitoTerminata: Modelli.EmettitoreEventi<void>
    = new Modelli.EmettitoreEventi<void>();
  onNotificaTermineAttesaRicezioneBackupDispositivi: Modelli.EmettitoreEventi<string>
    = new Modelli.EmettitoreEventi<string>();

  constructor() {
    $.connection.hubGestioneInvitiDispositivi.client.notificaElaborazioneInvitoTerminata = () => {
      this.onNotificaElaborazioneInvitoTerminata.next();
    };

    $.connection.hubGestioneDispositivi.client.notificaTermineAttesaRicezioneBackupDispositivi = (UUIDOperazione: string) => {
      this.onNotificaTermineAttesaRicezioneBackupDispositivi.next(UUIDOperazione);
    }
  }

  inizializza() {
    //Metodo utilizzato per l'inizializzazione del servizio in modo che nn venga considerato inutilizzato
  }
}
