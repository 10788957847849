import { Component, OnInit, ViewEncapsulation, ElementRef } from '@angular/core';
import { UtenteConnessoService } from 'src/app/servizi/utente-connesso.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit {

  constructor(
    private elm: ElementRef,
    private servizioUtenteConnesso: UtenteConnessoService
  ) {
  }

  ngOnInit() {
    $(this.elm.nativeElement).addClass('paginaHome');
  }

  get presenzaLogoArchivio() {
    return this.servizioUtenteConnesso.datiLogin.PresenzaLogoArchivio
  }

  get descrizioneArchivio() {
    return this.servizioUtenteConnesso.datiLogin.DescrizioneArchivio
  }
}
