import { Component, OnInit } from '@angular/core';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-estensioni',
  templateUrl: './estensioni.component.html',
  styleUrls: ['./estensioni.component.less']
})
export class EstensioniComponent implements OnInit {
  iFrameUrl: SafeResourceUrl;

  constructor(
    private sanitizer: DomSanitizer
  ) { 
  }

  ngOnInit() {
    let path: string = window.location.pathname;

    if (path.startsWith('/estensioni')){
      path = path.substr('/estensioni'.length);

      if (!path.startsWith('/')) {
        path = '/' + path;
      }

      path = '/estensione' + path;
    } else {
      path = "";
    }

    this.iFrameUrl = this.sanitizer.bypassSecurityTrustResourceUrl(path);
  }

  iFrameCaricato(evento): void {
    let frame = evento.target;
    let bt = frame.contentDocument.createElement('base');
    bt.setAttribute('href', window.location.pathname + '/');
    bt.setAttribute('target', '_top');
    frame.contentDocument.getElementsByTagName('head')[0].appendChild(bt);
  }
}
