import { Pipe, PipeTransform } from '@angular/core';
import { PL } from 'src/lib/isell';

@Pipe({
  name: 'PermessoModifica'
})
export class PermessoModificaPipe implements PipeTransform {

  transform(value: PL.iSell.Console.ServerWeb.Moduli.UtilitaPermessi.ValoriPermessoServerWeb, args?: any): any {
    return value == PL.iSell.Console.ServerWeb.Moduli.UtilitaPermessi.ValoriPermessoServerWeb.Modifica;
  }

}
