import { PL } from "src/lib/isell";
import { FormControl, ValidatorFn, AbstractControlOptions, AsyncValidatorFn } from "@angular/forms";

export enum TipiVerificaPermessiRichiestiAutenticazione {
    Tutti = 0,
    AlmenoUno = 1
}

export class AmbitoConValorePermesso implements PL.iSell.Console.ServerWeb.Moduli.UtilitaPermessi.AmbitoConValorePermesso {
    Ambito: PL.iSell.Console.ServerWeb.Moduli.UtilitaPermessi.AmbitiPermessoServerWeb;
    Valore: PL.iSell.Console.ServerWeb.Moduli.UtilitaPermessi.ValoriPermessoServerWeb;

    constructor(
        Ambito: PL.iSell.Console.ServerWeb.Moduli.UtilitaPermessi.AmbitiPermessoServerWeb,
        Valore: PL.iSell.Console.ServerWeb.Moduli.UtilitaPermessi.ValoriPermessoServerWeb) {
        this.Ambito = Ambito;
        this.Valore = Valore;
    }
}

export class DatiGuardAutenticazione {
    constructor(
        public permessiRichiesti: PL.iSell.Console.ServerWeb.Moduli.UtilitaPermessi.AmbitoConValorePermesso[],
        public tipoVerificaPermessi: TipiVerificaPermessiRichiestiAutenticazione) { }
}

export class DatiRouteDefault {
    constructor(
        public datiGuardAutenticazione: DatiGuardAutenticazione
    ) { }
}