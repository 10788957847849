import { DatiService, ModelloAttivitaSemplificato, ModelloGruppoAttivita, TipiRichiesteRecuperoDatiPerPagina, PaginaConRicaricaDatiWebApp, ModelloInformazioniBasePianificazioneAttivita } from 'src/app/servizi/dati.service';
import { LoaderModaleService, Utilita, DialogService, ModelloChiusuraDialog, TipiChiusuraDialog, SnackbarService, VisualizzazioneGruppi, UtilitaOperazioni, UtilitaDialogMessaggio, ModelliRecuperoDati } from '@pl-web-angular/libreria';
import { Component, OnInit, ChangeDetectorRef, ElementRef, OnDestroy, NgZone } from '@angular/core';
import { UtenteConnessoService } from 'src/app/servizi/utente-connesso.service';
import { PL } from 'src/lib/isell';
import { UtilitaAttivita } from 'src/app/utilita/pagine/attivita/UtilitaAttivita';
import { TraduzioniService } from 'src/app/servizi/traduzioni.service';
import { GestioneConnessioneHubService } from 'src/app/servizi/gestione-connessione-hub.service';
import { VisualizzazionePaginaConGruppi } from 'src/app/utilita/modelliPagina';
import { DialogGestioneAvvioAttivita, DialogGestioneAvvioAttivitaComponent } from './dialog-gestione-avvio-attivita/dialog-gestione-avvio-attivita.component';
import { UtilitaAvvioAttivita } from './dialog-gestione-avvio-attivita/utilita-avvio-attivita';
import { Subscription, timer } from 'rxjs';

enum TipoGruppoVisualizzazioneAttivita {
  GruppoAttivita = 0,
  GruppoPianificazioni = 1
}

@Component({
  selector: 'app-attivita',
  templateUrl: './attivita.component.html',
  styleUrls: ['./attivita.component.less']
})
export class AttivitaComponent extends PaginaConRicaricaDatiWebApp<VisualizzazionePaginaAttivita> implements OnInit, OnDestroy {

  dizionarioAttivita: Utilita.Dizionario<string, ModelloAttivitaSemplificato>;
  subscriptionTimerVerificaStati: Subscription;

  constructor(
    ref: ChangeDetectorRef,
    servizioDati: DatiService,
    NgZone: NgZone,
    private elm: ElementRef,
    private servizioLoader: LoaderModaleService,
    servizioUtenteConnesso: UtenteConnessoService,
    private servizioTraduzioni: TraduzioniService,
    private servizioGestioneConnessioneHubs: GestioneConnessioneHubService,
    private servizioDialog: DialogService,
    private servizioSnackbar: SnackbarService
  ) {
    super(
      ref,
      servizioDati,
      NgZone);

    this.dizionarioAttivita = new Utilita.Dizionario<string, ModelloAttivitaSemplificato>();
    this.visualizzazioneGruppi = new VisualizzazionePaginaAttivita(
      ref,
      servizioLoader,
      servizioTraduzioni,
      servizioUtenteConnesso);

    this.inizializzaPaginaConRicaricaDati(
      [TipiRichiesteRecuperoDatiPerPagina.Attivita]
    );

    this.subscriptionTimerVerificaStati = timer(5000, 5000).subscribe(() => {
      this.verificaStatoAttivitaERicarica();
    });
  }

  ngOnInit() {
    $(this.elm.nativeElement).addClass(['paginaAttivita']);
  }

  caricaDati(modelloStati: ModelliRecuperoDati.ModelloStatiRecuperoDatiPagina): void {
    if (modelloStati.statoUltimaRichiesta == ModelliRecuperoDati.StatiRichiestaRecuperoDati.TerminataConSuccesso) {
      var dati = this.servizioDati.datiAttivita;

      this.dizionarioAttivita.clear();
      dati.listaAttivita.forEach((singolaAttivita) => {
        this.dizionarioAttivita.setValue(singolaAttivita.IDAttivita, singolaAttivita);
      })

      this.visualizzazioneGruppi.modificaAttivita(dati.listaAttivita, dati.listaGruppiAttivita, dati.listaPianificazioni);
    }
  }

  private verificaStatoAttivitaERicarica() {
    var valoriDizionario = this.dizionarioAttivita.values();
    var rilevaStati: boolean = false;
    for (var i = 0; i < valoriDizionario.length; i++) {
      var attivita = valoriDizionario[i];
      if (attivita.StatoAttivita == PL.iSell.Console.ServerWeb.Hubs.HubGestioneAttivita.StatiAttivita.InCoda
        || attivita.StatoAttivita == PL.iSell.Console.ServerWeb.Hubs.HubGestioneAttivita.StatiAttivita.InElaborazione) {
        rilevaStati = true;
        break;
      }
    }
    if (rilevaStati) {
      this.rilevaStatoAttivita();
    }
  }

  private rilevaStatoAttivita(): void {
    $.connection.hubGestioneAttivita.server.rilevaStatoAttivita(this.dizionarioAttivita.keys()).done((stati) => {
      stati.forEach((singoloStato) => {
        var valoreInDizionario = this.dizionarioAttivita.getValue(singoloStato.IDAttivita);
        if (Utilita.FunzioniVarie.isDefined(valoreInDizionario)) {
          valoreInDizionario.StatoAttivita = singoloStato.StatoAttivita;
        }
      });
      this.ref.detectChanges();
    });
  }

  avviaAttivitaOGestioneAvvioAttivita(attivita: ModelloAttivitaSemplificato): void {
    var gestisciFallimentoMostraDialogGestioneAttivita = (): void => {
      UtilitaOperazioni.gestisciFallimentoMostraDialogOperazione(
        this.servizioDialog,
        this.servizioTraduzioni,
        (modelloChiusura) => {
          if (modelloChiusura.confermato) {
            this.avviaAttivitaOGestioneAvvioAttivita(attivita)
          }
        }
      )
    }

    if (attivita.AttivitaGestisceCanali && attivita.IDCanaliTrasmissione.length == 0) {
      UtilitaDialogMessaggio.messaggioErroreSoloOK(
        this.servizioDialog,
        this.servizioTraduzioni,
        this.servizioTraduzioni.traduci(UtilitaAvvioAttivita.MSG_ERRORE_NO_CANALE_PREDEFINITO)
      );
      return;
    }

    if (attivita.TipoSelezioneDispositiviAvvioAttivita != PL.iSell.Console.ServerWeb.Hubs.HubGestioneAttivita.TipiSelezioneDispositiviAvvioAttivita.NessunaSelezione
      && attivita.AttivitaGestisceDispositivi == true) {

      var UUIDLoader = this.servizioLoader.mostraLoader();

      this.servizioGestioneConnessioneHubs.verificaConnessioneORiavvia().done(() => {
        $.connection.hubGestioneAttivita.server.rilevaDispositiviPerAttivita(attivita.IDAttivita).done(
          (gruppiConDispositivi: PL.iSell.Console.ServerWeb.Hubs.HubGestioneAttivita.ModelloGruppoDispostivi[]) => {
            if (gruppiConDispositivi.length > 0) {
              this.apriGestioneAvvioAttivitaConSelezioneOpzioni(new DialogGestioneAvvioAttivita.DatiInizializzazione(attivita, gruppiConDispositivi));
            }
            else {
              UtilitaDialogMessaggio.messaggioAvvisoSoloOK(
                this.servizioDialog,
                this.servizioTraduzioni,
                this.servizioTraduzioni.traduci("103_NON_ESISTONO_DISPOSITIVI_DISPONIBILI_PER_ESEGUIRE_LOPERAZIONE")
              )
            }
          }).fail((e) => {
            console.error(e);
            gestisciFallimentoMostraDialogGestioneAttivita();
          }).always(() => {
            this.servizioLoader.nascondiLoader(UUIDLoader);
          });
      }).fail(() => {
        this.servizioLoader.nascondiLoader(UUIDLoader);
        gestisciFallimentoMostraDialogGestioneAttivita();
      });
    }
    else {
      UtilitaDialogMessaggio.messaggioInfo(
        this.servizioDialog,
        this.servizioTraduzioni,
        this.servizioTraduzioni.traduci("99_CONFERMI_LAVVIO_DELLATTIVITA").concat(':<br><strong>' + attivita.DescrizioneAttivita + '</strong>'),
        (modelloChiusura) => {
          if (modelloChiusura.confermato) {
            UtilitaAvvioAttivita.avviaAttivita(
              this.servizioDialog,
              this.servizioSnackbar,
              this.servizioTraduzioni,
              attivita,
              []
            ).done((esito) => {
              if (esito.successo) {
                this.rilevaStatoAttivita();
              }
            });
          }
        }
      );
    }
  }

  avviaPianificazione(pianificazione: ModelloInformazioniBasePianificazioneAttivita): void {
    // 303_CONFERMI_DI_VOLER_AVVIARE_LA_PIANIFICAZIONE

    UtilitaDialogMessaggio.messaggioInfo(
      this.servizioDialog,
      this.servizioTraduzioni,
      this.servizioTraduzioni.traduci("303_CONFERMI_DI_VOLER_AVVIARE_LA_PIANIFICAZIONE").concat('<br><strong>' + pianificazione.DescrizionePianificazioneAttivita + '</strong>'),
      (modelloChiusura) => {
        if (modelloChiusura.confermato) {
          UtilitaAvvioAttivita.avviaPianificazione(
            this.servizioDialog,
            this.servizioSnackbar,
            this.servizioTraduzioni,
            pianificazione
          ).done((esito) => {
            if (esito.successo) {
              this.rilevaStatoAttivita();
            }
          });
        }
      }
    );
  }

  apriGestioneAvvioAttivitaConSelezioneOpzioni(datiInizializzazione: DialogGestioneAvvioAttivita.DatiInizializzazione): void {
    DialogGestioneAvvioAttivitaComponent.nuovoDialog(
      datiInizializzazione,
      this.servizioDialog,
      this.servizioTraduzioni,
      (modelloChiusura) => {
        if (modelloChiusura.confermato) {
          this.rilevaStatoAttivita();
        }
      }
    );
  }

  codiceStringaPerStatoAttivita(attivita: PL.iSell.Console.ServerWeb.Hubs.HubGestioneAttivita.ModelloAttivitaSemplificato): string {
    switch (attivita.StatoAttivita) {
      case PL.iSell.Console.ServerWeb.Hubs.HubGestioneAttivita.StatiAttivita.InCoda:
        return "incoda";
      case PL.iSell.Console.ServerWeb.Hubs.HubGestioneAttivita.StatiAttivita.InElaborazione:
        return "inelaborazione";
      case PL.iSell.Console.ServerWeb.Hubs.HubGestioneAttivita.StatiAttivita.NonAttiva:
        return "nonattiva";
      case PL.iSell.Console.ServerWeb.Hubs.HubGestioneAttivita.StatiAttivita.NonDisponibile:
      default:
        return "nondisponibile";
    }
  }

  messaggioPerStatoAttivita(attivita: ModelloAttivitaSemplificato): string {
    return UtilitaAttivita.messaggioPerStatoAttivita(attivita);
  }

  messaggioPerTipoAttivita(attivita: ModelloAttivitaSemplificato): string {
    return UtilitaAttivita.messaggioPerTipoAttivita(attivita);
  }

  immaginePerTipoAttivita(attivita: ModelloAttivitaSemplificato): string {
    var pathBase = "/res/img/icone/";
    switch (attivita.TipoAttivita) {
      case PL.iSell.Console.ServerWeb.Hubs.HubGestioneAttivita.TipiAttivita.Sconosciuto: {
        pathBase += "attivita";
        break;
      }
      case PL.iSell.Console.ServerWeb.Hubs.HubGestioneAttivita.TipiAttivita.PreparazioneDatiPerDispositivi: {
        pathBase += "trasferimentodagestionaleaconsole";
        break;
      }
      case PL.iSell.Console.ServerWeb.Hubs.HubGestioneAttivita.TipiAttivita.ElaborazioneDatiDaDispositivi: {
        pathBase += "trasferimentodaconsoleagestionale";
        break;
      }
      case PL.iSell.Console.ServerWeb.Hubs.HubGestioneAttivita.TipiAttivita.InvioDatiADispositivi: {
        pathBase += "inviodatiadispositivi";
        break;
      }
      case PL.iSell.Console.ServerWeb.Hubs.HubGestioneAttivita.TipiAttivita.RicezioneDatiDaDispositivi: {
        pathBase += "ricezionedatidadispositivi";
        break;
      }
      case PL.iSell.Console.ServerWeb.Hubs.HubGestioneAttivita.TipiAttivita.OperazioniGeneriche: {
        pathBase += "attivita";
        break;
      }
      case PL.iSell.Console.ServerWeb.Hubs.HubGestioneAttivita.TipiAttivita.InvioScriptADispositivi: {
        pathBase += "inviodatiadispositivi";
        break;
      }
    }

    pathBase += ".svg";
    return pathBase;
  }

  ngOnDestroy(): void {
    this.subscriptionTimerVerificaStati.unsubscribe();
    super.ngOnDestroy();
  }
}

class VisualizzazionePaginaAttivita extends VisualizzazionePaginaConGruppi<TipoGruppoVisualizzazioneAttivita, string | number, ModelloVoceVisualePagina<ModelloAttivitaSemplificato | ModelloInformazioniBasePianificazioneAttivita>, string, VisualizzazioneGruppi.WrapperDatiEsteso> {
  private testoGruppoPredefinito: string = "";

  constructor(
    ref: ChangeDetectorRef,
    servizioLoader: LoaderModaleService,
    servizioTraduzioni: TraduzioniService,
    servizioUtenteConnesso: UtenteConnessoService) {

    super(
      ref,
      servizioLoader,
      servizioTraduzioni,
      servizioUtenteConnesso,
      PL.iSell.Console.ServerWeb.Moduli.UtilitaPermessi.AmbitiPermessoServerWeb.Attivita,
      null);
    this.testoGruppoPredefinito = this.servizioTraduzioni.traduci("120_GRUPPO_PREDEFINITO");
  }

  modificaAttivita(listaAttivita: ModelloAttivitaSemplificato[], listaGruppiAttivita: ModelloGruppoAttivita[], listaPianificazioni: ModelloInformazioniBasePianificazioneAttivita[]) {
    var elencoGruppi: VisualizzazioneGruppi.ModelloGruppo<TipoGruppoVisualizzazioneAttivita, string | number>[] = [];
    var elencoVoci: VisualizzazioneGruppi.ModelloVoce<ModelloVoceVisualePagina<ModelloAttivitaSemplificato | ModelloInformazioniBasePianificazioneAttivita>, string>[] = [];

    elencoGruppi.push(new VisualizzazioneGruppi.ModelloGruppo(
      "GRUPPO_ATTIVITA_()",
      TipoGruppoVisualizzazioneAttivita.GruppoAttivita,
      -1,
      this.testoGruppoPredefinito,
      ""
    ))

    elencoGruppi.push(new VisualizzazioneGruppi.ModelloGruppo(
      "PIANIFICAZIONI",
      TipoGruppoVisualizzazioneAttivita.GruppoPianificazioni,
      Number.MAX_SAFE_INTEGER,
      this.servizioTraduzioni.traduci("302_PIANIFICAZIONI"),
      ""
    ))

    listaGruppiAttivita.forEach((modelloGruppo: ModelloGruppoAttivita) => {
      elencoGruppi.push(new VisualizzazioneGruppi.ModelloGruppo(
        "GRUPPO_ATTIVITA_(" + modelloGruppo.IDGruppoAttivita + ")",
        TipoGruppoVisualizzazioneAttivita.GruppoAttivita,
        modelloGruppo.DescrizioneGruppoAttivita,
        modelloGruppo.DescrizioneGruppoAttivita,
        ""
      ));
    })

    listaAttivita.forEach((modelloAttivita: ModelloAttivitaSemplificato) => {
      elencoVoci.push(new VisualizzazioneGruppi.ModelloVoce(
        "ATTIVITA_(" + modelloAttivita.IDAttivita + ")",
        new ModelloVoceVisualePagina(true, modelloAttivita),
        modelloAttivita.DescrizioneAttivita,
        modelloAttivita.DescrizioneAttivita,
        "",
        "GRUPPO_ATTIVITA_(" + modelloAttivita.IDGruppoAttivita + ")"
      ))
    });

    listaPianificazioni.forEach((modelloPianificazione: ModelloInformazioniBasePianificazioneAttivita) => {
      elencoVoci.push(new VisualizzazioneGruppi.ModelloVoce(
        "PIANIFICAZIONE_(" + modelloPianificazione.IDPianificazioneAttivita + ")",
        new ModelloVoceVisualePagina(false, modelloPianificazione),
        modelloPianificazione.DescrizionePianificazioneAttivita,
        modelloPianificazione.DescrizionePianificazioneAttivita,
        "",
        "PIANIFICAZIONI"
      ))
    });

    this.modificaGruppiEVoci(
      elencoGruppi,
      elencoVoci,
      (IDGruppo: string, modelloGruppo: VisualizzazioneGruppi.ModelloGruppo<TipoGruppoVisualizzazioneAttivita, string | number>) => {
        return new VisualizzazioneGruppi.WrapperDatiEsteso(IDGruppo)
      },
      VisualizzazioneGruppi.ConfigurazioneOrdinamento.nuovaConfigurazioneStandard(),
      (gruppo, datiGruppo, numeroTotaleElementi) => {
        if (numeroTotaleElementi > 12) {
          gruppo.gruppoVisibile = false;
          gruppo.gruppoVisibileDuranteRicerca = false;
        }
      }
    );
  }

  protected _callbackModificaFiltroAttivo_Pre() {
  }
  protected _callbackModificaFiltroAttivo_Post() {
  }

  protected _applicaFiltroDiRicerca(IDGruppo: string, elementoDaValutare: any): boolean {
    return true;
  }

  esisteFiltroAttivo(): boolean {
    return false;
  }
}

class ModelloVoceVisualePagina<T extends ModelloAttivitaSemplificato | ModelloInformazioniBasePianificazioneAttivita>{
  constructor(public oggettoDiTipoAttivita: boolean, public modello: T) { }
}