import { Component, OnInit, Input, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { DatiService, DatiRichiestaDispositivi } from 'src/app/servizi/dati.service';
import { IconaDispositivoPreferitoService } from './icona-dispositivo-preferito.service';
import { Subscriber, Subscription } from 'rxjs';

enum TipiAzioniSupportateIconaDispostiivoPreferito {
  SoloRimozione = 0,
  SoloAggiunta = 1,
  AggiuntaERimozioni = 2
}

@Component({
  selector: 'app-icona-dispositivo-preferito[id-dispositivo]',
  templateUrl: './icona-dispositivo-preferito.component.html',
  styleUrls: ['./icona-dispositivo-preferito.component.less']
})
export class IconaDispositivoPreferitoComponent implements OnInit, OnDestroy {

  @Input('id-dispositivo') idDispositivo: string = null;
  @Input('azione') azione: TipiAzioniSupportateIconaDispostiivoPreferito = TipiAzioniSupportateIconaDispostiivoPreferito.SoloRimozione;
  @Input('label') label: string = "";

  preferito: boolean = false;

  private subscriberModificaPreferito: Subscription;
  private subscriberRicaricaDatiDispositivi: Subscription;

  constructor(
    private servizioDati: DatiService,
    private ref: ChangeDetectorRef,
    private servizioIconaDispositivoPreferito: IconaDispositivoPreferitoService
  ) {
    this.subscriberModificaPreferito = servizioIconaDispositivoPreferito.onModificaPreferito.subscribe((IDDispositivo: string) => {
      if (IDDispositivo == this.idDispositivo) {
        this.aggiornaStatoPreferito(true);
      }
    });
    this.subscriberRicaricaDatiDispositivi = servizioDati.onSuccessoRicaricaDatiDispositivi.subscribe(() => {
      this.aggiornaStatoPreferito(true);
    })
  }

  ngOnInit() {
    this.aggiornaStatoPreferito(false);
  }

  private aggiornaStatoPreferito(digestSeNecessario: boolean): void {
    var datiDispositivo = this.servizioDati.dispositivoDaDizionarioLocale(this.idDispositivo);
    var dispositivo = datiDispositivo.dati;
    if (datiDispositivo.definiti) {
      if (this.preferito != dispositivo.Preferito) {
        this.preferito = dispositivo.Preferito;
        if (digestSeNecessario) {
          this.ref.detectChanges();
        }
      }
    }
    else {
      this.preferito = false;
      if (digestSeNecessario) {
        this.ref.detectChanges();
      }
    }
  }

  aggiungiORimuoviDaPreferiti(
  ): void {
    this.servizioIconaDispositivoPreferito.aggiungiORimuoviDaPreferiti(this.idDispositivo);
  }

  get mostra(): boolean {
    return this.azione == TipiAzioniSupportateIconaDispostiivoPreferito.AggiuntaERimozioni
      || (this.azione == TipiAzioniSupportateIconaDispostiivoPreferito.SoloRimozione && this.preferito)
      || (this.azione == TipiAzioniSupportateIconaDispostiivoPreferito.SoloAggiunta && !this.preferito);
  }

  ngOnDestroy(): void {
    this.subscriberModificaPreferito.unsubscribe();
    this.subscriberRicaricaDatiDispositivi.unsubscribe();
  }
}
