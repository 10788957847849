import { Component, OnInit, ChangeDetectorRef, NgZone, ViewChild, ElementRef } from '@angular/core';
import { Utilita, LoaderModaleService, Modelli, UtilitaOperazioni, DialogService, ModelloChiusuraDialog } from '@pl-web-angular/libreria';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription, of } from 'rxjs';
import { PL } from 'src/lib/isell';
import { GestioneConnessioneHubService } from 'src/app/servizi/gestione-connessione-hub.service';
import { UtilitaAutenticazione } from 'src/app/utilita/UtilitaAutenticazione';

class GruppoFormPerLogin {
  constructor(
    public archivio: Modelli.FormControlWithType<string>,
    public nomeUtente: Modelli.FormControlWithType<string>,
    public password: Modelli.FormControlWithType<string>,
    public ricordami: Modelli.FormControlWithType<boolean>
  ) { }
}

class DatiPerArchivioIniziali {
  constructor(
    public archivioInQueryParams: string,
    public supportaArchiviMultipli: boolean,
    public descrizioneArchivioRichiesta: string
  ) { }
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less']
})
export class LoginComponent implements OnInit {

  constructor(
    private servizioLoaderModale: LoaderModaleService,
    private ref: ChangeDetectorRef,
    private router: Router,
    private ngZone: NgZone,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private servizioGestioneConnessioneHubs: GestioneConnessioneHubService
  ) {
    var controlArchivio = new Modelli.FormControlWithType("", Validators.required);
    controlArchivio.disable();

    this.formInterno = this.formBuilder.group(new GruppoFormPerLogin(
      controlArchivio,
      new Modelli.FormControlWithType('', Validators.required),
      new Modelli.FormControlWithType('', Validators.required),
      new Modelli.FormControlWithType(false)
    ))

    new Promise<DatiPerArchivioIniziali>((resolve, reject) => {
      this.subscriptionQueryParamsRoute = this.route.queryParams.subscribe((queryParams) => {
        var redirectTo = queryParams["redirectTo"];
        if (!Utilita.FunzioniVarie.stringIsNullOrEmpty(redirectTo)) {
          this.redirectTo = redirectTo;
        }
        var Archivio = queryParams["Archivio"];

        var recuperaSupportaArchiviMultipli = () => {
          this.servizioGestioneConnessioneHubs.verificaConnessioneORiavvia().done(() => {
            $.connection.hubLogin.server.rilevaInformazioniPerLogin(Archivio).done((risultato) => {
              resolve(new DatiPerArchivioIniziali(Archivio, risultato.SupportaArchiviMultipli, risultato.DescrizioneArchivioRichiesta))
            }).fail((e) => {
              //Mi aspetto la chiamata venga eseguita con successo visto che eseguo la riconnessione
              //In questo caso quindi eseguo il reject in modo che la pagina prosegua e richieda l'archivio
              console.error(e);
              reject();
            })
          }).fail((e) => {
            console.error(e);
            window.location.reload();
          });
        }

        recuperaSupportaArchiviMultipli();
      })
    }).then((datiPerArchivio: DatiPerArchivioIniziali) => {
      if (datiPerArchivio.supportaArchiviMultipli) {
        if (!Utilita.FunzioniVarie.stringIsNullOrEmpty(datiPerArchivio.archivioInQueryParams) && Utilita.FunzioniVarie.isDefined(datiPerArchivio.descrizioneArchivioRichiesta)) {
          controlArchivio.setValue(datiPerArchivio.archivioInQueryParams, { emitEvent: false });
          controlArchivio.disable();
        }
        else {
          controlArchivio.enable();
        }
      }
      else {
        controlArchivio.disable();
      }

      this.datiPerArchivio = datiPerArchivio;
      this.paginaNascosta = false;
      this.ref.detectChanges();
      if (!Utilita.FunzioniVarie.supportaTouch()) {
        if (datiPerArchivio.supportaArchiviMultipli && Utilita.FunzioniVarie.isDefined(datiPerArchivio.descrizioneArchivioRichiesta)) {
          $('[name="archivio"]').focus();
        }
        else {
          $('[name="username"]').focus();
        }
      }
    }).catch(() => {
      controlArchivio.enable();
      this.paginaNascosta = false;
      this.ref.detectChanges();
    })
  }

  @ViewChild('inputNomeUtente', { static: true })
  private elementoInputNomeUtente: ElementRef;

  @ViewChild('inputPassword', { static: true })
  private elementoInputPassword: ElementRef;

  @ViewChild('elementoFormLogin', { static: true })
  private elementoFormLogin: ElementRef;
  erroriForm: string[] = [];

  private subscriptionQueryParamsRoute: Subscription;
  private redirectTo: string = "/";

  public formInterno: FormGroup;

  public paginaNascosta: boolean = true;
  public datiPerArchivio: DatiPerArchivioIniziali;

  ngOnInit() {
  }

  get FC(): GruppoFormPerLogin { return (this.formInterno.controls as any) as GruppoFormPerLogin; }

  gestisciKeyDownArchivio(event: KeyboardEvent) {
    this.gestisciKeyDown(event, true);
  }

  gestisciKeyDownUsername(event: KeyboardEvent) {
    this.gestisciKeyDown(event, false);
  }

  gestisciKeyDown(event: KeyboardEvent, daCampoArchivio: boolean) {
    if ((event.keyCode == 13 || event.which == 13) && !this.formInterno.valid) {
      event.preventDefault();
      event.stopPropagation();
      if (daCampoArchivio) {
        $(this.elementoInputNomeUtente.nativeElement).focus();
      }
      else {
        $(this.elementoInputPassword.nativeElement).focus();
      }
    }
  }

  effettuaLogin() {
    this.erroriForm = [];
    Utilita.FunzioniVarie.aggiungiFlagSubmitAForm(this.formInterno, this.elementoFormLogin, this.ref);
    if (this.formInterno.valid) {
      var UUIDLoader = this.servizioLoaderModale.mostraLoader();

      this.servizioGestioneConnessioneHubs.verificaConnessioneORiavvia().done(() => {
        $.connection.hubLogin.server.login(
          this.FC.archivio.value,
          this.FC.nomeUtente.value,
          this.FC.password.value,
          this.FC.ricordami.value
        ).done((risultatoLogin) => {

          switch (risultatoLogin) {
            case PL.iSell.Console.ModelliComunicazioneSignalR.RisultatoOperazioneLogin.NessunErrore: {
              //NOTA: ricarico totalmente per applicare la lingua effettiva
              window.location.href = this.redirectTo;
              return;
            }
            case PL.iSell.Console.ModelliComunicazioneSignalR.RisultatoOperazioneLogin.NomeArchivioNonSpecificato: {
              this.erroriForm.push("archivioNonSpecificato");
              break;
            }
            case PL.iSell.Console.ModelliComunicazioneSignalR.RisultatoOperazioneLogin.NomeUtenteNonSpecificato: {
              this.erroriForm.push("nomeUtenteNonSpecificato");
              break;
            }
            case PL.iSell.Console.ModelliComunicazioneSignalR.RisultatoOperazioneLogin.PasswordNonSpecificata: {
              this.erroriForm.push("passwordNonSpecificata");
              break;
            }
            case PL.iSell.Console.ModelliComunicazioneSignalR.RisultatoOperazioneLogin.CredenzialiNonValide: {
              this.erroriForm.push("credenzialiNonValide");
              break;
            }
            case PL.iSell.Console.ModelliComunicazioneSignalR.RisultatoOperazioneLogin.ErroreLogin: {
              this.erroriForm.push("erroreGenericoNonGestito");
              break;
            }
          }
        }).fail(() => {
          this.erroriForm.push("erroreGenericoConnessione");
        }).always(() => {
          this.servizioLoaderModale.nascondiLoader(UUIDLoader);
          Utilita.FunzioniVarie.eseguiAnimazioneErroriForm(this.elementoFormLogin);
          this.ref.detectChanges();
        });
      }).fail(() => {
        this.erroriForm.push("erroreGenericoConnessione");
        this.servizioLoaderModale.nascondiLoader(UUIDLoader);
        Utilita.FunzioniVarie.eseguiAnimazioneErroriForm(this.elementoFormLogin);
        this.ref.detectChanges();
      })
    }
  }

  ngOnDestroy() {
    if (Utilita.FunzioniVarie.isDefined(this.subscriptionQueryParamsRoute)) {
      this.subscriptionQueryParamsRoute.unsubscribe();
    }
  }

  ricaricaPaginaSenzaArchivioPreselezionato(): void {
    window.location.href = this.router.createUrlTree(
      ["/login"], {
      queryParams: UtilitaAutenticazione.creaQueryParams(this.redirectTo, null)
    }
    ).toString();
  }
}
