import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { PL } from 'src/lib/isell';
import { AbsModelloContenutoDialog, EventoComunicazioneDialog, EsitoRichiestaChiusuraDialog, TipiChiusuraDialog, DatiComponenteParteDialog, SelezioneValori, Utilita, OpzioniDialog, UtilitaDialogSelezioneValori, ModelloChiusuraDialog, DialogService, GestioneDimensioniDialog, SnackbarService, UtilitaDialog, BottoniDialog, FunzioneChiusuraDialog, UtilitaDialogMessaggio } from '@pl-web-angular/libreria';
import { TraduzioniService } from 'src/app/servizi/traduzioni.service';
import { UtilitaAttivita } from 'src/app/utilita/pagine/attivita/UtilitaAttivita';
import { UtilitaAvvioAttivita } from './utilita-avvio-attivita';
import { ModelloAttivitaSemplificato } from 'src/app/servizi/dati.service';
import { WrapperDatoPerSelezione } from '@pl-web-angular/libreria/src/app/moduli/selezione-valori/selezione-valori.component';

type ModelloDispositivoAttivita = PL.iSell.Console.ServerWeb.Hubs.HubGestioneAttivita.ModelloDispositivo;
type ModelloGruppoDispositiviAttivita = PL.iSell.Console.ServerWeb.Hubs.HubGestioneAttivita.ModelloGruppoDispostivi;

export namespace DialogGestioneAvvioAttivita {
  export class DatiInizializzazione {
    constructor(
      public attivita: ModelloAttivitaSemplificato,
      public dispositiviSelezionabili: ModelloGruppoDispositiviAttivita[]
    ) {
    }
  }

  export class DatiChiusura {
    constructor(
      public attivita: ModelloAttivitaSemplificato,
      public identificatoriValoriSelezionati: string[]
    ) { }
  }
}

@Component({
  selector: 'app-dialog-gestione-avvio-attivita',
  templateUrl: './dialog-gestione-avvio-attivita.component.html',
  styleUrls: ['./dialog-gestione-avvio-attivita.component.less']
})
export class DialogGestioneAvvioAttivitaComponent extends AbsModelloContenutoDialog<void> implements OnInit {

  @Input() datiInizializzazione: DialogGestioneAvvioAttivita.DatiInizializzazione;

  private modelloPerSelettoreDispositivi: SelezioneValori.ModelloStrutturaSelettore<ModelloGruppoDispositiviAttivita, ModelloDispositivoAttivita>;
  private dizionarioVociConDescrizione: Utilita.Dizionario<string, string> = new Utilita.Dizionario<string, string>();
  mostraOpzioniAvanzate: boolean = false;

  constructor(
    private servizioTraduzioni: TraduzioniService,
    private servizioDialog: DialogService,
    ref: ChangeDetectorRef,
    private servizioSnackbar: SnackbarService
  ) {
    super(ref);
  }

  ngOnInit() {
    this.generaModelloInizialePerSelezione();
  }

  private generaModelloInizialePerSelezione() {
    var elencoGruppi: SelezioneValori.ModelloGruppoPerVociSelezionabili<ModelloGruppoDispositiviAttivita>[] = [];
    var elencoVoci: SelezioneValori.ModelloVoceSelezionabile<ModelloDispositivoAttivita>[] = [];
    var elencoIDVociSelezionate: Utilita.Set<string> = new Utilita.Set<string>();
    this.datiInizializzazione.dispositiviSelezionabili.forEach((gruppoConDispositivi: ModelloGruppoDispositiviAttivita) => {
      elencoGruppi.push(
        new SelezioneValori.ModelloGruppoPerVociSelezionabili(
          gruppoConDispositivi.IDGruppoDispositivi,
          gruppoConDispositivi
        )
      );

      gruppoConDispositivi.Dispositivi.forEach((dispositivo: ModelloDispositivoAttivita) => {
        elencoVoci.push(
          new SelezioneValori.ModelloVoceSelezionabile(
            gruppoConDispositivi.IDGruppoDispositivi,
            dispositivo
          )
        )

        this.dizionarioVociConDescrizione.setValue(dispositivo.IDDispositivo, dispositivo.DescrizioneDispositivo);

        if (dispositivo.Selezionato) {
          elencoIDVociSelezionate.add(dispositivo.IDDispositivo);
        }
      })
    })

    var elaboratoreGruppi = new SelezioneValori.ElaboratoreGruppi<ModelloGruppoDispositiviAttivita, ModelloDispositivoAttivita>(
      (mg: ModelloGruppoDispositiviAttivita, voci: never) => {
        //descrizione
        if (!Utilita.FunzioniVarie.stringIsNullOrEmpty(mg.IDGruppoDispositivi)) {
          var descrizione = mg.DescrizioneGruppoDispositivi;
          return descrizione;
        }
        else {
          return this.servizioTraduzioni.traduci("120_GRUPPO_PREDEFINITO");
        }
      },
      (mg: ModelloGruppoDispositiviAttivita) => {
        //ordinamento
        if (!Utilita.FunzioniVarie.stringIsNullOrEmpty(mg.IDGruppoDispositivi)) {
          return mg.DescrizioneGruppoDispositivi;
        }
        else {
          return mg.IDGruppoDispositivi;
        }
      },
      (gvs: WrapperDatoPerSelezione) => {
        var parametri = {
          numero: gvs.elencoValoriSelezionati.size(),
          totale: gvs.listaValori.size()
        };
        var codice = "";
        if (parametri.numero == 0) {
          codice = "147_ZEROELEMENTI_SU_TOTALEELEMENTI_DISPOSITIVI";
        }
        else if (parametri.numero == 1) {
          codice = "146_SINGOLOELEMENTO_SU_TOTALEELEMENTI_DISPOSITIVO";
        }
        else {
          codice = "148_NUMEROELEMENTI_SU_TOTALEELEMENTI_DISPOSITIVI";
        }

        return this.servizioTraduzioni.traduci(codice, parametri);
      }
    );

    var elaboratoreVoci = new SelezioneValori.ElaboratoreVoci<ModelloDispositivoAttivita>(
      (md: ModelloDispositivoAttivita) => {
        //id
        return md.IDDispositivo;
      },
      (md: ModelloDispositivoAttivita) => {
        //descrizione
        return md.DescrizioneDispositivo;
      }
    )

    switch (this.datiInizializzazione.attivita.TipoSelezioneDispositiviAvvioAttivita) {
      case PL.iSell.Console.ServerWeb.Hubs.HubGestioneAttivita.TipiSelezioneDispositiviAvvioAttivita.SelezioneDisattivata: {
        this.modelloPerSelettoreDispositivi = SelezioneValori.ModelloStrutturaSelettore.nuovaSelezioneDisattivata(
          elencoIDVociSelezionate,
          elencoGruppi,
          elaboratoreGruppi,
          elencoVoci,
          elaboratoreVoci,
        );
        break;
      }
      case PL.iSell.Console.ServerWeb.Hubs.HubGestioneAttivita.TipiSelezioneDispositiviAvvioAttivita.SelezioneSingoloDispositivo: {
        this.modelloPerSelettoreDispositivi = SelezioneValori.ModelloStrutturaSelettore.nuovaSelezioneSingola(
          elencoIDVociSelezionate,
          elencoGruppi,
          elaboratoreGruppi,
          elencoVoci,
          elaboratoreVoci,
        );
        break;
      }
      case PL.iSell.Console.ServerWeb.Hubs.HubGestioneAttivita.TipiSelezioneDispositiviAvvioAttivita.Selezione: {
        this.modelloPerSelettoreDispositivi = SelezioneValori.ModelloStrutturaSelettore.nuovaSelezioneMultipla(
          elencoIDVociSelezionate,
          elencoGruppi,
          elaboratoreGruppi,
          elencoVoci,
          elaboratoreVoci,
          true,
          null,
          null
        );
        break;
      }
    }
  }

  invertiVisibilitaOpzioniAvanzate(): void {
    this.mostraOpzioniAvanzate = !this.mostraOpzioniAvanzate;
    this.ref.detectChanges();
  }

  messaggioPerTipoAttivita(attivita: ModelloAttivitaSemplificato): string {
    return UtilitaAttivita.messaggioPerTipoAttivita(attivita);
  }

  mostraSelettoreDispositivi() {
    var codiceTitolo: string = "";
    switch (this.datiInizializzazione.attivita.TipoSelezioneDispositiviAvvioAttivita) {
      case PL.iSell.Console.ServerWeb.Hubs.HubGestioneAttivita.TipiSelezioneDispositiviAvvioAttivita.SelezioneDisattivata: {
        codiceTitolo = "125_VISUALIZZA_DISPOSITIVI_SELEZIONATI";
        break;
      }
      case PL.iSell.Console.ServerWeb.Hubs.HubGestioneAttivita.TipiSelezioneDispositiviAvvioAttivita.SelezioneSingoloDispositivo: {
        codiceTitolo = "107_SELEZIONA_SINGOLO_DISPOSITIVO";
        break;
      }
      case PL.iSell.Console.ServerWeb.Hubs.HubGestioneAttivita.TipiSelezioneDispositiviAvvioAttivita.Selezione: {
        codiceTitolo = "102_SELEZIONA_DISPOSITIVI";
        break;
      }
    }
    var opzioniDialog = UtilitaDialogSelezioneValori.generaDialogSelezioneValoriConConfigurazioniDefault(
      this.modelloPerSelettoreDispositivi,
      this.servizioTraduzioni.traduci(codiceTitolo),
      (modelloChiusura) => {
        if (modelloChiusura.confermato) {
          this.modelloPerSelettoreDispositivi.elencoIDValoriSelezionati = modelloChiusura.datiChiusura.elencoIDValoriSelezionati;
        }
      },
      this.servizioTraduzioni
    );

    this.servizioDialog.aggiungiDialog(opzioniDialog);
  }

  richiediChiusura(tipoChiusura: TipiChiusuraDialog): Promise<EsitoRichiestaChiusuraDialog<void>> {
    if (tipoChiusura == TipiChiusuraDialog.Conferma) {
      var identificatoriValoriSelezionati = this.elencoIDValoriSelezionati;
      if (identificatoriValoriSelezionati.size() == 0) {
        UtilitaDialogMessaggio.messaggioErroreSoloOK(
          this.servizioDialog,
          this.servizioTraduzioni,
          this.servizioTraduzioni.traduci("116_DISPOSITIVI_MANCANTI_PER_LAVVIO_ATTIVITA")
        )
        return Utilita.FunzioniVarie.PromiseConValore(new EsitoRichiestaChiusuraDialog(false, null));
      }
      else {
        return UtilitaAvvioAttivita.avviaAttivita(
          this.servizioDialog,
          this.servizioSnackbar,
          this.servizioTraduzioni,
          this.datiInizializzazione.attivita,
          identificatoriValoriSelezionati.toArray());
      }
    }
    else {
      return Utilita.FunzioniVarie.PromiseConValore(new EsitoRichiestaChiusuraDialog(true, null));
    }
  }

  protected onComunicazioneDaComponenteDialog(comunicazione: EventoComunicazioneDialog): void { }

  get supportaTouch(): boolean {
    return Utilita.FunzioniVarie.supportaTouch();
  }

  get attivita(): ModelloAttivitaSemplificato {
    return this.datiInizializzazione.attivita;
  }

  get elencoIDValoriSelezionati(): Utilita.Set<string> {
    return this.modelloPerSelettoreDispositivi.elencoIDValoriSelezionati;
  }

  get traduzionePerGestioneOpzioni(): string {
    var numeroTotaleElementi = 0;

    if (this.attivita.TipoSelezioneDispositiviAvvioAttivita != PL.iSell.Console.ServerWeb.Hubs.HubGestioneAttivita.TipiSelezioneDispositiviAvvioAttivita.SelezioneSingoloDispositivo) {
      numeroTotaleElementi = this.modelloPerSelettoreDispositivi.voci.length;
    }
    else {
      numeroTotaleElementi = 1;
    }


    return this.servizioTraduzioni.traduci("109_NUMEROSELEZIONATI_DISPOSITIVI_SU_NUMEROTOTALE_SELEZIONABILI_PER_LOPERAZIONE", {
      numeroSelezionati: this.elencoIDValoriSelezionati.size(),
      numeroTotale: numeroTotaleElementi
    });
  }

  get traduzionePerSingoloDispositivoSelezionato(): string {
    var descrizioneDispositivo = null;
    if (this.elencoIDValoriSelezionati.size() == 1) {
      var IDVoce = this.elencoIDValoriSelezionati.toArray()[0];
      descrizioneDispositivo = this.dizionarioVociConDescrizione.getValue(IDVoce);
    }

    return this.servizioTraduzioni.traduci("112_DISPOSITIVO_SELEZIONATO_NOMEDISPOSITIVO", {
      nomeDispositivo: Utilita.FunzioniVarie.isDefined(descrizioneDispositivo) ? descrizioneDispositivo : this.servizioTraduzioni.traduci("33_SCONOSCIUTO")
    });
  }

  static nuovoDialog(
    dati: DialogGestioneAvvioAttivita.DatiInizializzazione,
    servizioDialog: DialogService,
    servizioTraduzioni: TraduzioniService,
    callbackChiusura: FunzioneChiusuraDialog<void>
  ): void {

    var opzioniDialog = UtilitaDialog.generaOpzioniDialogDefault<void, DialogGestioneAvvioAttivitaComponent>(
      new DatiComponenteParteDialog<DialogGestioneAvvioAttivitaComponent>(
        DialogGestioneAvvioAttivitaComponent,
        <DialogGestioneAvvioAttivitaComponent>{
          datiInizializzazione: dati
        }
      ),
      servizioTraduzioni.traduci("108_AVVIA_ATTIVITA"),
      callbackChiusura,
      new GestioneDimensioniDialog(false, 300),
      BottoniDialog.Entrambi,
      servizioTraduzioni.traduci("98_AVVIA")
    );

    servizioDialog.aggiungiDialog(opzioniDialog);
  }
}
