import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Utilita } from '@pl-web-angular/libreria';
import { UtenteConnessoService } from './utente-connesso.service';
import { DatiRouteDefault, DatiGuardAutenticazione, TipiVerificaPermessiRichiestiAutenticazione } from '../utilita/modelli';
import { UtilitaAutenticazione } from '../utilita/UtilitaAutenticazione';

@Injectable({
  providedIn: 'root'
})
export class GuardAutenticazioneService {

  constructor(public router: Router,
    private servizioUtenteConnesso: UtenteConnessoService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return this.servizioUtenteConnesso.rilevaUtente().then((successo: boolean) => {
      if (successo) {
        var datiLogin = this.servizioUtenteConnesso.datiLogin;
        var datiRoute: DatiRouteDefault = route.data as DatiRouteDefault;
        if (Utilita.FunzioniVarie.isDefined(datiRoute)) {
          var datiGuard: DatiGuardAutenticazione = datiRoute.datiGuardAutenticazione;
          var dizionarioPermessi = datiLogin.DatiUtente.dizionarioPermessiUtenti;

          if (Utilita.FunzioniVarie.isDefined(datiGuard) && Utilita.FunzioniVarie.isDefined(datiGuard.permessiRichiesti) && datiGuard.permessiRichiesti.length > 0) {

            if (datiGuard.tipoVerificaPermessi == TipiVerificaPermessiRichiestiAutenticazione.Tutti) {
              for (var i = 0; i < datiGuard.permessiRichiesti.length; i++) {
                var permessoRichiesto = datiGuard.permessiRichiesti[i];
                var valoreInDizionario = dizionarioPermessi.getValue(permessoRichiesto.Ambito);
                if (Utilita.FunzioniVarie.isDefined(valoreInDizionario) && valoreInDizionario == permessoRichiesto.Valore) {
                  return false;
                }
              }
            }
            else {
              var permessoTrovato = false;
              for (var i = 0; i < datiGuard.permessiRichiesti.length; i++) {
                var permessoRichiesto = datiGuard.permessiRichiesti[i];
                var valoreInDizionario = dizionarioPermessi.getValue(permessoRichiesto.Ambito);
                if (Utilita.FunzioniVarie.isDefined(valoreInDizionario) && valoreInDizionario == permessoRichiesto.Valore) {
                  permessoTrovato = true;
                  break;
                }
              }

              if (!permessoTrovato) {
                return false;
              }
            }
          }
        }

        return true;
      }
      else {
        var IDArchivio = this.servizioUtenteConnesso.rilevaIDArchivioInSessionStorage();
        if (this.servizioUtenteConnesso.connesso) {
          UtilitaAutenticazione.logoutConRicaricaPagina(this.router, this.servizioUtenteConnesso);
        }
        else {
          this.router.navigate(['login'], { queryParams: UtilitaAutenticazione.creaQueryParams(state.url, IDArchivio) });
        }
        return false;
      }
    }, () => {
      //Fail
      this.router.navigate(['login']);
      return false;
    }).catch(() => {
      this.router.navigate(['login']);
      return false;
    });
  }
}
