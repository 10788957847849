import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UtenteConnessoService } from './utente-connesso.service';

@Injectable({
  providedIn: 'root'
})
export class GuardRedirectSeAutenticatoService {

  constructor(private router: Router, private servizioUtenteConnesso: UtenteConnessoService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return this.servizioUtenteConnesso.rilevaUtente().then((successo: boolean) => {
      if (successo) {
        this.router.navigate(['']);
        return false;
      }
      else {
        return true;
      }
    }, () => {
      //Fail
      return true;
    }).catch(() => {
      return true;
    });
  }
}
